import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import ResignSubscriptionModal from '../ResignSubscriptionModal';
import UnsubscribeSurveyModal from '../UnsubscribeSurveyModal';
import { useOverlayTriggerState } from 'react-stately';

const CancelButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    color: var(--neutral500);
    transition: color 0.25s ease-in-out;

    &:hover {
        color: var(--black);
    }

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        position: absolute;
        left: 0;
        bottom: -2.5rem;
    }
`;

const PricingCancelSubscriptionButton = () => {
    let unsuscribeSurveyModalState = useOverlayTriggerState({});
    let resignSubscriptionModalState = useOverlayTriggerState({});

    const startSubscriptionResignation = () => {
        unsuscribeSurveyModalState.close();
        resignSubscriptionModalState.open();
    };

    return (
        <>
            <CancelButton onClick={() => unsuscribeSurveyModalState.open()}>
                <DeprecatedText as="span" color="inherit" weight="semibold">
                    <FormattedMessage defaultMessage="Résilier mon abonnement" />
                </DeprecatedText>
            </CancelButton>
            {resignSubscriptionModalState.isOpen && (
                <ResignSubscriptionModal
                    isOpen={resignSubscriptionModalState.isOpen}
                    onClose={() => resignSubscriptionModalState.close()}
                />
            )}

            {unsuscribeSurveyModalState.isOpen && (
                <UnsubscribeSurveyModal
                    onAfterSubmit={startSubscriptionResignation}
                    isOpen={unsuscribeSurveyModalState.isOpen}
                    onClose={() => unsuscribeSurveyModalState.close()}
                />
            )}
        </>
    );
};

export default PricingCancelSubscriptionButton;
