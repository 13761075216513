import { Extension, findChildren } from '@tiptap/react';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

interface KaraokeExtensionOptions {
    nodeType: string; // The type of node to highlight during audio playback
}

const getDecorations = (
    doc: ProseMirrorNode,
    currentTime: number,
    options: KaraokeExtensionOptions,
): DecorationSet => {
    const { nodeType } = options;

    const decorations = findChildren(
        doc,
        (node) =>
            node.type.name === nodeType &&
            node.attrs.start <= currentTime &&
            currentTime <= node.attrs.end,
    ).map(({ pos, node }) => {
        const contentOffset = 1; // Offset position of the content node inside the parent node
        const contentWhitespace = 1; // Words start with a whitespace

        const from = pos + contentOffset + contentWhitespace;
        const to = pos + contentOffset + node.content.size;

        return Decoration.inline(
            from,
            to,
            { class: 'transcription-current-playing-node' },
            { inclusiveStart: true, inclusiveEnd: true },
        );
    });

    return DecorationSet.create(doc, decorations);
};

/**
 * An extension for Tiptap that highlights nodes during audio playback.
 */
const KaraokeExtension = Extension.create<KaraokeExtensionOptions>({
    name: 'karaokeExtension',

    addOptions() {
        return {
            nodeType: 'word',
        };
    },

    addProseMirrorPlugins() {
        const options = this.options;

        return [
            new Plugin({
                key: new PluginKey('karaokePlugin'),
                state: {
                    init(_, { doc }) {
                        return getDecorations(doc, 0, options);
                    },
                    apply(transaction, decorations) {
                        const currentTime = transaction.getMeta('currentTime');

                        // The plugin should ignore transactions without currentTime metadata
                        if (typeof currentTime !== 'number') {
                            return decorations.map(transaction.mapping, transaction.doc);
                        }

                        return getDecorations(transaction.doc, currentTime, options);
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            }),
        ];
    },
});

export default KaraokeExtension;
