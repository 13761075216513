import { Node } from '@tiptap/core';

const THRESHOLD_CONFIDENCE = 0.4;

const CustomWord = Node.create({
    name: 'word',
    group: 'inline',
    inline: true,
    content: 'text*',

    addAttributes() {
        return {
            start: {
                default: null,
                rendered: false,
            },
            end: {
                default: null,
                rendered: false,
            },
            confidence: {
                default: 1,
                renderHTML: (attributes) => {
                    if (attributes.confidence >= THRESHOLD_CONFIDENCE) {
                        return {};
                    }

                    return {
                        style: `color: var(--warning500)`,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [{ tag: 'span' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', HTMLAttributes, 0];
    },
});

export default CustomWord;
