import PropTypes from 'prop-types';
import { ReactComponent as LogoCompleteAndColored } from '@public/logos/logo-color-full.svg';
import { ReactComponent as LogoComplete } from '@public/logos/logo-full.svg';
import { ReactComponent as LogoColored } from '@public/logos/logo-color.svg';
import { ReactComponent as Logo } from '@public/logos/logo.svg';

const AushaLogo = (props) => {
    if (props.isComplete && props.isColored)
        return <LogoCompleteAndColored className={props.className} />;
    if (props.isComplete) return <LogoComplete className={props.className} />;
    if (props.isColored) return <LogoColored className={props.className} />;
    return <Logo className={props.className} />;
};

AushaLogo.propTypes = {
    isComplete: PropTypes.bool,
    isColored: PropTypes.bool,
    className: PropTypes.string,
};

AushaLogo.defaultProps = {
    isComplete: false,
    isColored: false,
    className: undefined,
};

export default AushaLogo;
