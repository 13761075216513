import { Children, cloneElement } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
    display: flex;
    gap: 1rem;
`;

function CardSelectList({
    children,
    onChange,
    isDisabled,
    value,
    name,
    tooltip,
    transform,
    ...props
}) {
    const handleChange = (e) => {
        const newValue = e.target.value;
        const transformedValue =
            transform && typeof transform === 'function' ? transform(newValue) : newValue;
        onChange(transformedValue);
    };

    const isSelected = (child) => {
        if (transform && typeof transform === 'function') {
            return value === transform(child.props.value);
        }
        return value === child.props.value;
    };

    return (
        <Container {...props}>
            {Children.map(children, (c) =>
                cloneElement(c, {
                    isSelected: isSelected(c),
                    onChange: handleChange,
                    name,
                    isDisabled: isDisabled ?? c.props.isDisabled,
                    tooltip: tooltip ?? c.props.tooltip,
                }),
            )}
        </Container>
    );
}

CardSelectList.propTypes = {
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string,
    isDisabled: PropTypes.bool,
    tooltip: PropTypes.node,
    transform: PropTypes.func,
};

export default CardSelectList;
