import Form from '../stores/Form';

export default (factory = {}) =>
    (model, opts = {}, defaultFields = {}) => {
        let form;
        if (typeof factory === 'function') {
            form = factory(model);
        } else {
            form = factory;
        }
        const { options = {}, fields = {} } = form;

        // use model.template in SocialMediaPostForm. Should overwrite constructor to only pass the template to the FormModelFactory
        const modelFields = Object.entries(
            model.constructor.attributes || model.template.constructor.attributes,
        ).reduce((acc, [attr, val]) => {
            let mapping = val;
            let resolver;

            if (typeof val === 'object' && val.toApi) {
                const { key, resolve } = val.toApi;
                if (!key) {
                    return acc;
                }
                mapping = key;
                resolver = resolve;
            } else if (typeof val === 'object') {
                mapping = val.fromApi.key;
            }

            return {
                ...acc,
                [attr]: {
                    default: defaultFields[attr] || model[attr],
                    mapping,
                    resolver,
                    ...fields[attr],
                },
            };
        }, fields);

        return new Form(modelFields, Object.assign(options, opts));
    };
