import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import { ReactComponent as validate } from '@public/icons/validate.svg';
import SubscriptionFeedback from '../molecules/SubscriptionFeedback';
import Img from '../../ui/atoms/Img';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { fetchShowBySlug } from '@/api';
import { parseQuery } from '../../../api/query';

const Subscribe = () => {
    const [showData, setShowData] = useState();

    useEffect(() => {
        const { show } = parseQuery(window.location.search);

        (async () => {
            setShowData((await fetchShowBySlug(show)).data);
        })();
    }, []);

    return (
        <SubscriptionFeedback>
            <DeprecatedPaper pos="relative" mb={25} w="max-content">
                <Img size={70} src={showData?.image_url} radius={4} />
                <DeprecatedIcon as={validate} size={40} pos="absolute" bottom={-20} right={-20} />
            </DeprecatedPaper>
            <DeprecatedText fontSize={36} mb={5} weight="bold">
                <FormattedMessage defaultMessage="Inscription réussie." />
            </DeprecatedText>
            <DeprecatedText size="big" mb={30} align="center">
                <FormattedMessage
                    defaultMessage="Vous êtes bien inscrit à la newsletter {showName}"
                    values={{ showName: showData?.name }}
                />
            </DeprecatedText>
            <DeprecatedButton
                accent="--primary"
                maxW={400}
                w="100%"
                as="a"
                href={showData?.site_url}
            >
                <FormattedMessage defaultMessage="Commencez à écouter" />
            </DeprecatedButton>
        </SubscriptionFeedback>
    );
};

export default Subscribe;
