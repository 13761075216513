import { createRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { DayPickerSingleDateController } from 'react-dates';
import { START_DATE } from 'react-dates/constants';
import { observer } from 'mobx-react';
import 'react-dates/lib/css/_datepicker.css';
import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { useSwitch } from '@hooks/useSwitch';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import { ReactComponent as calendar } from '@public/icons/calendar.svg';
import { ReactComponent as back } from '@public/icons/arrow-left.svg';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { formatDateInput, formatDates } from '@/helpers';
import useGetBoundingClientRect from '@/utils/hooks/useGetBoundingClientRect';
import { useWindowDimensions } from '@hooks/useWindowDimensions';
import Input from '@ui/atoms/DeprecatedInput';
import DatePickerSelect from '../DatePickerSelect';
import moment from 'moment';
import UiKitTooltip from '@ui/atoms/UiKitTooltip/UiKitTooltip';

const WrapperInput = styled(DeprecatedPaper)`
    display: flex;
    align-items: center;
    border-radius: var(--r-xs);
    border: 1px solid var(--neutral200);
    padding: 0 10px;
    width: max-content;
    height: 40px;
    background: white;
    overflow: hidden;
    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            & > svg {
                fill: var(--neutral500);
            }
        `};
    &:hover {
        border: 1px solid var(--neutral500);
    }
    & > input {
        &:hover {
            border: none;
        }
        &:active,
        &:focus {
            border: 1px solid var(--primary);
        }
        &[readonly] {
            background: var(--neutral50);
            color: var(--black);
        }
        &:disabled {
            color: var(--neutral500);
        }
        &:invalid {
            border-color: var(--alert);
        }
    }
`;

const InputDate = styled(Input)`
    border: none;
    padding: 0 !important;
    height: auto;
    &::-webkit-calendar-picker-indicator {
        display: none;
    }
    &:focus {
        border: none !important;
    }
`;

const ButtonNavigation = ({ children, ...props }) => (
    <DeprecatedButton
        mt={15}
        ml={20}
        icon
        accent="secondary"
        style={{ borderRadius: '50%' }}
        {...props}
    >
        {children}
    </DeprecatedButton>
);

const animateDatePicker = {
    enter: { opacity: 1 },
    exit: { opacity: 0 },
};

const DatePicker = ({
    children,
    date,
    onChangeDate,
    label,
    required,
    update,
    blockedNextDays,
    blockedPrevDays,
    setIsTypeText,
    disabled,
    tooltip,
    ...props
}) => {
    const [datePickerIsOpen, onOpenDatePicker, onCloseDatePicker] = useSwitch();
    // UI positioning DatePicker
    const [ref, refContainer] = useGetBoundingClientRect();
    const refSimpleDatePicker = createRef();

    const { height } = useWindowDimensions();
    const [positionDatePicker, setPositionDatePicker] = useState(null);
    const [isSimpleDatePickerSelect, setIsSimpleDateTypeText] = useState(false);
    const [isFirefox, setIsFirefox] = useState(false);

    useEffect(() => {
        if (datePickerIsOpen) {
            // Get AirBnB DatePicker
            const ul = document.getElementsByClassName(
                'DayPicker_weekHeader_ul DayPicker_weekHeader_ul_1',
            );

            // Styled
            ul[0].style.cssText = 'font-size: 17px';

            // Positioned date picker
            const HEIGHT_DATE_PICKER = 311;
            if (ref.top + HEIGHT_DATE_PICKER > height) setPositionDatePicker(38);
            else setPositionDatePicker(null);
        }
    }, [datePickerIsOpen, ref, height]);

    useEffect(() => {
        if (refSimpleDatePicker.current) {
            if (refSimpleDatePicker.current.type === 'text') {
                setIsSimpleDateTypeText(true);
                if (setIsTypeText) setIsTypeText(true);
            } else {
                setIsSimpleDateTypeText(false);
                if (setIsTypeText) setIsTypeText(false);
            }
        }
    }, [refSimpleDatePicker.current]);

    useEffect(() => setIsFirefox(window.navigator.userAgent.toLowerCase().includes('firefox')), []);

    // Disabled date before or after
    const isDayBlocked = (day) => {
        if (blockedPrevDays) return dayjs(day).isBefore(dayjs(), 'day');
        if (blockedNextDays) return dayjs(day).isAfter(dayjs(), 'day');
        return false;
    };
    if (isSimpleDatePickerSelect)
        return <DatePickerSelect date={date} onChangeDate={onChangeDate} />;

    return (
        <UiKitTooltip position="top" passthrough={!tooltip} content={tooltip} variant="dark">
            <DeprecatedPaper pos="relative" z={datePickerIsOpen ? 2 : 1} maxW="max-content">
                {label && (
                    <DeprecatedText weight="semibold" mb={8}>
                        {label}
                        {required && (
                            <DeprecatedText ml={5} as="span" color="--primary">
                                *
                            </DeprecatedText>
                        )}
                    </DeprecatedText>
                )}

                <WrapperInput disabled={disabled}>
                    {!isFirefox && (
                        <DeprecatedIcon
                            as={calendar}
                            mr={10}
                            onClick={() => !disabled && onOpenDatePicker()}
                        />
                    )}
                    <InputDate
                        ref={refSimpleDatePicker}
                        type="date"
                        value={date}
                        onChange={(e) => onChangeDate(e.target.value)}
                        required
                        min={blockedPrevDays && dayjs().format('YYYY-MM-DD')}
                        max={blockedNextDays && dayjs().format('YYYY-MM-DD')}
                        height={38}
                        disabled={disabled}
                    />
                </WrapperInput>

                <AnimatePresence>
                    {datePickerIsOpen && (
                        <motion.div
                            animate={datePickerIsOpen ? 'enter' : 'exit'}
                            variants={animateDatePicker}
                            initial="exit"
                            exit="exit"
                            className="myCustomTimePicker"
                            ref={refContainer}
                            style={{
                                position: 'absolute',
                                bottom: positionDatePicker,
                            }}
                        >
                            <DayPickerSingleDateController
                                {...props}
                                id="date-input"
                                onDateChange={(e) => onChangeDate(formatDateInput(e.toDate()))}
                                daySize={35}
                                date={moment(dayjs(date || new Date()))}
                                focused
                                focusedInput={START_DATE}
                                onOutsideClick={onCloseDatePicker}
                                renderCalendarInfo={() => children}
                                weekDayFormat="dd"
                                numberOfMonths={1}
                                renderCalendarDay={renderCalendarDay}
                                renderMonthElement={renderMonthElement}
                                hideKeyboardShortcutsPanel
                                isDayBlocked={isDayBlocked}
                                onClose={onCloseDatePicker}
                                navPrev={
                                    <ButtonNavigation pos="absolute" y={0}>
                                        <DeprecatedIcon as={back} color="black" />
                                    </ButtonNavigation>
                                }
                                navNext={
                                    <ButtonNavigation right={20} pos="absolute" y={0}>
                                        <DeprecatedIcon
                                            as={back}
                                            style={{ transform: 'rotate(180deg)' }}
                                            color="black"
                                        />
                                    </ButtonNavigation>
                                }
                            />
                        </motion.div>
                    )}
                </AnimatePresence>
            </DeprecatedPaper>
        </UiKitTooltip>
    );
};

function renderCalendarDay({ day, daySize, onDayClick, modifiers }) {
    let background;
    let color = '--black';
    let weight;
    if (modifiers && modifiers.has('selected')) {
        background = 'secondary';
        color = 'white';
    } else if (modifiers && modifiers.has('today')) {
        background = '--neutral50';
        weight = 'bold';
    } else if (modifiers && modifiers.has('valid')) {
        background = 'white';
    } else if (modifiers && modifiers.has('blocked')) {
        color = '--neutral500';
    }

    if (!day) {
        return (
            <DeprecatedPaper
                w={daySize}
                background={background}
                style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    lineHeight: `${daySize}px`,
                }}
                height={daySize}
            />
        );
    }

    return (
        <DeprecatedPaper
            w={daySize}
            background={background}
            style={{
                display: 'inline-block',
                verticalAlign: 'middle',
                lineHeight: `${daySize}px`,
                borderRadius: '50px',
            }}
            height={daySize}
            onClick={() => onDayClick(day)}
        >
            <DeprecatedText
                align="center"
                justify="center"
                weight={weight}
                color={color}
                flex
                h="100%"
            >
                {day.date()}
            </DeprecatedText>
        </DeprecatedPaper>
    );
}

function renderMonthElement({ month }) {
    return (
        <DeprecatedText weight="bold" as="span" size="big">
            {formatDates('months', [month.month()])} {month.year()}
        </DeprecatedText>
    );
}

export default observer(DatePicker);
