import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useResponsive } from '@/utils/hooks/useResponsive';
import Stack from '@ui/layout/Stack';
import { getSavingOnYearlyBilling, isSamePricingThan, PRICING_NAME } from '@/utils/pricing';
import PricingContext from './PricingContext';
import PricingSelectOfferButton from './PricingSelectOfferButton';
import PricingCancelSubscriptionButton from './PricingCancelSubscriptionButton';
import Desktop from '@ui/molecules/Desktop';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import { extractCssVariable } from '@/utils/cssVariables';
import Text from '@ui/atoms/Text';
import Cluster from '@/components/ui/layout/Cluster';

const OfferLabel = styled.div`
    background-color: ${(props) => props.backgroundColor};
    border-bottom-left-radius: var(--r-s);
    border-top-right-radius: var(--r-s);
    padding: 0.25rem 0.5rem;
    position: absolute;
    right: -1px;
    top: -1px;
`;
const PricingOfferTitleWrapper = styled.div`
    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        min-height: 4.75rem;
    }
`;
const Container = styled.div`
    background-color: var(--white);
    padding: 1.5rem;
    border-radius: var(--r-s);
    position: relative;
    border: 2px solid;
    border-color: ${(props) => `var(${props.$borderColor})`};
`;

const CurrentOfferLabel = () => (
    <OfferLabel backgroundColor={extractCssVariable('--primary')}>
        <Text fontWeight="--fw-semibold" color="white" variant="footnote">
            <FormattedMessage defaultMessage="Offre actuelle" />
        </Text>
    </OfferLabel>
);

const HighlightedOfferLabel = () => (
    <OfferLabel backgroundColor={extractCssVariable('--primary')}>
        <Text fontWeight="--fw-semibold" color="white" variant="footnote">
            <FormattedMessage defaultMessage="Coup de 🤍 de nos podcasteurs" />
        </Text>
    </OfferLabel>
);

const OfferImage = styled.img`
    // Offer image should go on top of highlighted label if necessary
    isolation: isolate;
    height: 7.5rem;
    align-self: flex-start;
    margin-top: calc(-1.5rem - (8rem / 2));

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        margin-top: calc(-1.5rem - (5rem / 2));
    }
`;

const BilledAnnuallyLabel = styled(Text)`
    line-height: 1.5em;
    font-size: 0.625rem;
    visibility: ${(props) => (props.isYearlyPeriodicitySelected ? 'visible' : 'hidden')};

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        font-size: 0.75rem;
    }
`;

const SavingOnYearlyBillingLabel = styled(Text)`
    color: var(--success);
    line-height: 1.5em;
    text-align: center;
`;

const OfferPrice = ({ price, currency }) => {
    const { isYearlyPeriodicitySelected } = useContext(PricingContext);

    return (
        // Margin used for alignment
        <Stack $align="flex-end" css="margin-top: 0.1875rem;">
            <Text whiteSpace="nowrap">
                <FormattedMessage
                    defaultMessage="{price} / mois"
                    values={{
                        price: (
                            <Text as="strong" fontWeight="--fw-bold" variant="largeTitle">
                                <FormattedNumber
                                    value={price}
                                    style="currency"
                                    currency={currency}
                                    currencyDisplay="narrowSymbol"
                                    maximumFractionDigits={Number.isInteger(price) ? 0 : 2}
                                />
                            </Text>
                        ),
                    }}
                />
            </Text>
            <BilledAnnuallyLabel
                isYearlyPeriodicitySelected={isYearlyPeriodicitySelected}
                weight="semibold"
                color="--neutral500"
            >
                <FormattedMessage defaultMessage="facturé annuellement" />
            </BilledAnnuallyLabel>
        </Stack>
    );
};

const PricingOfferWrapper = ({ children }) => {
    const { isDesktop } = useResponsive();

    if (isDesktop) {
        // On desktop screen, cancel button is inside PricingOffer container and uses absolute position
        // to minimize layout changes
        return children;
    }

    // Cancel button is outside PricingOffer container and take its place in layout flow
    return <Stack $gap="1rem">{children}</Stack>;
};

const PricingOffer = ({
    offer,
    offerDescription,
    offerImage,
    offerPrice,
    isCurrentOffer,
    isHighlighted,
    children,
}) => {
    const { isDesktop } = useResponsive();
    const {
        isCurrentSubscriptionActive,
        pricesOfCurrentSubscriptionPricing,
        isCurrentSubscriptionBilledYearly,
        currentSubscriptionPricing,
        selectedCurrency,
    } = useContext(PricingContext);

    // If the current subscription is billed monthly, a label with the savings
    // made by switching to yearly billing must be displayed.
    const isSavingOnYearlyBillingLabelDisplayed = useMemo(
        () =>
            isCurrentSubscriptionActive &&
            isSamePricingThan(offer, currentSubscriptionPricing) &&
            !isCurrentSubscriptionBilledYearly,
        [
            isCurrentSubscriptionActive,
            offer,
            currentSubscriptionPricing,
            isCurrentSubscriptionBilledYearly,
        ],
    );
    const savingOnYearlyBilling = useMemo(() => {
        if (!pricesOfCurrentSubscriptionPricing) {
            return 0;
        }
        return getSavingOnYearlyBilling(
            pricesOfCurrentSubscriptionPricing.monthly.base,
            pricesOfCurrentSubscriptionPricing.yearly.base,
        );
    }, [pricesOfCurrentSubscriptionPricing]);

    let borderColor = '--white';

    if (isHighlighted) {
        borderColor = '--primary';
    }
    if (isCurrentOffer) {
        borderColor = '--primary';
    }

    return (
        <PricingOfferWrapper>
            <Container $borderColor={borderColor}>
                {isCurrentOffer && <CurrentOfferLabel />}
                {isHighlighted && !isCurrentOffer && <HighlightedOfferLabel />}
                {/* eslint-disable-next-line */}
                <Stack
                    $gap={
                        isSavingOnYearlyBillingLabelDisplayed
                            ? '1rem'
                            : isDesktop
                            ? '2rem'
                            : '1.5rem'
                    }
                >
                    <Stack $gap="1rem">
                        <OfferImage offer={offer} src={offerImage} alt="" />
                        <PricingOfferTitleWrapper>
                            <Cluster
                                $gap="0.5rem"
                                $justify="space-between"
                                $align={isDesktop ? 'flex-start' : 'center'}
                            >
                                <Stack css="flex: 1 0 6rem;">
                                    <Text as="h2" fontWeight="--fw-bold" variant="title">
                                        {PRICING_NAME[offer]}
                                    </Text>
                                    <Text color="--neutral500">{offerDescription}</Text>
                                </Stack>
                                <OfferPrice price={offerPrice} currency={selectedCurrency} />
                            </Cluster>
                        </PricingOfferTitleWrapper>
                        <PricingSelectOfferButton offer={offer} />
                    </Stack>
                    {isSavingOnYearlyBillingLabelDisplayed && (
                        <SavingOnYearlyBillingLabel weight="semibold">
                            <FormattedMessage
                                defaultMessage="Économisez {saving}"
                                values={{
                                    saving: (
                                        <FormattedNumber
                                            value={savingOnYearlyBilling}
                                            style="currency"
                                            currency={selectedCurrency}
                                            currencyDisplay="narrowSymbol"
                                            maximumFractionDigits={
                                                Number.isInteger(savingOnYearlyBilling) ? 0 : 2
                                            }
                                        />
                                    ),
                                }}
                            />
                        </SavingOnYearlyBillingLabel>
                    )}
                    {children}
                </Stack>
                {isCurrentOffer && (
                    <Desktop>
                        <PricingCancelSubscriptionButton />
                    </Desktop>
                )}
            </Container>
            {isCurrentOffer && (
                <MobileOrTablet>
                    <PricingCancelSubscriptionButton />
                </MobileOrTablet>
            )}
        </PricingOfferWrapper>
    );
};

export default PricingOffer;
