import { computed } from 'mobx';
import Model from './Model';
import { dateUTC } from './resolvers';
import { apiCall } from '../components/app/decorators/api';

class SeasonModel extends Model {
    static attributes = {
        id: 'id',
        showId: 'show_id',
        number: 'season_number',
        podcastsCount: 'podcasts_count',
        updatedAt: dateUTC('updated_at'),
        createdAt: dateUTC('created_at'),
    };

    @computed
    get show() {
        return this.store.state.showStore.byId(this.showId);
    }

    @apiCall
    delete() {
        return this.store.deleteSeason(this);
    }
}

export default SeasonModel;
