import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deletePodcastListeningLink } from '@/api';
import episodeListeningLinkKeys from '@/queries/episodeListeningLink/episodeListeningLinkKeys';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ episodeId, key }) => {
    const { data } = await deletePodcastListeningLink(episodeId, key);

    return transform(data);
};

const useDeleteEpisodeListeningLinkMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (data, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: episodeListeningLinkKeys.listByEpisodeId(episodeId),
            });
        },
    });
};

export default useDeleteEpisodeListeningLinkMutation;
