import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Text from '@/components/ui/atoms/Text';
import Button from '@/components/ui/atoms/Button';
import styled from 'styled-components';
import { UI_VARIANTS } from '@/utils/constants';
import DialogAlert from '@/components/ui/atoms/DialogAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useTranscriptQuotaPriceQuery from '@/queries/quota/useTranscriptQuotaPriceQuery.hook';

const TranscriptionEditQuotaConfirmationModal = ({
    isOpen,
    onOpenChange,
    isLoading,
    onSubmit,
    quantity,
}) => {
    const { data: price } = useTranscriptQuotaPriceQuery(quantity);

    return (
        <DialogAlert
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            action={
                <>
                    <Button isLoading={isLoading} onPress={() => onSubmit(quantity)}>
                        <FormattedMessage defaultMessage="Confirmer la modification" />
                    </Button>
                    <CancelButton onPress={() => onOpenChange(false)} variant="ghost">
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </>
            }
            variant={UI_VARIANTS.PRIMARY}
            icon={<TrashIcon icon={icon({ name: 'receipt', style: 'solid' })} />}
            title={
                <FormattedMessage defaultMessage="Êtes-vous sûr de vouloir modifier votre limite de temps de transcription ?" />
            }
        >
            <Text variant="bodyM" color="--neutral500" textAlign="center">
                <FormattedMessage
                    defaultMessage="En validant cette modification, le coût de votre abonnement sera maintenant de <b>{totalPrice} /an</b>. Vous serez facturé de <b>{price}</b> dès maintenant."
                    values={{
                        price: price?.cost,
                        totalPrice: price?.total,
                        b: (chunks) => (
                            <Text as="span" fontWeight="--fw-semibold">
                                {chunks}
                            </Text>
                        ),
                    }}
                />
            </Text>
        </DialogAlert>
    );
};

const CancelButton = styled(Button)`
    color: var(--neutral500);
    padding: 0;
`;
const TrashIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    width: 1rem;
    height: 1rem;
`;

TranscriptionEditQuotaConfirmationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    quantity: PropTypes.number,
    onOpenChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
};

TranscriptionEditQuotaConfirmationModal.defaultProps = {
    isOpen: false,
    isLoading: false,
    onOpenChange: () => {},
    onSubmit: () => {},
};

export default TranscriptionEditQuotaConfirmationModal;
