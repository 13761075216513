import { createContext, useContext } from 'react';

export const EpisodeBeingEditedContext = createContext(null);

export const useEpisodeBeingEdited = () => {
    return useContext(EpisodeBeingEditedContext);
};

const EpisodeEditFormContext = ({ episode, children }) => {
    return (
        <EpisodeBeingEditedContext.Provider value={episode}>
            {children}
        </EpisodeBeingEditedContext.Provider>
    );
};

export default EpisodeEditFormContext;
