import { FormattedMessage } from 'react-intl';
import IconEmojiStars from '@ui/icons/IconEmojiStars';
import Stack from '@ui/layout/Stack';
import styled from 'styled-components';
import Text from '@ui/atoms/Text';

const TitleStyled = styled(Text)`
    text-align: center;
    line-height: 1.5em;

    ${(p) => p.theme.mediaQueries.mobile} {
        font-size: var(--fs-heading);
    }
`;
const SubtitleStyled = styled(Text)`
    text-align: center;
    line-height: 1.5em;
    max-width: 60ch;
    color: #88848c;
`;
const IconEmojiStarsStyled = styled(IconEmojiStars)`
    width: 1.5rem;
    height: 1.5rem;
    transform: translateY(15%);
`;

const Title = () => (
    <TitleStyled
        as="h1"
        variant="title"
        fontWeight="--fw-bold"
        data-testid="plan-selection-page-heading"
    >
        <FormattedMessage
            defaultMessage="Vous êtes à un clic d’un nouvel univers {emoji}"
            values={{
                emoji: <IconEmojiStarsStyled />,
            }}
        />
    </TitleStyled>
);

const Subtitle = () => (
    <SubtitleStyled>
        <FormattedMessage defaultMessage="Du lancement à la croissance de votre podcast, pilotez la diffusion, la communication et les statistiques de votre émission avec une seule plateforme." />
    </SubtitleStyled>
);

const PricingTitles = () => (
    <Stack $gap="0.25rem" $align="center">
        <Title />
        <Subtitle />
    </Stack>
);

export default PricingTitles;
