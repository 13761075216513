import { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from '@app/decorators/connect';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import Spinner from '@ui/atoms/Spinner';
import PricingContext from './PricingContext';
import { PRICING, PRICING_NAME } from '@/utils/pricing';
import { useStripe } from '@stripe/react-stripe-js';
import Modal from '@ui/molecules/Modal';
import Text from '@ui/atoms/Text';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ui/atoms/Button';
import { SUBSCRIPTION_ACTION } from '@/utils/constants';

const LOST_FEATURE_LIST = {
    BoostToLaunch: {
        left: [
            <FormattedMessage
                key="boost-youtube"
                defaultMessage="La diffusion de votre podcast en vidéo sur Youtube en quelques clics"
            />,
            <FormattedMessage
                key="boost-instagram"
                defaultMessage="Le partage de vos posts sur Instagram et LinkedIn"
            />,
            <FormattedMessage
                key="boost-stats"
                defaultMessage="Des statistiques avancées pour mieux connaitre votre audience (auditeurs uniques, meilleure heure de publication avec PeakTime, etc.)"
            />,
            <FormattedMessage
                key="boost-customlisteninglink"
                defaultMessage="La personnalisation de vos liens d’écoute"
            />,
        ],
        right: [
            <FormattedMessage
                key="boost-videoteaser"
                defaultMessage="La création de 12 Teasers vidéo chaque mois avec le Clip Vidéo"
            />,
            <FormattedMessage
                key="boost-monetisation"
                defaultMessage="Les campagnes manuelles de monétisation"
            />,
            <FormattedMessage key="boost-archived" defaultMessage="3 émissions archivées" />,
            <FormattedMessage
                key="boost-multiuser"
                defaultMessage="La possibilité de gérer votre podcast en équipe avec jusqu’à 3 utilisateurs"
            />,
            <FormattedMessage
                key="boost-pso"
                defaultMessage="Le suivi de tous vos mots-clés sur votre PSO Control Panel"
            />,
        ],
    },
    SupersonicToLaunch: {
        left: [
            <FormattedMessage
                key="supersonic-youtube"
                defaultMessage="La diffusion de votre podcast en vidéo sur Youtube en quelques clics"
            />,
            <FormattedMessage
                key="supersonic-instagram"
                defaultMessage="Le partage de vos posts sur Instagram et LinkedIn"
            />,
            <FormattedMessage
                key="supersonic-privateplaylist"
                defaultMessage="La possibilité de créer des listes d’épisodes confidentielles avec les Playlists Protégées"
            />,
            <FormattedMessage
                key="supersonic-api"
                defaultMessage="L’utilisation de notre API sécurisée"
            />,
            <FormattedMessage
                key="supersonic-stats"
                defaultMessage="Des statistiques avancées pour mieux comprendre votre audience (auditeurs uniques, complétion de vos épisodes, répartition de vos auditeurs par ville, etc.)"
            />,
        ],
        right: [
            <FormattedMessage
                key="supersonic-cta"
                defaultMessage="L’ajout de boutons personnalisés sur vos outils de communication Ausha (Siteweb, Smartlink & Smartplayer) pour capitaliser sur votre audience"
            />,
            <FormattedMessage
                key="supersonic-webhooks"
                defaultMessage="La création de Webhooks personnalisés pour automatiser la gestion de votre podcast"
            />,
            <FormattedMessage
                key="supersonic-videoteaser"
                defaultMessage="La création de 24 Teasers vidéo chaque mois avec le Clip Vidéo"
            />,
            <FormattedMessage key="supersonic-archived" defaultMessage="5 émissions archivées" />,
            <FormattedMessage
                key="supersonic-multiuser"
                defaultMessage="La possibilité de gérer votre podcast en équipe avec jusqu’à 5 utilisateurs"
            />,
            <FormattedMessage
                key="supersonic-pso"
                defaultMessage="Le suivi de tous vos mots-clés sur votre PSO Control Panel"
            />,
        ],
    },
    SupersonicToBoost: {
        left: [
            <FormattedMessage
                key="stob-privateplaylist"
                defaultMessage="La possibilité de créer des listes d’épisodes confidentielles avec les Playlists Protégées"
            />,
            <FormattedMessage
                key="stob-api"
                defaultMessage="L’utilisation de notre API sécurisée"
            />,
            <FormattedMessage
                key="stob-webhooks"
                defaultMessage="La création de Webhooks personnalisés pour automatiser la gestion de votre podcast"
            />,
            <FormattedMessage
                key="stob-cta"
                defaultMessage="L’ajout de boutons personnalisés sur vos outils de communication Ausha (Siteweb, Smartlink & Smartplayer) pour capitaliser sur votre audience"
            />,
        ],
        right: [
            <FormattedMessage
                key="stob-stats"
                defaultMessage="Des statistiques avancées pour mieux comprendre votre audience (complétion de vos épisodes, durée moyenne d’écoute, répartition de vos auditeurs par ville, etc.)"
            />,
            <FormattedMessage
                key="stob-videoteaser"
                defaultMessage="La création de 24 Teasers vidéo chaque mois avec le Clip Vidéo"
            />,
            <FormattedMessage key="stob-archived" defaultMessage="5 émissions archivées" />,
            <FormattedMessage
                key="stob-multiuser"
                defaultMessage="La possibilité de gérer votre podcast en équipe avec jusqu’à 5 utilisateurs"
            />,
            <FormattedMessage
                key="stob-pso"
                defaultMessage="Le suivi de 50 mots-clés sur votre PSO Control Panel"
            />,
        ],
    },
};

const enhance = connect(({ subscriptionStore }) => ({
    subscription: subscriptionStore.subscription,
    switchPlan: (isBilledYearly, offer, stripe) =>
        subscriptionStore.subscription.switchPlan(isBilledYearly, offer, stripe),
    isPending: subscriptionStore.subscription.switchPlan.pending,
}));

const PricingUpdateOfferModal = ({ isOpened, onClose, subscription, switchPlan, isPending }) => {
    const stripe = useStripe();
    const { offerUpdatePayload, setSuccessPayload, setErrorPayload } = useContext(PricingContext);

    const getFeatureListContent = () => {
        if (offerUpdatePayload?.action !== SUBSCRIPTION_ACTION.DOWNGRADE) return;
        if (subscription?.subscriptionPricing === PRICING.SUPERSONIC) {
            return offerUpdatePayload?.offer === PRICING.BOOST
                ? LOST_FEATURE_LIST.SupersonicToBoost
                : LOST_FEATURE_LIST.SupersonicToLaunch;
        }
        return LOST_FEATURE_LIST.BoostToLaunch;
    };

    const onUpdateOffer = async () => {
        const { offer, isBilledYearly, action } = offerUpdatePayload;
        try {
            await switchPlan(isBilledYearly, offer, stripe);
            onClose();
            setSuccessPayload({ offer, isBilledYearly, action });
        } catch (error) {
            onClose();
            setErrorPayload({ offer, isBilledYearly, error });
        }
    };

    const UpgradeOrPeriodicityUpdateModal = (
        <Modal
            isOpen={isOpened}
            onClose={onClose}
            title={
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Confirmer le changement ?" />
                </Text>
            }
        >
            <Stack $gap="2.5rem">
                <Text>
                    <FormattedMessage
                        defaultMessage="Vous bénéficierez de l’offre <strong>{offer} {periodicity}</strong>."
                        values={{
                            strong: (chunks) => <strong>{chunks}</strong>,
                            offer: PRICING_NAME[offerUpdatePayload?.offer],
                            periodicity: offerUpdatePayload?.isBilledYearly ? (
                                <FormattedMessage defaultMessage="annuelle" />
                            ) : (
                                <FormattedMessage defaultMessage="mensuelle" />
                            ),
                        }}
                    />
                </Text>
                <Cluster $gap="0.5rem" $justify="flex-end" $wrap="nowrap">
                    <Button variant="secondary" onPress={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button
                        disabled={isPending}
                        onPress={onUpdateOffer}
                        startIcon={isPending && <Spinner color="--white" />}
                    >
                        <FormattedMessage defaultMessage="Confirmer" />
                    </Button>
                </Cluster>
            </Stack>
        </Modal>
    );

    const DowngradeModal = (
        <Modal
            isOpen={isOpened}
            onClose={onClose}
            size="small"
            title={
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage
                        defaultMessage="Basculer vers l'offre {offer}"
                        values={{ offer: PRICING_NAME[offerUpdatePayload?.offer] }}
                    />
                </Text>
            }
        >
            <Stack $gap="1.5rem">
                <FeatureList>
                    <Text variant="headingS" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Vous perdrez l’accès à..." />
                    </Text>
                    <Columns>
                        <Stack $gap="1rem">
                            {getFeatureListContent()?.left.map((item, index) => (
                                <Cluster key={index} $gap="0.5rem" $wrap="nowrap">
                                    <CircleMinusIcon
                                        icon={iconFA({ name: 'circle-minus', style: 'solid' })}
                                    />
                                    <Text>{item}</Text>
                                </Cluster>
                            ))}
                        </Stack>
                        <Stack $gap="1rem">
                            {getFeatureListContent()?.right.map((item) => (
                                <Cluster key={item} $gap="0.5rem" $wrap="nowrap">
                                    <CircleMinusIcon
                                        icon={iconFA({ name: 'circle-minus', style: 'solid' })}
                                    />
                                    <Text>{item}</Text>
                                </Cluster>
                            ))}
                        </Stack>
                    </Columns>
                </FeatureList>
                <ActionButtons>
                    <Text
                        fontWeight="--fw-semibold"
                        color="--neutral500"
                        onClick={onClose}
                        textAlign="center"
                        style={{ cursor: 'pointer' }}
                    >
                        <FormattedMessage defaultMessage="Conserver mon offre" />
                    </Text>
                    <Button
                        disabled={isPending}
                        onPress={onUpdateOffer}
                        startIcon={isPending && <Spinner color="--white" />}
                    >
                        <FormattedMessage
                            defaultMessage="Basculer vers l'offre {offer}"
                            values={{ offer: PRICING_NAME[offerUpdatePayload?.offer] }}
                        />
                    </Button>
                </ActionButtons>
            </Stack>
        </Modal>
    );

    return (
        <>
            {offerUpdatePayload?.action === SUBSCRIPTION_ACTION.DOWNGRADE
                ? DowngradeModal
                : UpgradeOrPeriodicityUpdateModal}
        </>
    );
};

const FeatureList = styled(Stack)`
    background: var(--warning50);
    border-radius: var(--r-m);
    padding: 1.5rem;
    gap: 1rem;
`;

const Columns = styled.div`
    display: grid;
    flex-direction: column;
    gap: 1rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        grid-template-columns: 1fr 1fr;
    }
`;

const CircleMinusIcon = styled(FontAwesomeIcon)`
    color: var(--warning500);
    padding: 0.25rem;
`;

const ActionButtons = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    gap: 1rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

export default enhance(PricingUpdateOfferModal);
