import { runInAction } from 'mobx';
import { createTransformer } from 'mobx-utils';
import {
    addInvitation,
    fetchInvitations,
    fetchPermissions,
    deleteInvitation,
    updatePermission,
    deletePermission,
} from '@/api';
import Store from './Store';
import InvitationModel from '../models/InvitationModel';
import PermissionModel from '../models/PermissionModel';
import { apiCall, apiFetch } from '../components/app/decorators/api';

class InvitationsStore extends Store {
    static observables = {
        permissions: [],
        invitations: [],
        roles: ['viewer', 'editor', 'admin'],
    };

    permissionById = createTransformer((id) => this.permissions.find((p) => `${p.id}` === `${id}`));

    @apiFetch
    async fetchPermissions(showId) {
        const { data } = await fetchPermissions(showId);
        runInAction(() => {
            this.permissions = data.map((permission) => new PermissionModel(this, permission));
        });
    }

    @apiFetch
    async fetchInvitations(showId) {
        const { data } = await fetchInvitations(showId);
        runInAction(() => {
            this.invitations = data.map((invitation) => new InvitationModel(this, invitation));
        });
    }

    @apiCall
    async addInvitation(formData) {
        const { data } = await addInvitation(this.state.routerStore.params.showId, formData);
        runInAction(() => {
            this.invitations.push(new InvitationModel(this, data));
        });
    }

    @apiCall
    async deleteInvitation(invitationId) {
        await deleteInvitation(invitationId);

        runInAction('delete invitation success', () => {
            this.invitations = this.invitations.filter((i) => i.id !== invitationId);
        });
    }

    @apiCall
    async deletePermission(permissionId) {
        await deletePermission(permissionId);

        runInAction('delete invitation success', () => {
            this.permissions = this.permissions.filter((i) => i.id !== permissionId);
        });
    }

    @apiCall
    async updateRole(permission, role) {
        const { data } = await updatePermission(permission, { role });

        runInAction(() => {
            this.permissionById(permission).userRole = data.role;
        });
    }
}
export default InvitationsStore;
