import { useQuery, UseQueryResult, QueryFunctionContext } from '@tanstack/react-query';
import { fetchShow } from '@/api';
import showKeys from '@/queries/show/showKeys';
import camelcaseKeys from 'camelcase-keys';
import { STATUS_SHOWS } from '@/utils/statusShows';

export interface Show {
    archived: boolean;
    author: string;
    campaigns_count: number;
    canComment: boolean;
    category: { data: Category };
    categoryId: number;
    channelId: number;
    clip_color: string;
    created_at: string;
    darkLogoUrl: string | null;
    description: string;
    downloadsCount: number;
    duration: number;
    faviconUrl: string | null;
    hasPublicActivePodcasts: boolean;
    htmlDescription: string;
    id: number;
    imageUrl: string;
    language: { data: Language };
    languageId: number;
    lightLogoUrl: string | null;
    listeningLinks: { data: Link[] };
    logoLink: string | null;
    name: string;
    ownerEmail: string;
    podcastsCount: number;
    podcastsUpdated: string;
    publicCounts: boolean;
    publicId: string;
    rssLink: string;
    secondaryCategoryId: number | null;
    siteUrl: string;
    slug: string;
    socialLinks: { data: Link[] };
    tags: { data: Tag[] };
    type: string;
    updatedAt: string;
    userOptions: string[];
    userRole: string;
    whiteLabel: boolean;
}

interface Category {
    apple_id: number;
    id: number;
    name: string;
    parent_id: number | null;
    slug: string;
}

interface Language {
    code: string;
    id: number;
    is_featured: boolean;
    name: string;
    native_name: string;
}

interface Link {
    key: string;
    url: string;
}

interface Tag {
    id: number;
    name: string;
    slug: string;
}

const transform = (data: Show) => {
    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });

    const showStatus = transformedData.archived
        ? STATUS_SHOWS.ARCHIVED_SHOWS
        : !transformedData.userRole.includes('owner')
        ? STATUS_SHOWS.GUEST_SHOWS
        : STATUS_SHOWS.ACTIVE_SHOWS;

    return {
        ...transformedData,
        showStatus,
    };
};

const queryFn = async ({
    queryKey: [{ showId }],
}: QueryFunctionContext<ReturnType<typeof showKeys.detailById>>) => {
    const { data } = await fetchShow(showId);

    return transform(data);
};

const useShowQuery = (showId: string): UseQueryResult<Show> => {
    return useQuery({
        queryKey: showKeys.detailById(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useShowQuery;
