import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import { resolveColor } from '@/utils/cssVariables';

const Div = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background: ${(props) =>
        props.backgroundColor ? resolveColor(props.backgroundColor) : 'var(--neutral50)'};
`;

const Wrapper = posed(Div)({
    enter: { opacity: 1, y: 0, transition: { duration: 250 } },
    exit: { opacity: 0, y: 100, transition: { duration: 250 } },
});

export default class FullscreenModal extends Component {
    constructor() {
        super();
        this.el = document.createElement('div');
        this.el.id = 'modal-background';
        this.modalRoot = document.getElementById('modal-root');
    }

    static propTypes = {
        isOpened: PropTypes.bool,
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
            .isRequired,
    };

    static defaultProps = {
        isOpened: false,
    };

    componentDidMount() {
        this.handleOpen();
    }

    componentDidUpdate() {
        this.handleOpen();
        if (!this.props.isOpened) {
            this.handleClose();
        }
    }

    componentWillUnmount() {
        this.handleClose();
    }

    handleOpen() {
        if (this.props.isOpened) {
            this.modalRoot.appendChild(this.el);
            document.documentElement.style.overflowY = 'hidden';
        }
    }

    handleClose() {
        setTimeout(() => {
            if (this.modalRoot.contains(this.el)) {
                this.modalRoot.removeChild(this.el);
            }
            document.documentElement.style.overflowY = 'scroll';
        }, 200);
    }

    render() {
        const { isOpened, ...props } = this.props;
        return ReactDOM.createPortal(
            <PoseGroup animateOnMount>{isOpened && <Wrapper key={0} {...props} />}</PoseGroup>,
            this.el,
        );
    }
}
