import { runInAction } from 'mobx';
import { createTransformer } from 'mobx-utils';
import Store from './Store';
import { addSeason, deleteSeason, fetchSeasons } from '@/api';
import SeasonModel from '../models/SeasonModel';
import { apiCall, apiFetch } from '../components/app/decorators/api';

class SeasonStore extends Store {
    static observables = {
        seasons: [],
    };

    byId = createTransformer((id) => this.seasons.find((season) => `${season.id}` === `${id}`));

    @apiFetch
    async fetchSeasons(showId) {
        const { data } = await fetchSeasons(showId);
        runInAction(() => {
            this.seasons = data.map((season) => new SeasonModel(this, season));
        });
    }

    @apiCall
    async addSeason(showId) {
        const { data } = await addSeason(showId);
        runInAction(() => {
            this.seasons.unshift(new SeasonModel(this, data));
        });
    }

    async deleteSeason(season) {
        await deleteSeason(season.id);
        runInAction(() => {
            this.seasons.remove(season);
        });
    }
}

export default SeasonStore;
