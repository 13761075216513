import { action, computed, runInAction } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Store from './Store';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import {
    addCallToActionButton,
    fetchCallToActionButtons,
    updateCallToActionButtons,
    deleteCallToActionButton,
    updateCallToActionButtonsPositions,
} from '@/api';
import CallToActionModel from '../models/CallToActionModel';
import { LINKS_NAMES, LOCATIONS_LABEL } from '../components/app/organisms/CTAAccessAllowed';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { UI_VARIANTS } from '@/utils/constants';

const { PAGE_PODCAST, SMARTLINK, PLAYER, NEWSLETTER, CUSTOM } = LINKS_NAMES;

class CallToActionStore extends Store {
    static observables = {
        callToActionButtons: [],
        currentDragAndDropData: {
            hasChanged: false,
            newList: [],
        },
    };

    @apiFetch
    async fetchCallToActionButtons(showId, location) {
        const { data } = await fetchCallToActionButtons(showId, location);
        runInAction(() => {
            this.callToActionButtons = data.map(
                (ctaButton) => new CallToActionModel(this, ctaButton),
            );
        });
    }

    @apiCall
    async addCallToAction(showId, formData) {
        const { data } = await addCallToActionButton(showId, formData);
        try {
            runInAction(() => {
                this.callToActionButtons = [
                    ...this.callToActionButtons,
                    new CallToActionModel(this, data),
                ];
            });
        } catch (e) {
            modalToastQueue.add(null, { variant: UI_VARIANTS.ALERT, timeout: 5000 });
        }
    }

    @apiCall
    async updateCallToAction(showId, formData) {
        const { data } = await updateCallToActionButtons(formData.id, { ...formData });
        try {
            this.updateCallToActionList(data);
        } catch (e) {
            modalToastQueue.add(null, { variant: UI_VARIANTS.ALERT, timeout: 5000 });
        }
    }

    @apiCall
    async deleteCallToActionButton(showId, ctaId) {
        await deleteCallToActionButton(ctaId);
        try {
            modalToastQueue.add(
                <FormattedMessage defaultMessage="Votre boutton a été supprimé" />,
                {
                    variant: UI_VARIANTS.SUCCESS,
                    timeout: 5000,
                },
            );
            runInAction(() => {
                this.callToActionButtons = this.callToActionButtons.filter(
                    (cta) => cta.id !== ctaId,
                );
            });
        } catch (e) {
            modalToastQueue.add(null, { variant: UI_VARIANTS.ALERT, timeout: 5000 });
        }
    }

    @apiCall
    async removeCallToActionLocations(showId, ctaId, type) {
        const location = this.callToActionButtons
            .find((cta) => cta.id === ctaId)
            .locations.filter((removeCta) => removeCta !== type);
        const { data } = await updateCallToActionButtons(ctaId, { locations: location });
        try {
            modalToastQueue.add(
                <FormattedMessage
                    defaultMessage="Votre bouton a été supprimé de votre {location}"
                    values={{ location: LOCATIONS_LABEL[type] }}
                />,
                {
                    variant: UI_VARIANTS.SUCCESS,
                    timeout: 5000,
                },
            );
            this.updateCallToActionList(data);
        } catch (e) {
            modalToastQueue.add(null, { variant: UI_VARIANTS.ALERT, timeout: 5000 });
        }
    }

    async updateCallToActionButtonsPositions(showId, query) {
        if (!this.currentDragAndDropData.hasChanged) return;
        const { location } = query;
        await updateCallToActionButtonsPositions(showId, {
            location,
            ids: this.currentDragAndDropData.newList.map((cta) => cta.id),
        });
        try {
            modalToastQueue.add(null, {
                variant: UI_VARIANTS.SUCCESS,
                timeout: 5000,
            });
            runInAction(() => this.resetDragAndDropData());
        } catch (e) {
            modalToastQueue.add(null, { variant: UI_VARIANTS.ALERT, timeout: 5000 });
        }
    }

    @action
    setCurrentDragAndDropData(dragAndDropData) {
        this.currentDragAndDropData = dragAndDropData;
    }

    @action
    resetDragAndDropData() {
        this.currentDragAndDropData = { hasChanged: false, newList: [] };
    }

    @action
    updateCallToActionList(data) {
        runInAction(() => {
            const currentIndexToChange = this.callToActionButtons.findIndex(
                (ctaItem) => ctaItem.id === data.id,
            );
            this.callToActionButtons.splice(
                currentIndexToChange,
                1,
                new CallToActionModel(this, data),
            );
        });
    }

    @computed
    get getCurrentCallToActionType() {
        return this.callToActionButtons.map((cta) => cta.type);
    }

    @computed
    get getLocationButtons() {
        const getArrayLocationType = (locationType) =>
            this.callToActionButtons.filter((cta) => cta.locations.includes(locationType) && cta);

        return {
            [PAGE_PODCAST]: getArrayLocationType(PAGE_PODCAST),
            [PLAYER]: getArrayLocationType(PLAYER),
            [SMARTLINK]: getArrayLocationType(SMARTLINK),
            [NEWSLETTER]: getArrayLocationType(NEWSLETTER),
            [CUSTOM]: getArrayLocationType(LINKS_NAMES.CUSTOM),
        };
    }

    @computed
    get getNoCallToActionsButtons() {
        return this.callToActionButtons.length === 0;
    }
}

export default CallToActionStore;
