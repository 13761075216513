import { formatDates, makeArray } from '../../../../helpers';
import SelectNative from '../SelectNative';

const populateTimeHours = (source) => {
    const hours = 24;
    // timePickerSelect is an array === [HH:mm]
    const currentType = source.split(':')[0];
    // Creating array, if timeType => hours === 24 else if minutes === 60 then convert to string e.g: [..., '12', '13', '14', ...]
    const timeOption = makeArray(hours).map((m) => m.toString().padStart(2, '0'));
    return timeOption.map((time) => (
        <option key={time} selected={time === currentType}>
            {time}
        </option>
    ));
};

const HourSelect = ({ onChange, source }) => (
    <SelectNative
        label={formatDates('times', 'hour')}
        name="hour"
        source={populateTimeHours(source)}
        onChange={(e) => onChange(`${e.target.value}:${source.split(':')[1]}`)}
    />
);

export default HourSelect;
