import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { ReactComponent as FileUpload } from '@public/images/file-upload.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@ui/layout/Cluster';
import { extractCssVariable } from '@/utils/cssVariables';
import PropTypes from 'prop-types';
import { MAX_AUDIO_FILE_SIZE } from '@/utils/constants';

const Label = styled.label`
    border: 2px dashed var(--neutral100);
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    border-radius: var(--r-l);
    width: 100%;
    height: 400px;
    padding-inline: 2rem;
    cursor: pointer;

    ${({ isDragActive }) =>
        isDragActive &&
        css`
            background-color: var(--primary50);
        `}

    ${({ hasError }) =>
        hasError &&
        css`
            border-color: var(--alert100);
        `}

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding-inline: 4rem;
    }
`;
const HiddenInput = styled.input`
    display: none;
`;

const DropFileUploader = ({ name, onChange, hasError, errorMessage, accept, maxSize }) => {
    const [isDragActive, setIsDragActive] = useState(false);
    const [file, setFile] = useState(null);

    const overrideEventDefaults = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDragEnter = (e) => {
        if (file && !hasError) return;
        overrideEventDefaults(e);
        setIsDragActive(true);
    };
    const handleDragOver = (e) => {
        if (file && !hasError) return;
        overrideEventDefaults(e);
        if (isDragActive) return;
        setIsDragActive(true);
    };
    const handleDragLeave = (e) => {
        if (file && !hasError) return;
        overrideEventDefaults(e);
        setIsDragActive(false);
    };
    const handleDrop = (e) => {
        overrideEventDefaults(e);
        setIsDragActive(false);
        if (!e.dataTransfer.files || !e.dataTransfer.files[0]) return;
        const file = e.dataTransfer.files[0];
        setFile(file);
        onChange && onChange(file);
    };
    const handleChange = (e) => {
        const inputFiles = e.target.files;
        if (!inputFiles || !inputFiles[0]) {
            setFile(null);
            return;
        }
        const file = inputFiles[0];
        setFile(file);
        onChange && onChange(file);
    };

    return (
        <Label
            isDragActive={isDragActive}
            hasError={hasError}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <FileUpload />
            <HiddenInput
                name={name}
                type="file"
                id="new-episode-file-upload"
                onChange={handleChange}
                maxSize={maxSize}
                accept={accept.join(',')}
            />
            <Stack $align="center" $gap="0.5rem">
                <Text fontWeight="--fw-semibold" textAlign="center">
                    <FormattedMessage
                        defaultMessage="Glissez-déposez votre fichier audio, ou <label>Parcourir</label>"
                        values={{
                            label: (chunks) => (
                                <Text as="span" color="--primary" fontWeight="--fw-semibold">
                                    {chunks}
                                </Text>
                            ),
                        }}
                    />
                </Text>
                <Text color="--neutral500" variant="footnote" textAlign="center">
                    <FormattedMessage
                        defaultMessage=".MP3, .WAV, .M4A, .OGG ou .FLAC, jusqu’à {max}Mo"
                        values={{ max: MAX_AUDIO_FILE_SIZE / 1000000 }}
                    />
                </Text>
                {hasError && (
                    <Cluster $align="center" $gap="0.25rem">
                        <FontAwesomeIcon
                            icon={icon({ name: 'exclamation-circle', style: 'solid' })}
                            color={extractCssVariable('--alert500')}
                            style={{ fontSize: '0.75rem', cursor: 'pointer' }}
                        />
                        <Text color="--alert500">{errorMessage}</Text>
                    </Cluster>
                )}
            </Stack>
        </Label>
    );
};

DropFileUploader.propTypes = {
    name: PropTypes.string,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    accept: PropTypes.arrayOf(PropTypes.string),
    maxSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
};

DropFileUploader.defaultProps = {
    name: PropTypes.string,
    hasError: false,
    errorMessage: '',
    accept: [],
    maxSize: MAX_AUDIO_FILE_SIZE, // 500 Mo,
    onChange: () => {},
};

export default DropFileUploader;
