import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@ui/atoms/UiKitTooltip/UiKitTooltip';
import Text from '@/components/ui/atoms/Text';

const EpisodeEditTooltip = ({ children }) => {
    return (
        <UiKitTooltip
            variant="dark"
            content={<Text fontWeight="--fw-semibold">{children}</Text>}
            position="top"
        >
            <TooltipInfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
        </UiKitTooltip>
    );
};

const TooltipInfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;

EpisodeEditTooltip.propTypes = {
    children: PropTypes.node.isRequired,
};

export default EpisodeEditTooltip;
