import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { connect } from '../decorators/connect';

const enhance = connect(({ routerStore, callToActionStore }) => {
    const show = routerStore.resolve('showId');
    return {
        removeCallToActionLocations: (ctaId, type) =>
            callToActionStore.removeCallToActionLocations(show.id, ctaId, type),
    };
});

const CTADeleteLocationModal = ({ isOpened, onClose, removeCallToActionLocations, state }) => (
    <DeprecatedModal
        isOpened={isOpened}
        onClose={onClose}
        maxW={400}
        title={<FormattedMessage defaultMessage="Souhaitez-vous supprimer ce bouton ?" />}
    >
        <DeprecatedText>
            <FormattedMessage
                defaultMessage="Il ne sera plus visible sur votre {type}."
                values={{ type: state.locationSelected.label }}
            />
        </DeprecatedText>
        <DeprecatedPaper flex mt={40} justify="flex-end" gap={8}>
            <DeprecatedButton accent="secondary" onClick={onClose}>
                <FormattedMessage defaultMessage="Annuler" />
            </DeprecatedButton>
            <DeprecatedButton
                onClick={() => {
                    removeCallToActionLocations(state.item.id, state.locationSelected.value);
                    onClose();
                }}
            >
                <FormattedMessage defaultMessage="Supprimer" />
            </DeprecatedButton>
        </DeprecatedPaper>
    </DeprecatedModal>
);

export default enhance(CTADeleteLocationModal);
