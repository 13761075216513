import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PRICING, PRICING_NAME } from '@/utils/pricing';
import Link from '../Link';
import useInternalRouteMapping from '@/utils/hooks/useInternalRouteMapping.hook';

const PricingLabel = ({ pricing }) => {
    const { link } = useInternalRouteMapping();
    const offersLink = link('user.offers');

    return (
        <LabelLink to={offersLink} target="_blank" pricing={pricing}>
            <DiamondIcon
                icon={icon({
                    name: 'gem',
                    style: 'duotone',
                })}
            />
            {PRICING_NAME[pricing]}
        </LabelLink>
    );
};

const pricingStyleMatch = {
    [PRICING.SUPERSONIC]: css`
        background: var(--gradient-primary);
        color: var(--white);

        &::after {
            background-image: linear-gradient(
                -45deg,
                transparent 25%,
                rgba(255, 255, 255, 0.25),
                transparent 75%
            );
        }
    `,
    [PRICING.BOOST]: css`
        background: var(--gradient-dusk);
        color: var(--primary);

        &::after {
            background-image: linear-gradient(
                -45deg,
                transparent 25%,
                rgba(255, 255, 255, 0.5),
                transparent 75%
            );
        }
    `,
    [PRICING.LAUNCH]: css`
        background: var(--gradient-dawn);
        color: var(--primary);

        &::after {
            background-image: linear-gradient(
                -45deg,
                transparent 25%,
                rgba(255, 255, 255, 0.5),
                transparent 75%
            );
        }
    `,
};

const LabelLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
    padding: 0.375rem;
    border-radius: var(--r-xs);
    width: fit-content;
    overflow: hidden;
    position: relative;
    transition: 0.25s;
    text-decoration: none;
    font-weight: var(--fw-bold);
    font-size: 0.625rem;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-repeat: no-repeat;
        transform: translateX(-100%);
        transition: 0s;
    }

    &:hover::after {
        transform: translateX(100%);
        transition: 1s ease;
    }

    ${({ pricing }) => pricingStyleMatch[pricing]}
`;
const DiamondIcon = styled(FontAwesomeIcon)`
    color: inherit;
    font-size: 0.5rem;
`;

PricingLabel.propTypes = {
    pricing: PropTypes.oneOf(Object.values(PRICING)).isRequired,
};

export default PricingLabel;
