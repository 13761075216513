import styled, { keyframes } from 'styled-components';
import bot from '@public/images/transcription-bot.png';
import fakeWaveform from '@public/images/transcription-waveform.svg';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import Alert from '@ui/atoms/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import { EPISODE_STATUS } from '@/utils/constants';

const EpisodeEditTranscriptionLoading = () => {
    const episode = useEpisodeBeingEdited();
    const isPublished = episode.state === EPISODE_STATUS.ONLINE;

    return (
        <Stack $gap="1.5rem">
            <Stack $gap="0.75rem">
                <LoadingWrapper>
                    <BotWrapper>
                        <BotImg src={bot} />
                        <BotEyes>
                            <BotEye />
                            <BotEye />
                        </BotEyes>
                    </BotWrapper>
                    <Scanner />
                </LoadingWrapper>
                <Stack $gap="0.25rem">
                    <Text variant="bodyM" fontWeight="--fw-semibold" textAlign="center">
                        <FormattedMessage defaultMessage="Génération de la transcription..." />
                    </Text>
                    <Text variant="bodyM" color="--neutral500" textAlign="center">
                        <FormattedMessage defaultMessage="...vous pouvez continuer l’édition de votre épisode ou quitter et revenir plus tard. ☕️" />
                    </Text>
                </Stack>
            </Stack>
            {isPublished && (
                <Alert
                    variant="info"
                    icon={<AlertIcon icon={icon({ name: 'circle-info', style: 'solid' })} />}
                >
                    <Text variant="bodyM" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Une fois générée, la transcription sera automatiquement publiée." />
                    </Text>
                </Alert>
            )}
        </Stack>
    );
};

const eyeAnimation = keyframes`
    0% {
    transform: translateX(0px);
  }

  10% {
    transform: translateX(-8px);
  }

  20% {
    transform: translateX(-8px);
  }

  30% {
    transform: translateX(4px);
  }

  40% {
    transform: translateX(4px);
  }

  50% {
    transform: translateX(0px);
  }

  60% {
    transform: translateX(0px) scaleY(1);
  }

  65% {
    transform: translateX(0px) scaleY(0.07);
  }

  70% {
    transform: translateX(0px) scaleY(1);
  }
`;
const scannerAnimation = keyframes`
    0% {
        left: -160px;
    }
    100% {
        left:200%;
    }
`;
const LoadingWrapper = styled.div`
    height: 5rem;
    border-radius: var(--r-m);
    background: var(--gradient-dusk);
    position: relative;
    overflow: hidden;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 19.25rem;
        height: 3rem;
        background: url(${fakeWaveform}) no-repeat center;
        background-size: contain;
    }
`;
const BotWrapper = styled.div`
    width: 7rem;
    height: 4rem;
    z-index: 2;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
`;
const BotImg = styled.img`
    width: 100%;
    height: 100%;
`;
const Scanner = styled.div`
    position: absolute;
    width: 10rem;
    height: 100%;
    border-right: solid 2px var(--white);
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0) 100%);
    animation: ${scannerAnimation} 2s linear infinite;
    z-index: 1;
`;
const BotEye = styled.span`
    width: 0.3125rem;
    height: 100%;
    transform: rotate(-2deg);
    border-radius: var(--r-full);
    background-color: var(--neutral-white);
    box-shadow: 0px 0px 2px 0px var(--primary), 0px 0px 4px 0px var(--primary),
        0px 0px 12px 0px var(--primary), 0px 0px 24px 0px var(--primary),
        0px 0px 48px 0px var(--primary), 0px 0px 80px 0px var(--primary);
    animation: ${eyeAnimation} cubic-bezier(0.18, 0.89, 0.32, 1.28) 5s infinite; ;
`;
const BotEyes = styled.div`
    position: absolute;
    top: 1.74rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    column-gap: 0.4rem;
    height: 1rem;
`;
const AlertIcon = styled(FontAwesomeIcon)`
    color: var(--info500);
    width: 1rem;
    height: 1rem;
`;

export default EpisodeEditTranscriptionLoading;
