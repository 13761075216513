import { useState } from 'react';
import AccordionItem from '@ui/atoms/AccordionItem';
import EpisodeEditAccordionItem from '@app/molecules/EpisodeEditAccordionItem';
import { FormattedMessage } from 'react-intl';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import Accordion from '@ui/atoms/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EpisodeEditFormContent from '@app/organisms/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContent';
import EpisodeEditFormOptions from '@app/organisms/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptions';
import EpisodeEditFormPrivacy from '@app/organisms/EpisodeEditForm/EpisodeEditFormPrivacy/EpisodeEditFormPrivacy';
import EpisodeEditFormChapters from '@app/organisms/EpisodeEditForm/EpisodeEditFormChapters/EpisodeEditFormChapters';
import { useFormContext } from 'react-hook-form';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import { EPISODE_PRIVACY, EPISODE_STATUS } from '@/utils/constants';
import { useEpisodeEditAudioFileMutation } from '@/context/EpisodeEditContext';
import Spinner from '@ui/atoms/Spinner';
import Text from '@ui/atoms/Text';
import EpisodeEditFormNewsletter from '@app/organisms/EpisodeEditForm/EpisodeEditFormNewsletter/EpisodeEditFormNewsletter';
import EpisodeDeeplinkForm from '@app/molecules/EpisodeDeeplinkForm';
import { EpisodeEditFormNewsletterErrorsCountContext } from '@app/organisms/EpisodeEditForm/contexts/EpisodeEditFormNewsletterErrorsCountContext';

const AccordionItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

const EpisodeAccordion = styled(Accordion)`
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        padding-bottom: 2rem;
    }
`;

const NoAudioFileAlert = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    column-gap: 0.25rem;
    background-color: var(--info50);
    border-radius: var(--r-s);
`;

const NoAudioFileAlertIcon = styled(FontAwesomeIcon)`
    color: var(--info500);
    width: 0.75rem;
    height: 0.75rem;
`;

const EpisodeEditFormAccordion = () => {
    const episode = useEpisodeBeingEdited();
    const {
        formState: { errors },
    } = useFormContext();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();

    const hasAudioFile = episode.files.length > 0;
    const isUploadingAudioFile = episodeEditAudioFileMutation.isLoading;

    const isNewsletterEnabled = episode.state !== EPISODE_STATUS.ONLINE;
    const isDeeplinkEnabled =
        episode.state === EPISODE_STATUS.ONLINE &&
        [EPISODE_PRIVACY.PUBLIC, EPISODE_PRIVACY.UNLISTED].includes(episode.privacy);
    const isChaptersEnabled = !isUploadingAudioFile && hasAudioFile;

    const [newsletterErrorsCount, setNewsletterErrorsCount] = useState(0);

    return (
        <EpisodeAccordion defaultIndex={0}>
            <AccordionItem>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Contenu" />}
                    icon={
                        <AccordionItemIcon
                            icon={icon({ name: 'album-collection', style: 'solid' })}
                        />
                    }
                    errorsCount={errors.content ? Object.keys(errors.content).length : 0}
                    localStorageKey="content"
                >
                    <EpisodeEditFormContent />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            <AccordionItem>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Options" />}
                    icon={<AccordionItemIcon icon={icon({ name: 'sliders-up', style: 'solid' })} />}
                    errorsCount={errors.options ? Object.keys(errors.options).length : 0}
                    localStorageKey="options"
                >
                    <EpisodeEditFormOptions />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            <AccordionItem>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Confidentialité" />}
                    icon={
                        <AccordionItemIcon icon={icon({ name: 'lock-keyhole', style: 'solid' })} />
                    }
                    errorsCount={errors.privacy ? Object.keys(errors.privacy).length : 0}
                    localStorageKey="privacy"
                >
                    <EpisodeEditFormPrivacy />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            <AccordionItem isDisabled={!isChaptersEnabled}>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Chapitres" />}
                    icon={<AccordionItemIcon icon={icon({ name: 'bookmark', style: 'solid' })} />}
                    isOptional
                    errorsCount={errors.chapters ? Object.keys(errors.chapters).length : 0}
                    localStorageKey="chapters"
                    customContent={
                        isUploadingAudioFile ? (
                            <Spinner size={24} />
                        ) : !hasAudioFile ? (
                            <NoAudioFileAlert>
                                <NoAudioFileAlertIcon
                                    icon={icon({ name: 'circle-info', style: 'solid' })}
                                />
                                <Text color="--info500" fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Un fichier audio est requis" />
                                </Text>
                            </NoAudioFileAlert>
                        ) : null
                    }
                >
                    <EpisodeEditFormChapters />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            {isNewsletterEnabled && (
                <AccordionItem>
                    <EpisodeEditAccordionItem
                        label={<FormattedMessage defaultMessage="Newsletter" />}
                        icon={
                            <AccordionItemIcon
                                icon={icon({ name: 'envelope-open-text', style: 'solid' })}
                            />
                        }
                        isOptional
                        errorsCount={newsletterErrorsCount}
                        localStorageKey="newsletter"
                    >
                        <EpisodeEditFormNewsletterErrorsCountContext.Provider
                            value={{ setNewsletterErrorsCount }}
                        >
                            <EpisodeEditFormNewsletter />
                        </EpisodeEditFormNewsletterErrorsCountContext.Provider>
                    </EpisodeEditAccordionItem>
                </AccordionItem>
            )}
            {isDeeplinkEnabled && (
                <AccordionItem>
                    <EpisodeEditAccordionItem
                        label="Deeplink"
                        icon={<AccordionItemIcon icon={icon({ name: 'link', style: 'solid' })} />}
                        isOptional
                        // TODO Handle errorsCount
                        localStorageKey="deeplink"
                    >
                        <EpisodeDeeplinkForm />
                    </EpisodeEditAccordionItem>
                </AccordionItem>
            )}
        </EpisodeAccordion>
    );
};

export default EpisodeEditFormAccordion;
