import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateEpisodeTranscript } from '@/api';
import episodeTranscriptKeys from './episodeTranscriptKeys';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ episodeId, transcript }) => {
    const { data } = await updateEpisodeTranscript(episodeId, transcript);

    return transform(data);
};

export default function useUpdateEpisodeTranscriptMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (data, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: episodeTranscriptKeys.detailByEpisodeId(episodeId),
            });
        },
    });
}
