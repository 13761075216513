import Model from './Model';
import { apiCall } from '../components/app/decorators/api';

class InvitationModel extends Model {
    static attributes = {
        id: 'id',
        email: 'email',
        avatar: 'avatar',
    };

    @apiCall
    delete() {
        return this.store.deleteInvitation(this.id);
    }
}

export default InvitationModel;
