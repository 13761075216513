import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useResponsive } from '@/utils/hooks/useResponsive';
import Flex from '../../../ui/layout/Flex';
import Stack from '../../../ui/layout/Stack';
import Cluster from '../../../ui/layout/Cluster';
import getapp from '@public/images/getapp.png';
import capterra from '@public/images/capterra.png';
import trustpilot from '@public/images/trustpilot-excellent.png';
import CustomerReviews from '../../../ui/molecules/CustomerReviews';
import useUserStore from '../../../../utils/hooks/useUserStore';
import { observer } from 'mobx-react';
import Text from '@ui/atoms/Text';

const PricingReviews = () => {
    const { user } = useUserStore();
    const { isDesktop } = useResponsive();
    return (
        <Container>
            <Flex
                $gap="2rem"
                $flexDirection={isDesktop ? 'row' : 'column'}
                $align="center"
                $justify="space-between"
            >
                <Stack $gap="1.5rem" $align={isDesktop ? 'flex-start' : 'center'}>
                    <Text
                        variant={isDesktop ? 'title' : 'heading'}
                        fontWeight="--fw-bold"
                        align={isDesktop ? 'start' : 'center'}
                        css="max-width: 20ch; line-height: 1.5em;"
                    >
                        <FormattedMessage defaultMessage="La vérité sort de la bouche de nos podcasteur•ses" />
                    </Text>
                    <Cluster
                        $gap="2rem"
                        $rowGap="1.5rem"
                        $justify={isDesktop ? 'flex-start' : 'center'}
                    >
                        {user.locale === 'fr' && (
                            <ProReviewImage src={trustpilot} alt="TrustPilot" />
                        )}
                        <ProReviewImage src={capterra} alt="Capterra" />
                        <ProReviewImage src={getapp} alt="Getapp" />
                    </Cluster>
                </Stack>
                <CustomerReviews
                    css={isDesktop ? 'margin-bottom: -1.5rem; max-width: min(27.5rem, 50%);' : null}
                />
            </Flex>
        </Container>
    );
};

const Container = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1.5rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        padding: 3rem 4rem;
    }
`;
const ProReviewImage = styled.img`
    height: 4rem;
`;

export default observer(PricingReviews);
