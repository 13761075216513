import * as yup from 'yup';
import { getPlainText } from '@/helpers';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const newsletterMaxCharacters = 4000;

const getNewsletterSchema = (intl) => {
    return yup.object({
        activated: yup.boolean(),
        title: yup.string().required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        messageTpl: yup
            .string()
            .test({
                name: 'max-characters',
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                    max: newsletterMaxCharacters,
                }),
                skipAbsent: true,
                test: (value) => getPlainText(value).length <= newsletterMaxCharacters,
            })
            .notRequired(),
    });
};

export default getNewsletterSchema;
