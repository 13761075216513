import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import ResignSubscriptionForm from './ResignSubscriptionForm';

const ResignSubscriptionModal = ({ onClose, isOpen }) => (
    <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={<FormattedMessage defaultMessage="Résilier mon abonnement" />}
    >
        <ResignSubscriptionForm onAfterSubmit={onClose} />
    </Modal>
);

export default ResignSubscriptionModal;
