const chapterKeys = {
    all: () => [{ entity: 'chapter' }],
    allLists: () => [{ ...chapterKeys.all()[0], scope: 'list' }],
    listByEpisodeId: (rawEpisodeId) => [
        { ...chapterKeys.allLists()[0], episodeId: Number(rawEpisodeId) },
    ],
    allDetails: () => [{ ...chapterKeys.all()[0], scope: 'detail' }],
    detailById: (rawChapterId) => [
        { ...chapterKeys.allDetails()[0], chapterId: Number(rawChapterId) },
    ],
};

export default chapterKeys;
