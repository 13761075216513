import { useQuery } from '@tanstack/react-query';
import { fetchChapters } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import chapterKeys from '@/queries/chapter/chapterKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const queryFn = async ({ queryKey: [{ episodeId }] }) => {
    const { data, meta } = await fetchChapters(episodeId);

    return transform(data, meta);
};

const useChaptersQuery = (episodeId, config) => {
    return useQuery({
        queryKey: chapterKeys.listByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
        ...config,
    });
};

export default useChaptersQuery;
