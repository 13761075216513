import './styles/global.css';
import './styles/reset.css';
import './styles/third-party-overwrites.css';
import '@public/favicon.png';
import '@public/apple-touch-icon.png';
import 'core-js/stable';
// Custom polyfills not yet available in `core-js/stable`:
import 'core-js/features/array/to-reversed';
import 'core-js/features/array/to-spliced';
import 'core-js/features/array/to-sorted';
import 'react-dates/initialize';
import * as Sentry from '@sentry/react';
import { render } from 'react-dom';
import { timeFormatDefaultLocale } from 'd3-time-format';
import WebFontConfig from 'webfontloader';
import Root from './components/Root';
import * as amplitude from '@amplitude/analytics-browser';

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT ?? 'production',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    ignoreErrors: [
        /^Request failed with status code/, // API requests with status code 4XX or 5XX
        /fbq/, // Facebook related errors
        /^Network Error/, // Generic error returned by Axios
    ],
    // Performance Monitoring
    tracesSampleRate: 0.01,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    // Session Replay
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 0.01,
    // Disable for development environment
    enabled: process.env.NODE_ENV !== 'development',
});

// Add deploy preview ID context if possible
// https://docs.netlify.com/configure-builds/environment-variables/#git-metadata
if (process.env.PULL_REQUEST === 'true') {
    Sentry.setTag('deploy_preview_id', process.env.REVIEW_ID);
}

// --- Amplitude ---
amplitude.init(process.env.AMPLITUDE_API_KEY, null, {
    minIdLength: 1,
    // Uncomment following line to enable Amplitude debug mode
    // logLevel: amplitude.Types.LogLevel.Debug,
    defaultTracking: {
        pageViews: false,
        sessions: false,
        formInteractions: false,
        fileDownloads: false,
    },
});

timeFormatDefaultLocale({
    dateTime: '%a %b %e %X %Y',
    date: '%d/%m/%Y',
    time: '%H : %M : %S',
    periods: ['AM', 'PM'],
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    shortDays: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    months: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ],
    shortMonths: [
        'Jan.',
        'Févr.',
        'Mar',
        'Avr',
        'Mai',
        'Jui',
        'Juil',
        'Aou',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ],
});

WebFontConfig.load({
    custom: {
        families: ['ProximaNova', 'n4, n6, n7'],
    },
});

render(<Root />, document.getElementById('root'));
