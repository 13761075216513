import PropTypes from 'prop-types';
import Modal from '@/components/ui/molecules/Modal';
import { FormattedMessage } from 'react-intl';
import EpisodePublicationDateForm from '../EpisodePublicationDateForm';

const EpisodeEditPublicationDateModal = ({
    isOpen,
    onClose,
    isOnline,
    handleEditPublicationDate,
    isLoading,
    publishedAt,
}) => {
    const date = publishedAt || new Date();

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={
                isOnline ? (
                    <FormattedMessage defaultMessage="Modifier la date et l'heure de publication de l'épisode" />
                ) : (
                    <FormattedMessage defaultMessage="Planifier la publication de l’épisode" />
                )
            }
            description={
                <FormattedMessage defaultMessage="L’épisode sera publié automatiquement à la date et à l’heure choisies." />
            }
            size="small"
            disableScroll
        >
            <EpisodePublicationDateForm
                handleEditPublicationDate={handleEditPublicationDate}
                isLoading={isLoading}
                isOnline={isOnline}
                publishedAt={date}
                onCloseModal={onClose}
            />
        </Modal>
    );
};

EpisodeEditPublicationDateModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isOnline: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    publishedAt: PropTypes.string,
    handleEditPublicationDate: PropTypes.func,
};

EpisodeEditPublicationDateModal.defaultProps = {
    isOpen: false,
    isOnline: false,
    isLoading: false,
    handleEditPublicationDate: () => {},
    onClose: () => {},
};

export default EpisodeEditPublicationDateModal;
