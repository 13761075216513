import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'mobx-react';
import stores from '@/stores/RootStore';
import App from '@/components/App';
import { mediaQueries } from '@/styles/theme';
import IntlProvider from './app/atoms/IntlProvider';
import RefreshTokenBoundary from './app/errorBoundaries/RefreshTokenBoundary';
import Intercom from './app/atoms/Intercom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CurrentShowContext } from '@/context/CurrentShowContext';
import GlobalToastRegions from '@ui/molecules/GlobalToastRegions';

function getUserConfirmation(dialogKey, callback) {
    // use "message" as Symbol-based key
    const dialogTrigger = window[Symbol.for(dialogKey)];
    if (dialogTrigger) {
        // pass the callback to delegate
        // to the invoked dialog
        return dialogTrigger(callback);
    }
    // Fallback to allowing navigation
    return callback(true);
}

const stripePromise = loadStripe(process.env.STRIPE_KEY);

function Root() {
    return (
        <Elements stripe={stripePromise}>
            <Provider stores={stores}>
                <BrowserRouter getUserConfirmation={getUserConfirmation}>
                    <IntlProvider>
                        <ThemeProvider theme={mediaQueries}>
                            <CurrentShowContext>
                                <RefreshTokenBoundary>
                                    <App />
                                    <GlobalToastRegions />
                                    <Intercom />
                                </RefreshTokenBoundary>
                            </CurrentShowContext>
                        </ThemeProvider>
                    </IntlProvider>
                </BrowserRouter>
            </Provider>
        </Elements>
    );
}

export default Root;
