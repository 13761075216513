import { useQuery } from '@tanstack/react-query';
import { getEpisodeSlug } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeSlugKeys from '@/queries/episodeSlug/episodeSlugKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });

    return transformedData;
};

const queryFn = async ({ queryKey: [{ episodeId, name }] }) => {
    const { data } = await getEpisodeSlug(episodeId, name);

    return transform(data);
};

const useEpisodeSlugQuery = ({ episodeId, name }, config = {}) => {
    return useQuery({
        queryKey: episodeSlugKeys.detailByEpisodeId(episodeId, name),
        queryFn,
        ...config,
    });
};

export default useEpisodeSlugQuery;
