import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateChapters } from '@/api';
import { decamelizeKeys } from 'humps';
import chapterKeys from './chapterKeys';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ episodeId, chapters }) => {
    const { data } = await updateChapters(episodeId, decamelizeKeys(chapters));

    return transform(data);
};
const useUpdateChaptersMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (data, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: chapterKeys.listByEpisodeId(episodeId),
                exact: true,
            });
        },
    });
};

export default useUpdateChaptersMutation;
