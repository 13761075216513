import { computed, runInAction } from 'mobx';
import NewsletterModel from '../models/NewsletterModel';
import {
    fetchNewsletterSubscribers,
    fetchNewsletterExportSubscribersCsv,
    deleteNewsletterSubscribers,
    fetchNewsletterPreview,
} from '@/api';
import Store from './Store';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import { download } from '../helpers';
import PaginationModel from '../models/PaginationModel';

class NewsletterStore extends Store {
    static observables = {
        subscribers: [],
        preview: '',
        pagination: {},
        quota: {},
        pricesQuota: {},
        newQuota: 0,
    };

    @apiFetch
    async fetchNewsletterExportSubscribersCsv(showId) {
        const { axios, ...data } = await fetchNewsletterExportSubscribersCsv(showId);
        const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];
        download(Object.values(data).join(''), filename, 'txt');
    }

    @apiFetch
    async fetchNewsletterSubscribers(showId, page) {
        const { data, meta } = await fetchNewsletterSubscribers(showId, page);
        runInAction(() => {
            this.subscribers = data.map((subscriber) => new NewsletterModel(this, subscriber));
            this.pagination = new PaginationModel(this, meta.pagination);
        });
    }

    @apiFetch
    async fetchNewsletterPreview(podId) {
        try {
            const { axios } = await fetchNewsletterPreview(podId);
            runInAction(() => {
                this.preview = axios.request.responseText;
            });
        } catch (error) {
            console.error(error);
        }
    }

    @apiCall
    async deleteNewsletterSubscribers(show, subscriber) {
        await deleteNewsletterSubscribers(show.id, { email: subscriber });
        runInAction(() => {
            this.subscribers.remove(
                this.subscribers.find((element) => element.email === subscriber),
            );
        });
    }

    @computed
    get getNumberSubscribers() {
        return this.pagination.total;
    }
}

export default NewsletterStore;
