import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import PaperGrid from '../../ui/atoms/PaperGrid';
import Column from '../../ui/atoms/Column';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import { ReactComponent as editIcon } from '@public/icons/edit.svg';
import { ReactComponent as deleteIcon } from '@public/icons/trash.svg';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { ACTIONS_CTA, BASICS_CHECKBOX, ICON_LIST_CTA } from './CTAAccessAllowed';

const CTAButtonsController = ({ ctaList, dispatch }) => (
    <DeprecatedPaper flex column gap={16} mb={16}>
        {ctaList.map(
            (item) =>
                item.url && (
                    <DeprecatedPaper background="--neutral50" rounded p={12} gap={16} key={item.id}>
                        <PaperGrid customColumn="20px 1fr 68px" mb={12}>
                            <Column gridColumn="1/2" align="flex-start">
                                {ICON_LIST_CTA[item.type]}
                            </Column>
                            <Column
                                gridColumn="2/3"
                                flex
                                column
                                align="flex-start"
                                style={{ overflow: 'hidden' }}
                            >
                                <DeprecatedText
                                    ml={8}
                                    weight="semibold"
                                    nowrap
                                    ellipsis
                                    maxW="100%"
                                    pr={20}
                                >
                                    {item.text}
                                </DeprecatedText>
                                <DeprecatedText
                                    ml={8}
                                    weight="semibold"
                                    color="--neutral500"
                                    size="small"
                                    ellipsis
                                    nowrap
                                    maxW="100%"
                                    pr={20}
                                >
                                    <FormattedMessage
                                        defaultMessage="{url}"
                                        values={{ url: item.url }}
                                    />
                                </DeprecatedText>
                            </Column>
                            <Column gridColumn="3/4" gap={4}>
                                <DeprecatedButton
                                    icon
                                    accent="roundedSmall"
                                    onClick={() =>
                                        dispatch({
                                            type: ACTIONS_CTA.OPEN_EDIT_MODAL_CTA,
                                            payload: { edit: true, item: { ...item } },
                                        })
                                    }
                                >
                                    <DeprecatedIcon as={editIcon} />
                                </DeprecatedButton>
                                <DeprecatedButton
                                    icon
                                    accent="roundedSmall"
                                    onClick={() =>
                                        dispatch({
                                            type: ACTIONS_CTA.DELETE_CTA,
                                            payload: { item: { ...item } },
                                        })
                                    }
                                >
                                    <DeprecatedIcon as={deleteIcon} />
                                </DeprecatedButton>
                            </Column>
                        </PaperGrid>
                        <DeprecatedPaper>
                            {item.locations.length > 0 && (
                                <DeprecatedText
                                    color="--neutral500"
                                    weight="semibold"
                                    size="small"
                                    mb={8}
                                >
                                    <FormattedMessage defaultMessage="Présent sur" />
                                </DeprecatedText>
                            )}
                            <DeprecatedPaper flex column gap={4}>
                                {item.locations.map((location) => {
                                    if (!location) return null;
                                    return (
                                        <DeprecatedPaper
                                            background="white"
                                            justify="space-between"
                                            h={36}
                                            flex
                                            align="center"
                                            px={8}
                                            rounded
                                            key={location}
                                        >
                                            <DeprecatedText>
                                                {BASICS_CHECKBOX[location].label}
                                            </DeprecatedText>
                                            <DeprecatedIcon
                                                onClick={() =>
                                                    dispatch({
                                                        type:
                                                            item.locations.length === 1
                                                                ? ACTIONS_CTA.DELETE_CTA
                                                                : ACTIONS_CTA.DELETE_LOCATION_CTA,
                                                        payload: {
                                                            item: { ...item },
                                                            locationSelected: {
                                                                ...BASICS_CHECKBOX[location],
                                                            },
                                                        },
                                                    })
                                                }
                                                as={deleteIcon}
                                                color="--neutral500"
                                                size={16}
                                            />
                                        </DeprecatedPaper>
                                    );
                                })}
                            </DeprecatedPaper>
                        </DeprecatedPaper>
                    </DeprecatedPaper>
                ),
        )}
    </DeprecatedPaper>
);

export default observer(CTAButtonsController);
