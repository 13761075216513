import { formatDates, makeArray } from '../../../../helpers';
import SelectNative from '../SelectNative';

const populateYearsSelect = (year, yearsAgo = 20) => {
    // Here we are filling the selected year based on the actual current year, not the PickerSelect date
    const date = new Date();
    const currentYear = date.getFullYear();
    // Start by creating a year slice. currentYear - yearsAgo, years e.g: 2020 - 20 === 2000
    // Finally we add yearsAgo, ['2000', ..., '2040']
    const yearsOption = makeArray(yearsAgo * 2, currentYear - yearsAgo).map((y) => y.toString());
    return yearsOption.map((y) => (
        <option key={y} selected={y === year}>
            {y}
        </option>
    ));
};

const YearSelect = ({ onChange, source }) => {
    const [year, month, day] = source;
    return (
        <SelectNative
            label={formatDates('range', 'years')}
            name="year"
            source={populateYearsSelect(year)}
            onChange={(e) => onChange([e.target.value, month, day])}
        />
    );
};

export default YearSelect;
