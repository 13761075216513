import styled from 'styled-components';

const PricingOfferGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    column-gap: 1rem;
    row-gap: 4rem;

    > * {
        flex-grow: 1;
        flex-basis: calc((46rem - 100%) * 999);
    }
`;

export default PricingOfferGroup;
