import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { connect } from '../decorators/connect';
import Spinner from '../../ui/atoms/Spinner';

const enhance = connect(({ routerStore, callToActionStore }) => {
    const show = routerStore.resolve('showId');
    return {
        deleteCallToActionButton: (showId, type) =>
            callToActionStore.deleteCallToActionButton(showId, type),
        isLoading: callToActionStore.deleteCallToActionButton.pending,
        showId: show?.id,
    };
});

const CTADeleteButtonModal = ({
    isOpened,
    onClose,
    deleteCallToActionButton,
    showId,
    state,
    isLoading,
}) => (
    <DeprecatedModal
        isOpened={isOpened}
        onClose={onClose}
        maxW={400}
        title={<FormattedMessage defaultMessage="Souhaitez-vous supprimer ce bouton ?" />}
    >
        <DeprecatedText>
            <FormattedMessage defaultMessage="Il ne sera plus visible sur vos outils de communication." />
        </DeprecatedText>
        <DeprecatedPaper flex mt={40} justify="flex-end" gap={8}>
            <DeprecatedButton accent="secondary" onClick={onClose}>
                <FormattedMessage defaultMessage="Annuler" />
            </DeprecatedButton>
            <DeprecatedButton
                onClick={() => {
                    deleteCallToActionButton(showId, state.item.id);
                    onClose();
                }}
                disabled={isLoading}
            >
                {isLoading && <Spinner />}
                <FormattedMessage defaultMessage="Supprimer" />
            </DeprecatedButton>
        </DeprecatedPaper>
    </DeprecatedModal>
);

export default enhance(CTADeleteButtonModal);
