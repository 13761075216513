import { createApi } from '../callApi';
import { uploadImage } from '../uploadImage';
const contentApi = createApi('CONTENT');

export const fetchPodcast = (podcast) => {
    return contentApi({ method: 'GET', target: `/v1/podcasts/${podcast}` });
};
export const fetchPodcasts = (show, q, seasons, status, page, perPage = 20) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${show}/podcasts`,
        query: { q, page, per_page: perPage, status, seasons },
    });
};
export const fetchAllShrinkedEpisodes = (
    show,
    searchQuery,
    page,
    perPage,
    withClip = null,
    episodeStatus = null,
) => {
    let query = { q: searchQuery, page, per_page: perPage, state: episodeStatus };

    if (withClip !== null) {
        query = { ...query, clip: withClip ? '1' : '0' };
    }

    return contentApi({ method: 'GET', target: `/v1/shows/${show}/podcasts/minimal`, query });
};
export const fetchImportedPodcasts = (id, query) => {
    return contentApi({
        method: 'GET',
        target: `/v1/rss/imports/${id}/episodes`,
        query,
    });
};
export const addPodcast = (show, body) => {
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/podcasts`, body });
};
export const duplicate = (podcast) => {
    return contentApi({ method: 'POST', target: `/v1/podcasts/${podcast}/duplicate` });
};
export const getEpisodeSlug = (episodeId, name) => {
    return contentApi({ method: 'GET', target: `/v1/podcasts/${episodeId}/slugify?name=${name}` });
};
export const uploadEpisodeAudioFile = (podcast, fileModel) => {
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${podcast}/file`,
        body: fileModel.rawFileData,
        onUploadProgress: fileModel.onProgress,
        cancelToken: fileModel.cancelToken,
    });
};
export const experimentalUploadEpisodeAudioFile = (podcast, audioFile) => {
    const body = new FormData();
    body.append('file', audioFile);
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${podcast}/file`,
        body,
    });
};
export function uploadPodcastImage(podcast, image) {
    return uploadImage(`/v1/podcasts/${podcast}/image`, image);
}
export const experimentalUploadEpisodeImage = (episodeId, image) => {
    const body = new FormData();
    body.append('file', image);
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${episodeId}/image`,
        body,
    });
};
export const deletePodcastListeningLink = (podcast, platform) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/podcasts/${podcast}/listening_links/${platform}`,
    });
};
export const deletePodcast = (podcast) => {
    return contentApi({ method: 'DELETE', target: `/v1/podcasts/${podcast}` });
};
export const deletePodcastImage = (podcast) => {
    return contentApi({ method: 'DELETE', target: `/v1/podcasts/${podcast}/image` });
};
export const deleteAllPodcasts = (show) => {
    return contentApi({ method: 'DELETE', target: `/v1/shows/${show}/podcasts` });
};
export const assignAllPodcasts = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}/podcasts/season`, body });
};
export const moveAllPodcasts = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}/podcasts`, body });
};
export const updatePodcast = (podcast, body) => {
    return contentApi({ method: 'PUT', target: `/v1/podcasts/${podcast}`, body });
};
export const updatePodcastListeningLink = (podcast, platform, body) => {
    return contentApi({
        method: 'PUT',
        target: `/v1/podcasts/${podcast}/listening_links/${platform}`,
        body,
    });
};
export const addPodcastListeningLink = (podcast, body) => {
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${podcast}/listening_links`,
        body,
    });
};
export const fetchPodcastListeningLinks = (podcast) => {
    return contentApi({ method: 'GET', target: `/v1/podcasts/${podcast}/listening_links` });
};
export const fetchListenablePodcasts = (show, query) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/podcasts/listenables`, query });
};
export const fetchEpisodeJobsProgress = (podcast) => {
    return contentApi({ method: 'GET', target: `/v1/podcasts/${podcast}/jobs` });
};
