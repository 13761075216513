import Text from '@/components/ui/atoms/Text';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Stack from '@/components/ui/layout/Stack';
import { InputNumberController } from '@/components/ui/atoms/InputNumber';
import Button from '@/components/ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@/components/ui/layout/Cluster';
import useTranscriptQuotaPriceQuery from '@/queries/quota/useTranscriptQuotaPriceQuery.hook';
import UiKitTooltip from '@/components/ui/atoms/UiKitTooltip';

const TranscriptionEditQuotaForm = ({ quota, onClose, onConfirm }) => {
    const { control, handleSubmit, watch, formState } = useForm({
        defaultValues: {
            quota: quota?.maximum,
        },
    });
    const { isDirty } = formState;
    const quotaValue = watch('quota');
    const { data: price } = useTranscriptQuotaPriceQuery(quotaValue);

    const onSubmit = ({ quota }) => {
        onConfirm(quota);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InnerWrapper>
                <Stack $gap="0.25rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Minutes additionnelles" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="{price} / mois"
                            values={{
                                price: price?.cost,
                            }}
                        />
                    </Text>
                </Stack>
                <InputNumberController
                    control={control}
                    step={quota?.step}
                    minValue={quota?.minimum}
                    name="quota"
                />
            </InnerWrapper>
            <FooterWrapper>
                <Divider />
                <Cluster $gap="0.25rem" $align="center">
                    <Text>
                        <FormattedMessage
                            defaultMessage="Votre nouvelle limite mensuelle de transcription sera de <quota>minutes.</quota>"
                            values={{
                                quota: (chunks) => (
                                    <Text as="span" fontWeight="--fw-semibold">
                                        {quotaValue} {chunks}
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                    <UiKitTooltip
                        position="top"
                        variant="dark"
                        content={
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="{additionalQuota, plural,
                                        one {# minute}
                                        other {# minutes}
                                    } de temps additionnel s’ajouteront aux {maxQuota} minutes incluses dans votre forfait."
                                    values={{
                                        maxQuota: quota?.maximum,
                                        additionalQuota: quotaValue - quota?.maximum,
                                    }}
                                />
                            </Text>
                        }
                    >
                        <HelpIcon
                            icon={icon({
                                name: 'circle-info',
                                style: 'solid',
                            })}
                        />
                    </UiKitTooltip>
                </Cluster>
                <ActionWrapper>
                    <Button isDisabled={!isDirty} variant="primary" type="submit">
                        <FormattedMessage defaultMessage="Modifier la limite" />
                    </Button>
                    <CancelButton onPress={onClose} variant="ghost">
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </ActionWrapper>
            </FooterWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;

        & > :first-child {
            order: 1;
        }
    }
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const Divider = styled.div`
    height: 0.0625rem;
    background-color: var(--neutral100);
`;
const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    align-items: stretch;
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;

TranscriptionEditQuotaForm.propTypes = {
    quota: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

TranscriptionEditQuotaForm.defaultProps = {
    onClose: () => {},
    onConfirm: () => {},
};

export default TranscriptionEditQuotaForm;
