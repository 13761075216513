import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import CTAButtonsController from './CTAButtonsController';
import CTAMenuDropDown from './CTAMenuDropDown';
import { connect } from '../decorators/connect';
import { ICON_LIST_CTA, LINKS_NAMES } from './CTAAccessAllowed';
import CTAsCreationStart from './CTAsCreationStart';
import styled from 'styled-components';
import useSmartlinkSettingsQuery from '@/queries/smartlinkSetting/useSmartlinkSettingsQuery.hook';

const MESSAGE_BASICS_CTA = defineMessages({
    'callToAction.newsletter': { defaultMessage: "S'inscrire à ma newsletter" },
    'callToAction.podcastPage': { defaultMessage: 'Site Web' },
    'callToAction.smartlink': { defaultMessage: 'Smartlink' },
    'callToAction.crowdfunding': { defaultMessage: 'Soutenez-moi !' },
});

const enhance = connect(
    ({ routerStore, pageSettingsStore, autoSharingStore, callToActionStore }, { intl }) => {
        const show = routerStore.resolve('showId');
        const settings = pageSettingsStore.settings;
        const newsletterTemplate = autoSharingStore.newsletterShowTemplate;

        const basicsCTAsList = [
            {
                url: (settings?.isPublished && `${show.siteUrl}`) || undefined,
                icon: ICON_LIST_CTA.PAGE_PODCAST,
                text: intl.formatMessage(MESSAGE_BASICS_CTA['callToAction.podcastPage']),
                type: LINKS_NAMES.PAGE_PODCAST,
            },
            {
                url: show?.socialLinks.monetization,
                icon: ICON_LIST_CTA.CROWDFUNDING,
                text: intl.formatMessage(MESSAGE_BASICS_CTA['callToAction.crowdfunding']),
                type: LINKS_NAMES.CROWDFUNDING,
            },
            {
                url: undefined, // Will be computed in the component, using react query
                icon: ICON_LIST_CTA.SMARTLINK,
                text: intl.formatMessage(MESSAGE_BASICS_CTA['callToAction.smartlink']),
                type: LINKS_NAMES.SMARTLINK,
            },
            {
                url: (newsletterTemplate?.activated && `${show.siteUrl}?s=1`) || undefined,
                icon: ICON_LIST_CTA.NEWSLETTER,
                text: intl.formatMessage(MESSAGE_BASICS_CTA['callToAction.newsletter']),
                type: LINKS_NAMES.NEWSLETTER,
            },
        ];

        return {
            show,
            basicsCTAsList,
            callToActionButtons: callToActionStore.callToActionButtons,
            getNoCallToActionsButtons: callToActionStore.getNoCallToActionsButtons,
        };
    },
);

const CTAControlPanel = ({
    show,
    basicsCTAsList,
    callToActionButtons,
    dispatch,
    getNoCallToActionsButtons,
}) => {
    const { data: smartlinkSettings } = useSmartlinkSettingsQuery(show?.id);

    // Update this param based on fetched smartlink settings
    basicsCTAsList.find((item) => item.type === LINKS_NAMES.SMARTLINK).url =
        smartlinkSettings?.isPublished
            ? `${process.env.URL_SMARTLINKS_AUSHA}${show?.slug}`
            : undefined;

    if (getNoCallToActionsButtons)
        return <CTAsCreationStart basicsCTAsList={basicsCTAsList} dispatch={dispatch} />;

    return (
        <OuterWrapper>
            <Wrapper>
                <DeprecatedText color="--neutral500" mb={24}>
                    <FormattedMessage defaultMessage="Créez, personnalisez et gérez vos propres boutons pour enrichir vos outils de communication." />
                </DeprecatedText>
                <CTAButtonsController ctaList={callToActionButtons} dispatch={dispatch} />
                <CTAMenuDropDown ctaList={basicsCTAsList} dispatch={dispatch} />
            </Wrapper>
        </OuterWrapper>
    );
};

const OuterWrapper = styled.div`
    width: 100%;
    max-width: 1200px;
    padding: 2rem;
    background: var(--white);
`;

const Wrapper = styled.div`
    width: 100%;
    max-width: 680px;
    margin-inline: auto;
`;

export default injectIntl(enhance(CTAControlPanel));
