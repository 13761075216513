import styled, { css, keyframes } from 'styled-components';

const ShiningDiamond = () => {
    return (
        <Diamond
            width="186"
            height="168"
            viewBox="0 0 186 168"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <BottomLeft
                d="M0 66.7924L92.7736 168L59.0377 66.7924H0Z"
                fill="url(#paint0_linear_3_35)"
            />
            <TopLeft
                d="M36.5472 19L0 66.7924H59.0377L92.7736 19H36.5472Z"
                fill="url(#paint1_linear_3_35)"
            />
            <TopRight
                d="M185.547 66.7924L149 19H92.7736L123.698 66.7924H185.547Z"
                fill="url(#paint2_linear_3_35)"
            />
            <BottomRight
                d="M92.7736 168L185.547 66.7924H123.698L92.7736 168Z"
                fill="url(#paint3_linear_3_35)"
            />
            <Center
                d="M92.7736 19L59.0377 66.7924L92.7736 168L123.698 66.7924L92.7736 19Z"
                fill="url(#paint4_linear_3_35)"
            />
            <Star1
                d="M46.5 0L53.0337 31.9663L85 38.5L53.0337 45.0337L46.5 77L39.9663 45.0337L8 38.5L39.9663 31.9663L46.5 0Z"
                fill="#EDEDED"
            />
            <Star2
                d="M121.5 96L125.488 115.512L145 119.5L125.488 123.488L121.5 143L117.512 123.488L98 119.5L117.512 115.512L121.5 96Z"
                fill="#EDEDED"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3_35"
                    x1="5.637e-07"
                    y1="54.1775"
                    x2="104.911"
                    y2="155.423"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#7643E9" />
                    <stop offset="1" stop-color="#BA49EF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_3_35"
                    x1="37.0296"
                    y1="-1.135e-06"
                    x2="63.2748"
                    y2="51.3045"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#684EFE" />
                    <stop offset="1" stop-color="#9264FF" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_3_35"
                    x1="92.7736"
                    y1="3.86538e-06"
                    x2="189.479"
                    y2="44.205"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#9B8AFF" />
                    <stop offset="1" stop-color="#9D71FF" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_3_35"
                    x1="185.148"
                    y1="54.1775"
                    x2="80.6347"
                    y2="155.476"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#9465FF" />
                    <stop offset="1" stop-color="#C858FC" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_3_35"
                    x1="92.7736"
                    y1="0"
                    x2="92.7736"
                    y2="168"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.28125" stop-color="#B7ABFF" />
                    <stop offset="0.317708" stop-color="#C2A7FF" />
                    <stop offset="0.369792" stop-color="#B795FF" />
                    <stop offset="1" stop-color="#D989FF" />
                </linearGradient>
            </defs>
        </Diamond>
    );
};

const Diamond = styled.svg`
    height: 2.5rem;
    width: 2.5rem;
    max-width: initial;
`;

const shine = keyframes`
    0% {
        filter: brightness(100%);
    }
    6% {
        filter: brightness(100%);
    }
    12% {
        filter: brightness(85%);
    }
    18% {
        filter: brightness(100%);
    }
    100% {
        filter: brightness(100%);
    }
`;

const starShining = keyframes`
    0% {
        opacity: 0;
    }
    4% {
        opacity: 0;
        transform: scale(1);
    }
    8% {
        opacity: 1;
        transform: scale(1.05);
    }
    12% {
        opacity: 0;
        transform: scale(1);
    }
    16% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
`;

const DiamondPartBase = css`
    animation: 6s ease-in-out 0s infinite normal ${shine};
`;
const TopLeft = styled.path`
    ${DiamondPartBase};
    animation-delay: 0ms;
`;
const BottomLeft = styled.path`
    ${DiamondPartBase};
    animation-delay: 300ms;
`;
const Center = styled.path`
    ${DiamondPartBase};
    animation-delay: 200ms;
`;
const TopRight = styled.path`
    ${DiamondPartBase};
    animation-delay: 100ms;
`;
const BottomRight = styled.path`
    ${DiamondPartBase};
    animation-delay: 400ms;
`;

const StarBase = css`
    opacity: 0;
    animation: ${starShining} 6s ease-in-out infinite;
    transform-origin: 50% 50%;
    filter: blur(1px);
`;
const Star1 = styled.path`
    ${StarBase};
    animation-delay: 800ms;
`;
const Star2 = styled.path`
    ${StarBase};
    animation-delay: 1300ms;
`;

export default ShiningDiamond;
