import { Component } from 'react';
import * as Sentry from '@sentry/react';

export default class RefreshTokenBoundary extends Component {
    componentDidCatch(error, errorInfo) {
        // Don't pass server errors
        if (error.response) {
            return;
        }

        if (process.env.NODE_ENV === 'development') {
            console.log(error);
        }

        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            Sentry.captureException(error);
        });
    }
    render() {
        return this.props.children;
    }
}
