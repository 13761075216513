const episodeKeys = {
    all: () => [{ entity: 'episode' }],
    allLists: () => [{ ...episodeKeys.all()[0], scope: 'list' }],
    listByShowId: (
        showId,
        {
            q,
            page,
            perPage,
            sort,
            transformer,
            seasonsIds,
            states,
            publishedDate,
            hasClip,
            isListenable,
        } = {},
    ) => [
        {
            ...episodeKeys.allLists()[0],
            showId,
            q,
            page,
            perPage,
            sort,
            transformer,
            seasonsIds,
            states,
            publishedDate,
            hasClip,
            isListenable,
        },
    ],
    listByShowIdInfinite: (showId, query, perPage) => [
        {
            ...episodeKeys.allLists()[0],
            showId,
            query,
            perPage,
        },
    ],
    listMinimalByShowId: (showId, { searchQuery, page, perPage, withClip, episodeStatus }) => [
        {
            ...episodeKeys.allLists()[0],
            showId,
            searchQuery,
            page,
            perPage,
            withClip,
            episodeStatus,
        },
    ],
    listListenableByShowId: (showId) => [
        {
            ...episodeKeys.allLists()[0],
            showId,
        },
    ],
    allDetails: () => [{ ...episodeKeys.all()[0], scope: 'detail' }],
    detailById: (rawEpisodeId) => [
        { ...episodeKeys.allDetails()[0], episodeId: Number(rawEpisodeId) },
    ],
    detailByPublicId: (rawEpisodePublicId) => [
        { ...episodeKeys.allDetails()[0], episodePublicId: Number(rawEpisodePublicId) },
    ],
};

export default episodeKeys;
