import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import useUserStore from '@/utils/hooks/useUserStore';
import { PRICING_COMPARISON_URL_EN, PRICING_COMPARISON_URL_FR } from '@/utils/pricing';
import Text from '@ui/atoms/Text';
import IconEmojiNerdFace from '@ui/icons/IconEmojiNerdFace';
import IconExport from '@ui/icons/IconExport';

const PricingCompare = () => {
    const { user } = useUserStore();

    return (
        <Stack $gap="0.25rem" $align="center">
            <Text variant="heading" fontWeight="--fw-bold" textAlign="center">
                <FormattedMessage
                    defaultMessage="Vous souhaitez en savoir plus sur nos offres ? {emoji}"
                    values={{
                        emoji: (
                            <IconEmojiNerdFace style={{ verticalAlign: 'middle' }} size="1.5rem" />
                        ),
                    }}
                />
            </Text>
            <Text
                as="a"
                href={
                    user?.languageCode === 'fr'
                        ? PRICING_COMPARISON_URL_FR
                        : PRICING_COMPARISON_URL_EN
                }
                color="--primary"
                fontWeight="--fw-semibold"
                textAlign="center"
                target="_blank"
                rel="noopener"
            >
                <FormattedMessage
                    defaultMessage="{emoji} Consulter nos offres en détails"
                    values={{
                        emoji: (
                            <IconExport
                                size="1.25rem"
                                color="currentColor"
                                style={{ verticalAlign: 'middle' }}
                            />
                        ),
                    }}
                />
            </Text>
        </Stack>
    );
};

export default PricingCompare;
