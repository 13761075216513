import { lazy, Suspense } from 'react';
import { PoseGroup } from 'react-pose';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useWindowDimensions } from '@hooks/useWindowDimensions';
import { AuthRoute, GuestRoute } from './app/routes/authorisationRoutes';
import DefaultRoute from './app/routes/DefaultRoute';
import FetcherUser from './app/fetchers/FetcherUser';
import Loader from './ui/atoms/Loader';
import LoginAsPage from './app/pages/LoginAsPage';
import LoginWithProviderPage from './app/pages/LoginWithProviderPage';
import LoginWithSharingProviderPage from './app/pages/LoginWithSharingProviderPage';
import DiscordPage from './app/pages/DiscordPage';
import Unsubscribe from './app/pages/Unsubscribe';
import Subscribe from './app/pages/Subscribe';
import OffersPage from './app/pages/OffersPage';
import AushaClubCallback from './app/pages/AushaClubCallback';
import EmailConfirmationPage from './app/pages/EmailConfirmationPage';
import StripeCheckoutSuccessPage from './app/pages/StripeCheckoutSuccessPage';
import Topbar from './app/organisms/Topbar';
import FullPageLayout from './app/layout/Fullpage.layout';
import SponsorshipPage from './app/pages/SponsorshipPage';
import { OverlayProvider } from 'react-aria';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Theme } from '@/styles/theme';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import PricingSuccessPage from '@app/pages/PricingSuccessPage';
import EpisodeEditPage from './app/pages/EpisodeEditPage';
import { StatsDateSelectorProvider } from '@/context/StatsDateSelectorContext';

const ApplicationPage = lazy(() => import('./app/pages/ApplicationPage'));
const LoginPage = lazy(() => import('./app/pages/LoginPage'));
const NewShowPage = lazy(() => import('./app/pages/NewShowPage'));
const SongLibraryPage = lazy(() => import('./app/pages/SongLibraryPage'));
const ChannelPage = lazy(() => import('./app/pages/ChannelPage'));
const DisplayAllShows = lazy(() => import('./app/pages/DisplayAllShows'));

import Responsive from '@/context/responsive.context';
import useAmplitudeLogLocationChangeEvent from '@/utils/hooks/useAmplitudeLogLocationChangeEvent.hook';

require('dayjs/locale/fr');

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 /* ms */ * 60 /* sec */ * 60 /* min */, // 60 min
            retry: (failureCount, error) => {
                /**
                 * Error is unexpected (5xx-range), setup default retry count of 3 retries.
                 */
                if (error.response?.status >= 500 && error.response?.status <= 599) {
                    return failureCount < 3;
                }
                /**
                 * Error code matches known and predictable error (typically 4xx-range),
                 * don't bother retrying, it'll just yield more of the same error.
                 */
                return false;
            },
        },
    },
});

function App() {
    useAmplitudeLogLocationChangeEvent();
    const { media, width } = useWindowDimensions();
    return (
        <QueryClientProvider client={queryClient}>
            <Responsive.Provider value={{ ...media, width }}>
                <OverlayProvider style={{ height: '100%' }}>
                    <StatsDateSelectorProvider>
                        <Theme />
                        <Switch>
                            <Route path="/loginas" component={LoginAsPage} />
                            <Route path="/ausha-club-callback" component={AushaClubCallback} />
                            <Route
                                render={() => (
                                    <FetcherUser
                                        fallback={(isLoading) => (
                                            <PoseGroup animateOnMount>
                                                {isLoading && <Loader key={0} />}
                                            </PoseGroup>
                                        )}
                                    >
                                        <Suspense fallback={null}>
                                            <Switch>
                                                <AuthRoute
                                                    path="/checkout-success"
                                                    component={StripeCheckoutSuccessPage}
                                                />
                                            <AuthRoute
                                                path="/app/subscription/offers"
                                                component={OffersPage}
                                            />
                                                <AuthRoute
                                                    path="/email-confirmation"
                                                    component={EmailConfirmationPage}
                                                />
                                                <AuthRoute
                                                    path="/discord"
                                                    component={DiscordPage}
                                                />
                                                <AuthRoute
                                                    path="/app/social/:provider"
                                                    component={LoginWithSharingProviderPage}
                                                />
                                                <AuthRoute
                                                    path="/app/user"
                                                    component={ApplicationPage}
                                                />
                                                <AuthRoute path="/app/sponsorship">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <SponsorshipPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                                <AuthRoute path="/app/show/new">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <NewShowPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                                <AuthRoute path="/app/show/all">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <DisplayAllShows />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                                <AuthRoute path="/app/show/:showId/episodes/edit">
                                                    <EpisodeEditPage />
                                                </AuthRoute>
                                                <AuthRoute
                                                    path="/app/show/:showId/:tabMenu?"
                                                    component={ApplicationPage}
                                                />
                                                <AuthRoute path="/app/song-library">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <SongLibraryPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                                <AuthRoute path="/app/channel/settings">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <ChannelPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                                <AuthRoute
                                                    path="/app/pricing/success"
                                                    component={PricingSuccessPage}
                                                />
                                                <AuthRoute path="/app">
                                                    <Redirect to="/app/show/all" />
                                                </AuthRoute>
                                                <GuestRoute
                                                    path="/login/:provider(google|facebook)"
                                                    component={LoginWithProviderPage}
                                                />
                                                <Route
                                                    path="/(login|password)"
                                                    component={LoginPage}
                                                />
                                                <Route
                                                    path="/unsubscribe"
                                                    component={Unsubscribe}
                                                />
                                                <Route path="/subscribe" component={Subscribe} />
                                                <DefaultRoute to="/app" />
                                            </Switch>
                                        </Suspense>
                                    </FetcherUser>
                                )}
                            />
                        </Switch>
                    </StatsDateSelectorProvider>
                </OverlayProvider>
            </Responsive.Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
}

export default App;
