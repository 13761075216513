import PropTypes from 'prop-types';
import {
    AmazonMusicFile,
    ApplePodcastsFile,
    CastboxFile,
    CastroFile,
    DeezerFile,
    GaanaFile,
    GooglePodcastsFile,
    iHeartFile,
    JioSaavnFile,
    ListenNotesFile,
    OvercastFile,
    PandoraFile,
    PlayerFMFile,
    PocketCastsFile,
    PodcastAddictFile,
    PodcastIndexFile,
    PodchaserFile,
    SoundCloudFile,
    SpotifyFile,
    TuneInFile,
    YouTubeFile,
} from '@public/images/platforms';
import styled from 'styled-components';

const PlatformIcon = ({ platform, className }) => {
    const platformMatch = {
        amazon: AmazonMusicFile,
        castbox: CastboxFile,
        castro: CastroFile,
        deezer: DeezerFile,
        gaana: GaanaFile,
        google_podcasts: GooglePodcastsFile,
        itunes: ApplePodcastsFile,
        i_heart_radio: iHeartFile,
        jio_saavn: JioSaavnFile,
        listen_notes: ListenNotesFile,
        overcast: OvercastFile,
        pandora: PandoraFile,
        player_fm: PlayerFMFile,
        pocket_casts: PocketCastsFile,
        podcast_addict: PodcastAddictFile,
        podcast_index: PodcastIndexFile,
        podchaser: PodchaserFile,
        radio_public: null,
        soundcloud: SoundCloudFile,
        spotify: SpotifyFile,
        tunein: TuneInFile,
        youtube: YouTubeFile,
    };

    return (
        <CustomIcon src={platformMatch[platform]} alt={`${platform} logo`} className={className} />
    );
};

const CustomIcon = styled.img`
    display: inline-block;
    width: 2rem;
    height: auto;
    aspect-ratio: 1/1;
`;

PlatformIcon.propTypes = {
    platform: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default PlatformIcon;
