import { formatDates, makeArray } from '../../../../helpers';
import SelectNative from '../SelectNative';

const getMonthWordToStringNumber = (date) => {
    const months = makeArray(12).map((month, i) => formatDates('months', i));
    const indexOfMonth = `${months.findIndex((m) => m.props.defaultMessage === date) + 1}`;
    return indexOfMonth.padStart(2, '0');
};

const populateMonthsSelect = (month) => {
    // ParseInt the currentMonth and transform it to an index e.g: datePickerSelect[1] = "01" -1 === 0
    const currentMonth = parseInt(month, 10) - 1;
    // formatDates is an array with all months. We mapped makeArray and selected the currentMonth
    // Then display <options> value of day in letter
    // e.g: formatDates('months', 0).props.defaultMessage === Janvier
    return makeArray(12).map((m) => (
        <option key={m} selected={m === currentMonth}>
            {formatDates('months', m).props.defaultMessage}
        </option>
    ));
};

const MonthSelect = ({ onChange, source }) => {
    const [year, month, day] = source;
    return (
        <SelectNative
            label={formatDates('range', 'months')}
            source={populateMonthsSelect(month)}
            name="month"
            onChange={(e) => onChange([year, getMonthWordToStringNumber(e.target.value), day])}
        />
    );
};

export default MonthSelect;
