import { useQuery } from '@tanstack/react-query';
import { fetchChannel } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import channelKeys from './channelKeys';

const queryFn = async () => {
    const { data } = await fetchChannel();

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useChannelQuery = () => {
    return useQuery({
        queryKey: channelKeys.allDetails(),
        queryFn,
    });
};

export default useChannelQuery;
