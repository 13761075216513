import { createApi } from '../callApi';
import { SOCIAL_NETWORK_POST_STATUS } from '@/utils/constants';
const contentApi = createApi('CONTENT');

/* -- social media manager -- */
export const fetchSharingProvider = (show, provider) => {
    return contentApi({ method: 'GET', target: `/v1/sharing/shows/${show}/${provider}` });
};
export const fetchProviders = (show) => {
    return contentApi({ method: 'GET', target: `/v1/sharing/shows/${show}/providers` });
};
export const fetchPodcastNewsletterTemplate = (podcast) => {
    return contentApi({ method: 'GET', target: `/v1/podcasts/${podcast}/newsletter/message` });
};
export const fetchShowTemplate = (show, provider) => {
    return contentApi({ method: 'GET', target: `/v1/sharing/shows/${show}/${provider}/message` });
};
export const updateShowTemplate = (show, provider, body) => {
    return contentApi({
        method: 'PUT',
        target: `/v1/sharing/shows/${show}/${provider}/message`,
        body,
    });
};
export const fetchPosts = (show, page, perPage) => {
    return contentApi({
        method: 'GET',
        target: `/v1/sharing/shows/${show}/messages`,
        query: { page, per_page: perPage },
    });
};
export const loginSharingProvider = (showId, { provider, ...query }) => {
    return contentApi({
        method: 'GET',
        target: `/sharing/${provider}/callback`,
        query: { show_id: showId, ...query.query },
    });
};
export const fetchNewsletterSubscribers = (show, page, perPage = 10) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${show}/newsletters/subscribers`,
        query: { page, per_page: perPage },
    });
};
export const fetchNewsletterExportSubscribersCsv = (show, body) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${show}/newsletters/subscribers/export`,
        body,
    });
};
export const fetchNewsletterPreview = (podcast, episodeName) => {
    return contentApi({
        method: 'GET',
        target: `/v1/podcasts/${podcast}/newsletters/preview`,
        query: { podcast_name: episodeName },
    });
};
export const fetchTwitterUsers = (podcast, query) => {
    return contentApi({
        method: 'GET',
        target: `/v1/sharing/podcasts/${podcast}/twitter/users`,
        query: { query },
    });
};
export const fetchSocialMediaPostsOfEpisode = (episode) => {
    return contentApi({
        method: 'GET',
        target: `/v1/sharing/podcasts/${episode}/messages`,
    });
};
export const fetchInsightsOfSocialMediaPosts = (postIds) => {
    return contentApi({
        method: 'GET',
        target: '/v1/sharing/messages/insights',
        query: { message_ids: postIds },
    });
};
export const addPost = (podcast, provider, body) => {
    return contentApi({
        method: 'POST',
        target: `/v1/sharing/podcasts/${podcast}/${provider}/message`,
        body,
    });
};
export function uploadMessageImage(messageId, file) {
    const body = new FormData();
    body.append('file', file);
    return contentApi({
        method: 'POST',
        target: `/v1/sharing/messages/${messageId}/image`,
        body,
    });
}
export const deleteUploadedMessageImage = (messageId) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/sharing/messages/${messageId}/image`,
    });
};
export const deletePost = (message) => {
    return contentApi({ method: 'DELETE', target: `/v1/sharing/messages/${message}` });
};
export const unlinkSharing = (show, provider) => {
    return contentApi({ method: 'DELETE', target: `/v1/sharing/shows/${show}/${provider}` });
};
export const deleteNewsletterSubscribers = (show, body) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/shows/${show}/newsletters/subscribers`,
        body,
    });
};
export const updatePost = (message, body) => {
    return contentApi({ method: 'PUT', target: `/v1/sharing/messages/${message}`, body });
};
export const updatePodcastNewsletterTemplate = (podcast, body) => {
    return contentApi({
        method: 'PUT',
        target: `/v1/podcasts/${podcast}/newsletter/message`,
        body,
    });
};
export const fetchAiGeneratedSocialPost = (episodeId, socialNetwork) => {
    return contentApi({
        method: 'GET',
        target: `/v1/podcasts/${episodeId}/promotion_posts/${socialNetwork}`,
    });
};
export const fetchCalendarCommunicationEvents = (show, startDate, endDate) => {
    return contentApi({
        method: 'GET',
        target: `/v1/sharing/shows/${show}/communication_events`,
        query: { start_date: startDate, end_date: endDate },
    });
};
export const fetchCalendarCommunicationEventDetails = (message) => {
    return contentApi({
        method: 'GET',
        target: `/v1/sharing/messages/${message}`,
    });
};
export const fetchCalendarCommunicationEventDraft = (show) => {
    return contentApi({
        method: 'GET',
        target: `/v1/sharing/shows/${show}/messages`,
        query: { state: SOCIAL_NETWORK_POST_STATUS.DRAFT },
    });
};
export const fetchCalendarCommunicationEventScheduled = (show) => {
    return contentApi({
        method: 'GET',
        target: `/v1/sharing/shows/${show}/planned_messages`,
    });
};
export const fetchProviderRedirectUrl = (provider) => {
    const currentUrl = new URL(window.location.href);
    const domain = currentUrl.origin;
    return contentApi({
        method: 'GET',
        target: `/sharing/${provider}/login_page_url`,
        query: { redirect_url: `${domain}/app/social/${provider}` },
    });
};
