const showKeys = {
    all: () => [{ entity: 'show' }],
    allLists: () => [{ ...showKeys.all()[0], scope: 'list' }],
    list: ({ page, perPage, sort, transformer } = {}) => [
        { ...showKeys.allLists()[0], page, perPage, sort, transformer },
    ],
    listByChannelId: (rawChannelId, { page, perPage, sort, transformer } = {}) => [
        {
            ...showKeys.allLists()[0],
            channelId: Number(rawChannelId),
            page,
            perPage,
            sort,
            transformer,
        },
    ],
    listArchived: (channelId) => [
        { ...showKeys.listByChannelId(channelId)[0], status: 'archived' },
    ],
    allDetails: () => [{ ...showKeys.all()[0], scope: 'detail' }],
    detailById: (rawShowId) => [{ ...showKeys.allDetails()[0], showId: Number(rawShowId) }],
    detailByPublicId: (rawShowPublicId) => [
        { ...showKeys.allDetails()[0], showPublicId: Number(rawShowPublicId) },
    ],
};

export default showKeys;
