import { useMemo } from 'react';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import CustomDocument from '@app/molecules/TranscriptionEditor/CustomExtensions/CustomDocument';
import CustomParagraph from '@app/molecules/TranscriptionEditor/CustomExtensions/CustomParagraph';
import CustomPhrase from '@app/molecules/TranscriptionEditor/CustomExtensions/CustomPhrase';
import CustomWord from '@app/molecules/TranscriptionEditor/CustomExtensions/CustomWord';
// import KaraokeExtension from '@app/molecules/TranscriptionEditor/CustomExtensions/KaraokeExtension';
import { TextSelection } from '@tiptap/pm/state';
// import SegmentExtension from '@app/molecules/TranscriptionEditor/CustomExtensions/SegmentExtension';
// import ConfidenceUpdateExtension from '@app/molecules/TranscriptionEditor/CustomExtensions/ConfidenceUpdateExtension';
import SeekAudioExtension from '@app/molecules/TranscriptionEditor/CustomExtensions/SeekAudioExtension';
import ImprovedConfidenceUpdateExtension from '@app/molecules/TranscriptionEditor/CustomExtensions/ImprovedConfidenceUpdateExtension';
import ImprovedKaraokeExtension from '@app/molecules/TranscriptionEditor/CustomExtensions/ImprovedKaraokeExtension';
// import SpeakersExtension from '@app/molecules/TranscriptionEditor/CustomExtensions/SpeakersExtension';
import { convertTranscriptToTiptapContent } from '@app/molecules/TranscriptionEditor/transcriptConverter';
import { useEditor } from '@tiptap/react';

const useTranscriptionEditor = ({ transcription, onPlaySegment }) => {
    const hasMultipleWordsInSelection = (editor, selection) => {
        const { from, to } = selection;
        const selectedText = editor.textBetween(from, to, ' ');
        return selectedText.trim().split(' ').length > 1;
    };

    const content = useMemo(() => {
        return convertTranscriptToTiptapContent(transcription);
    }, [transcription]);

    const editor = useEditor(
        {
            extensions: [
                CustomDocument,
                CustomParagraph,
                CustomPhrase,
                CustomWord,
                Text,
                History,
                // ConfidenceUpdateExtension,
                ImprovedConfidenceUpdateExtension,
                // KaraokeExtension,
                ImprovedKaraokeExtension,
                /* SegmentExtension.configure({
                    onPlaySegment: onPlaySegment,
                }), */
                SeekAudioExtension.configure({ callback: onPlaySegment }),
                // SpeakersExtension,
            ],
            content,
            parseOptions: {
                preserveWhitespace: 'full', // \n in text value should be preserved as linebreaks
            },
            editorProps: {
                attributes: {
                    spellcheck: 'false', // With spellcheck on, the browser explodes if the transcription is too large. Keep it off ;)
                },
                handleDOMEvents: {
                    keydown: (view, event) => {
                        const { selection } = view.state;
                        if (
                            selection &&
                            !selection.empty &&
                            hasMultipleWordsInSelection(view.state.doc, selection)
                        ) {
                            event.preventDefault();
                            return;
                        }
                        if (
                            (event.ctrlKey || event.metaKey) &&
                            ['c', 'v', 'x', 'a'].includes(event.key.toLowerCase())
                        ) {
                            event.preventDefault();
                            return;
                        }
                        return false;
                    },
                },
            },
            onSelectionUpdate: ({ editor }) => {
                const { selection } = editor.state;
                if (!selection.empty) {
                    const { from, to } = selection;
                    const selectedText = editor.state.doc.textBetween(from, to, ' ');
                    const firstWord = selectedText.trim().split(' ')[0];

                    if (hasMultipleWordsInSelection(editor.state.doc, selection)) {
                        const tr = editor.state.tr.setSelection(
                            TextSelection.create(
                                editor.state.doc,
                                from,
                                from + firstWord.length + 1,
                            ),
                        );
                        editor.view.dispatch(tr);
                    }
                }
            },
        },
        [onPlaySegment],
    );

    return editor;
};

export default useTranscriptionEditor;
