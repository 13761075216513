import { FormattedMessage } from 'react-intl';
import { ReactComponent as EmailIcon } from '@public/icons/mail.svg';
import styled from 'styled-components';
import { resolveColor } from '@/utils/cssVariables';
import Text from '@ui/atoms/Text';

const StyledLink = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    min-width: fit-content;
    width: 100%;
    background-color: var(--primary);
    box-shadow: 0 2px 12px ${resolveColor('--primary')}33;
    color: var(--white);
    border-radius: var(--r-xs);
    padding: 0.625rem;
    cursor: pointer;
    border: none;
    transition-duration: 200ms;
    transition-property: opacity, outline, background, color, box-shadow;
    text-decoration: none;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        width: fit-content;
    }

    &:hover {
        background: var(--primary400);
        box-shadow: none;
    }

    svg {
        fill: var(--white);
        width: 1.25rem;
        height: 1.25rem;
        flex-shrink: 0;
    }
`;

const EmailSharingButton = ({ subject, body, url }) => {
    const emailBody = url ? `${body}\n${url}` : body;
    const emailUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        emailBody,
    )}`;

    return (
        <StyledLink href={emailUrl}>
            <EmailIcon />
            <Text as="span" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Partager par email" />
            </Text>
        </StyledLink>
    );
};

export default EmailSharingButton;
