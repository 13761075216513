import axios from 'axios';
import Cookie from 'js-cookie';
import { runInAction } from 'mobx';
import stores from '@/stores/RootStore';
import { buildQuery } from './query';

const instances = {};
let isRefreshing = false;
const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain',
};
const apiLookup = {
    AUTH: process.env.API_AUTH,
    CONTENT: process.env.API_CONTENT,
    BILLING: process.env.API_BILLING,
    PUBLIC: process.env.API_PUBLIC,
    ANALYTICS: process.env.API_ANALYTICS,
};

export const createApi = (api) =>
    async function callApi({ method, target, body, query, ...rest }) {
        const axiosInstance = getAxiosInstance(api);
        const { url, parsedMethod } = await parseApiUrl({ api, method, target, query });
        const config = { url, method: method ?? parsedMethod ?? 'GET', data: body, ...rest };

        let res = {};
        try {
            res = await axiosInstance.request(config);
        } catch (error) {
            if (!isRefreshing && error.response && error.response.status === 401) {
                return refreshToken(axiosInstance, config);
            }
            throw error;
        }
        return { ...res.data, axios: res };
    };

export async function parseApiUrl({ api, target, query }) {
    return { url: `${apiLookup[api]}${target}${buildQuery(query)}` };
}

function getAxiosInstance(apiName) {
    if (!(apiName in instances)) {
        instances[apiName] = axios.create({ baseURL: apiLookup[apiName], headers });
    }
    return instances[apiName];
}

export const getAxiosInstances = () => instances;

async function refreshToken(axiosInstance, config) {
    isRefreshing = true;
    try {
        const { data } = await getAxiosInstance('CONTENT').request({
            url: '/refresh',
            method: 'POST',
            data: { refresh_token: Cookie.get('refresh') },
        });
        runInAction(() => {
            stores.authStore.refreshToken = data.refresh_token;
            stores.authStore.token = data.access_token;
        });

        const res = await axiosInstance.request(config);
        isRefreshing = false;
        return { ...res.data, axios: res };
    } catch (error) {
        isRefreshing = false;
        stores.authStore.token = null;
    }

    return undefined;
}
