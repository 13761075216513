import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import HourSelect from '../HourSelect';
import MinuteSelect from '../MinuteSelect';

const TimePickerSelect = ({ time, setTime }) => (
    <DeprecatedPaper flex w="max-content" ml={30}>
        <HourSelect source={time} onChange={setTime} />
        <MinuteSelect source={time} onChange={setTime} />
    </DeprecatedPaper>
);

export default TimePickerSelect;
