import Cookie from 'js-cookie';
import { autorun, computed, reaction, runInAction, action } from 'mobx';
import { boundMethod } from 'autobind-decorator';
import {
    login,
    loginAs,
    loginWith,
    logout,
    register,
    resendVerificationEmail,
    resetPassword,
    setResetPassword,
    checkReferer,
} from '@/api';
import Store from './Store';
import { apiCall } from '../components/app/decorators/api';
import { sendAmplitudeLogEvent } from '@/helpers';
import * as amplitude from '@amplitude/analytics-browser';
import { prefetchSubscriptionQuery } from '@queries/subscription/useSubscriptionQuery.hook';
import { prefetchUserQuery } from '@queries/user/useUserQuery.hook';
import {
    removeAuthorizationCookies,
    setAuthorizationCookies,
    setAxiosAuthorizationHeader,
} from '@/helpers/authorization';
import { pushLoginEventToGTM } from '@/helpers/gtm';

class AuthStore extends Store {
    static observables = {
        token: Cookie.get('token'),
        refreshToken: Cookie.get('refresh'),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        isLoggedAs: false,
    };

    static async findProviderUrl(provider) {
        return `${process.env.API_AUTH}/auth/${provider}`;
    }

    @computed
    get isLoggedIn() {
        return !!this.token;
    }

    constructor(state) {
        super(state);
        reaction(
            () => this.refreshToken,
            (token) => token && state.userStore.fetchUser(),
        );
        autorun(() => {
            if (this.token) {
                setAxiosAuthorizationHeader(this.token);
                setAuthorizationCookies({
                    accessToken: this.token,
                    refreshToken: this.refreshToken,
                });
            } else {
                removeAuthorizationCookies();
            }
        });
    }

    @apiCall
    async login(formData, invitationToken, invitationShow, options = {}) {
        const {
            redirect = true, // Redirect user to homepage after login success
        } = options;

        const data = await login({
            ...formData,
            invitation_token: invitationToken,
            invitation_show: invitationShow,
        });
        this.fetchEssentialQueries();
        runInAction(() => {
            this.token = data.access_token;
            this.refreshToken = data.refresh_token;

            if (redirect) {
                this.state.routerStore.redirectAfterLogin();
            }
        });
        pushLoginEventToGTM();
    }

    @apiCall
    async loginAs(token) {
        const data = await loginAs({ token });
        this.fetchEssentialQueries();
        runInAction(() => {
            this.token = data.access_token;
            this.refreshToken = data.refresh_token;
            this.isLoggedAs = true;
            this.state.routerStore.redirectAfterLogin();
        });
    }

    @apiCall
    async loginWith(formData, dataLayerEvent, provider) {
        const data = await loginWith(formData);
        this.fetchEssentialQueries();
        runInAction(() => {
            this.token = data.access_token;
            this.refreshToken = data.refresh_token;
            this.state.routerStore.redirectAfterLogin();
        });

        if (provider && provider === 'google' && dataLayerEvent === 'signup') {
            sendAmplitudeLogEvent('signup_google');
        }

        // Add data to Google Tag Manager
        // eslint-disable-next-line no-undef
        if (dataLayer) {
            // eslint-disable-next-line no-undef
            dataLayer.push({
                event: dataLayerEvent || 'login',
            });
        }
    }

    @boundMethod
    async logout(queryClient) {
        if (!this.isLoggedIn) {
            return;
        }
        this.state.reset();
        if (queryClient) {
            queryClient.removeQueries();
        }
        await logout();
        amplitude.reset();
    }

    @action
    reset() {
        super.reset();
        this.token = null;
        this.refreshToken = null;
    }

    @apiCall
    setResetPassword(formData) {
        return setResetPassword(formData);
    }

    @apiCall
    changePassword(formData) {
        return resetPassword(formData);
    }

    @apiCall
    async register(formData) {
        const data = await register({ ...formData, timezone: this.timezone });

        runInAction(() => {
            this.token = data.access_token;
            this.refreshToken = data.refresh_token;

            const { history, defaultRedirect } = this.state.routerStore;
            history.push(defaultRedirect);
            setAxiosAuthorizationHeader(this.token);
            setAuthorizationCookies({ accessToken: this.token, refreshToken: this.refreshToken });
        });
        this.fetchEssentialQueries();
        await this.state.userStore.fetchUser();
        if (this.state.userStore.user) {
            // eslint-disable-next-line no-undef
            $FPROM.trackSignup({ email: this.state.userStore.user.email }, () =>
                console.log('Callback received!'),
            );
        }

        // Add data to Google Tag Manager
        // eslint-disable-next-line no-undef
        if (dataLayer) {
            // eslint-disable-next-line no-undef
            dataLayer.push({
                event: 'signup',
            });
        }
    }

    @apiCall
    resendVerificationEmail() {
        return resendVerificationEmail();
    }

    @apiCall
    checkReferer(code) {
        return checkReferer(code);
    }

    @apiCall
    async fetchEssentialQueries() {
        await Promise.all([prefetchSubscriptionQuery(), prefetchUserQuery()]);
    }
}

export default AuthStore;
