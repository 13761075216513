const newsletterKeys = {
    all: () => [{ entity: 'newsletter' }],
    allDetails: () => [{ ...newsletterKeys.all()[0], scope: 'detail' }],
    detailByShowId: (rawShowId) => [
        { ...newsletterKeys.allDetails()[0], showId: Number(rawShowId) },
    ],
    detailByEpisodeId: (rawEpisodeId) => [
        { ...newsletterKeys.allDetails()[0], episodeId: Number(rawEpisodeId) },
    ],
};

export default newsletterKeys;
