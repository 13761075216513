import { useController } from 'react-hook-form';
import DropFileUploader from './DropFileUploader';

export const DropFileUploaderController = (props) => {
    const { control, name, onChange, onBlur, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <DropFileUploader
            onChange={(value) => {
                const forwardedValue = !value ? null : value;
                field.onChange(forwardedValue);
                onChange && onChange(forwardedValue);
            }}
            onBlur={(...v) => {
                onBlur && onBlur(...v);
                field.onBlur(...v);
            }}
            name={field.name}
            hasError={!!fieldState.error}
            errorMessage={fieldState.error?.message}
            {...rest}
        />
    );
};
