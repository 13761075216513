import { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '@public/icons/close.svg';
import FullscreenModal from '@ui/molecules/FullscreenModal';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import { connect } from '../decorators/connect';
import FetcherPrices from '../fetchers/FetcherPrices';
import { useAmplitudeLogEvent } from '@/utils/hooks/useAmplitude';
import Cluster from '@ui/layout/Cluster';
import { useResponsive } from '@/utils/hooks/useResponsive';
import { PRICING_CURRENCY, PRICING_NAME } from '@/utils/pricing';
import PricingContext from './Pricing/PricingContext';
import PricingUpdateOfferModal from './Pricing/PricingUpdateOfferModal';
import PricingErrorModal from './Pricing/PricingErrorModal';
import PricingOffers from './Pricing/PricingOffers';
import useQuery from '@/utils/hooks/useQuery';
import styled from 'styled-components';
import AushaLogo from '@ui/atoms/AushaLogo';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { SUBSCRIPTION_ACTION } from '@/utils/constants';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';
import { useQueryClient } from '@tanstack/react-query';

const StyledLogo = styled(AushaLogo)`
    height: 1.5rem;
`;
const SubscriptionModalInnerWrapper = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        row-gap: 4.5rem;
        padding-bottom: 6rem;
    }
`;

const enhance = connect(({ subscriptionStore, routerStore, userStore }) => ({
    goBack: () => routerStore.goBack(),
    redirectToShowsList: () => routerStore.push('show.all'),
    currentSubscription: subscriptionStore.subscription,
    getPricesOfCurrencyAndProduct: subscriptionStore.getPricesOfCurrencyAndProduct,
    pricesOfCurrentSubscriptionPricing: subscriptionStore.pricesOfCurrentSubscriptionPricing,
    navigatedFrom: routerStore.location.state?.from,
    userCountry: userStore.user.countryCode,
}));

const EditSubscriptionModal = ({
    isOpened,
    goBack,
    redirectToShowsList,
    currentSubscription,
    getPricesOfCurrencyAndProduct,
    pricesOfCurrentSubscriptionPricing,
    navigatedFrom,
    userCountry,
}) => {
    useAmplitudeLogEvent(
        'Subscription Plans Viewed',
        { from_trial_period_band: navigatedFrom === 'trial-period-band' },
        !!isOpened,
    );
    const query = useQuery();
    const queryClient = useQueryClient();
    const isFromCheckout = query.get('fromCheckout') === 'true';

    const { isDesktop } = useResponsive();
    const history = useHistory();
    const toast = useModalToastQueue();

    const isCurrentSubscriptionActive = !!currentSubscription?.subscribed;
    const isCurrentSubscriptionBilledYearly = !!currentSubscription?.yearly;
    const currentSubscriptionCurrency = currentSubscription?.currency;
    const currentSubscriptionPricing = currentSubscription?.pricing;

    const [isYearlyPeriodicitySelected, setYearlyPeriodicitySelected] = useState(
        (currentSubscription.subscribed && isCurrentSubscriptionBilledYearly) ?? false,
    );

    const calculatedCurrency =
        currentSubscription.subscribed && currentSubscriptionCurrency
            ? currentSubscriptionCurrency
            : userCountry === 'FR'
            ? PRICING_CURRENCY.EUR
            : userCountry === 'GB'
            ? PRICING_CURRENCY.GBP
            : PRICING_CURRENCY.USD;

    const [selectedCurrency, setSelectedCurrency] = useState(calculatedCurrency);

    const [offerUpdatePayload, setOfferUpdatePayload] = useState();

    const [successPayload, setSuccessPayload] = useState();
    const [errorPayload, setErrorPayload] = useState();

    const onClose = async () => {
        if (isFromCheckout) {
            // When user come back from Stripe Checkout page, goBack would loop on Stripe Checkout Page.
            // Instead, we redirect to an arbitrary page for breaking the loop.
            await redirectToShowsList();
            return;
        }
        goBack();
    };

    useEffect(() => {
        if (!successPayload) return;

        /**
         * Invalidate quota, subscription & user queries to force refetch
         */
        queryClient.invalidateQueries([{ entity: 'quota' }]);
        queryClient.invalidateQueries([{ entity: 'subscription' }]);
        queryClient.invalidateQueries([{ entity: 'user' }]);

        if (successPayload.action === SUBSCRIPTION_ACTION.PERIODICITY_UPDATE) {
            onClose().then(() =>
                toast.success(
                    <FormattedMessage
                        defaultMessage="C'est fait ! Vous bénéficiez désormais de l'offre {offerName} {periodicity}."
                        values={{
                            offerName: PRICING_NAME[successPayload.offer],
                            periodicity: currentSubscription.yearly ? (
                                <FormattedMessage defaultMessage="annuelle" />
                            ) : (
                                <FormattedMessage defaultMessage="mensuelle" />
                            ),
                        }}
                    />,
                ),
            );
        } else
            history.push(
                `/app/pricing/success?offer=${successPayload.offer}&upgrade=${
                    successPayload.action === SUBSCRIPTION_ACTION.UPGRADE
                }`,
            );
    }, [successPayload]);

    return (
        <PricingContext.Provider
            value={{
                getPricesOfCurrencyAndProduct,
                pricesOfCurrentSubscriptionPricing,
                isCurrentSubscriptionActive,
                isCurrentSubscriptionBilledYearly,
                currentSubscriptionCurrency,
                currentSubscriptionPricing,
                isYearlyPeriodicitySelected,
                setYearlyPeriodicitySelected,
                selectedCurrency,
                setSelectedCurrency,
                offerUpdatePayload,
                setOfferUpdatePayload,
                successPayload,
                setSuccessPayload,
                errorPayload,
                setErrorPayload,
            }}
        >
            <FullscreenModal isOpened={isOpened}>
                <SubscriptionModalInnerWrapper>
                    <Cluster $justify="space-between" $flexWrap="nowrap" $gap="1rem">
                        <StyledLogo isComplete isColored />
                        <DeprecatedButton onClick={onClose} accent="rounded" icon>
                            <DeprecatedIcon as={CloseIcon} />
                        </DeprecatedButton>
                    </Cluster>
                    <FetcherPrices>
                        <PricingOffers />
                    </FetcherPrices>
                </SubscriptionModalInnerWrapper>
            </FullscreenModal>
            {offerUpdatePayload && (
                <PricingUpdateOfferModal
                    isOpened={!!offerUpdatePayload}
                    onClose={() => setOfferUpdatePayload(null)}
                />
            )}
            {!!errorPayload && (
                <PricingErrorModal
                    isOpened={!!errorPayload}
                    onClose={() => setErrorPayload(null)}
                />
            )}
        </PricingContext.Provider>
    );
};

export default enhance(EditSubscriptionModal);
