import useEpisodeJobsProgressQuery from '@hooks/useEpisodeJobsProgress.hook';
import useChaptersQuery from '@queries/chapter/useChaptersQuery.hook';
import EpisodeEditFormChaptersEditor from '@app/organisms/EpisodeEditForm/EpisodeEditFormChapters/EpisodeEditFormChaptersEditor';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import ChaptersProgressTracker from './ChaptersProgressTracker';

const EpisodeEditFormChapters = () => {
    const episode = useEpisodeBeingEdited();
    const { waveformUrl: episodeWaveformUrl } = episode;

    const isEpisodeJobsProgressQueryEnabled = episode.files.length > 0 && !episodeWaveformUrl;
    const episodeJobsProgressQuery = useEpisodeJobsProgressQuery({
        episodeId: episode.id,
        enabled: isEpisodeJobsProgressQueryEnabled,
    });
    // Ignore result but enable chapters fetching once chapters job is completed
    useChaptersQuery(episode.id, {
        enabled: episodeJobsProgressQuery.data?.readchapters?.isFinished,
    });

    if (isEpisodeJobsProgressQueryEnabled && episodeJobsProgressQuery.isLoading) {
        // TODO Handle loading state
        return 'LOADING';
    }

    if (isEpisodeJobsProgressQueryEnabled && episodeJobsProgressQuery.error) {
        // TODO Handle error state
        return 'ERROR';
    }

    const { data: episodeJobsProgress } = episodeJobsProgressQuery;

    // Jobs are still running
    if (episodeJobsProgress && !episodeJobsProgress.isFinished && !episodeJobsProgress.isFailed) {
        return <ChaptersProgressTracker progress={episodeJobsProgress} />;
    }

    // TODO Camelcase useEpisodeJobsProgressQuery data
    const jobWaveformUrl = episodeJobsProgress?.generatewaveform?.result?.waveform_url;
    const waveformUrl = episodeWaveformUrl || jobWaveformUrl;

    if (!waveformUrl) {
        // TODO Handle error state
        return 'ERROR';
    }

    return <EpisodeEditFormChaptersEditor episode={episode} waveformUrl={waveformUrl} />;
};

export default EpisodeEditFormChapters;
