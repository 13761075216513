import { useFormContext } from 'react-hook-form';
import { InputRichTextController } from '@ui/atoms/InputRichText';
import { FormattedMessage } from 'react-intl';

const EpisodeEditFormContentDescriptionField = () => {
    const { control } = useFormContext();

    return (
        <InputRichTextController
            name="content.htmlDescription"
            control={control}
            label={<FormattedMessage defaultMessage="Description" />}
            charactersLimit={4000}
        />
    );
};

export default EpisodeEditFormContentDescriptionField;
