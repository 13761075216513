import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import UnsubscribeSurveyForm from './UnsubscribeSurveyForm';

const UnsubscribeSurveyModal = ({ isOpen, onClose, onAfterSubmit }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={<FormattedMessage defaultMessage="Nous sommes tristes de vous voir partir 😢" />}
            size="small"
        >
            <UnsubscribeSurveyForm onAfterSubmit={onAfterSubmit} onClose={() => onClose()} />
        </Modal>
    );
};

UnsubscribeSurveyModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onAfterSubmit: PropTypes.func,
};

UnsubscribeSurveyModal.defaultProps = {
    isOpen: false,
    onClose: () => {},
};

export default UnsubscribeSurveyModal;
