import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchChannel = () => {
    return contentApi({ method: 'GET', target: '/v1/channels' });
};
export const createChannelPage = (channelId, body) => {
    return contentApi({
        method: 'POST',
        target: `/v1/channels/${channelId}/page`,
        body,
    });
};
export const updateChannelPage = (channelId, body) => {
    return contentApi({
        method: 'PUT',
        target: `/v1/channels/${channelId}/page`,
        body,
    });
};
export const fetchChannelPage = (channelId) => {
    return contentApi({ method: 'GET', target: `/v1/channels/${channelId}/page` });
};
export const fetchChannelShows = (channelId) => {
    return contentApi({ method: 'GET', target: `/v1/channels/${channelId}/latest_shows` });
};
export const searchChannelPage = (query) => {
    return contentApi({ method: 'GET', target: '/v1/channel_pages', query });
};
export const uploadChannelImage = (channelId, file, format) => {
    const body = new FormData();
    body.append('file', file);
    body.append('format', format);
    return contentApi({ method: 'POST', target: `/v1/channels/${channelId}/images`, body });
};
export const deleteChannelImage = (channelId, format) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/channels/${channelId}/images`,
        query: { format },
    });
};
