import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchRssInfo = (query) => {
    return contentApi({ method: 'GET', target: '/v1/rss/info', query });
};
export const fetchRssImport = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/rss/import` });
};
export const fetchRssSettings = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/rss/settings` });
};
export const addRssImport = (show, body) => {
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/rss/import`, body });
};
export const retryImport = (id) => {
    return contentApi({ method: 'POST', target: `/v1/rss/imports/episodes/${id}/retry` });
};
export const deleteImportedEpisode = (id) => {
    return contentApi({ method: 'DELETE', target: `/v1/rss/imports/episodes/${id}` });
};
export const deleteRssImport = (show) => {
    return contentApi({ method: 'DELETE', target: `/v1/shows/${show}/rss/import` });
};
export const updateRssImport = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}/rss/import`, body });
};
export const updateRssSettings = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}/rss/settings`, body });
};
export const fetchAppleShows = (q) => {
    return contentApi({ method: 'GET', target: '/v1/rss/search', query: { q } });
};
export const fetchCategories = () => {
    return contentApi({ method: 'GET', target: '/v1/rss/categories' });
};
