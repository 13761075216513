import Model from './Model';
import { apiCall } from '../components/app/decorators/api';

class PermissionModel extends Model {
    static attributes = {
        id: 'id',
        userRole: 'role',
        userName: 'user_name',
        userEmail: 'user_email',
        userAvatar: 'user_avatar',
    };

    @apiCall
    updateRole(role) {
        return this.store.updateRole(this.id, role);
    }

    @apiCall
    delete() {
        return this.store.deletePermission(this.id);
    }
}

export default PermissionModel;
