import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchInvitations = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/invitations` });
};
export const fetchPermissions = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/permissions` });
};
export const addInvitation = (show, body) => {
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/invitations`, body });
};
export const updatePermission = (permission, body) => {
    return contentApi({ method: 'PUT', target: `/v1/permissions/${permission}`, body });
};
export const deletePermission = (permission) => {
    return contentApi({ method: 'DELETE', target: `/v1/permissions/${permission}` });
};
export const deleteInvitation = (invitation) => {
    return contentApi({ method: 'DELETE', target: `/v1/invitations/${invitation}` });
};
export const exportShowGuestUsers = (showId) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${showId}/permissions_extract`,
        headers: { Accept: 'text/csv' },
    });
};
export const exportAccountGuestUsers = (channelId) => {
    return contentApi({
        method: 'GET',
        target: `/v1/channels/${channelId}/permissions_extract`,
        headers: { Accept: 'text/csv' },
    });
};
