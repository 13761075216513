import messages from './validationMessages';

export const createValidator =
    ({ name, predicate }) =>
    (value) => ({
        value,
        name,
        validate(fieldValue, form) {
            if (predicate(fieldValue, value, form)) {
                return null;
            }
            return messages[name](value);
        },
    });
