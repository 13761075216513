import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { findChildren } from '@tiptap/react';
import { formatTimeLight } from '@/helpers';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

interface SeekAudioExtensionOptions {
    nodeType: string; // The type of node to seek audio within
    nodesInterval: number; // Interval between seeking audio nodes
    callback: (time: number) => void; // Callback function to handle audio seeking
}

const renderSeekAudioButton = ({
    time,
    callback,
}: {
    time: number;
    callback: (time: number) => void;
}): HTMLButtonElement => {
    const button = document.createElement('button');
    button.innerText = `▶ ${formatTimeLight(time)}`;
    button.className = 'transcription-seek-audio-button';
    button.addEventListener('click', () => callback(time));

    return button;
};

const getDecorations = (
    doc: ProseMirrorNode,
    options: SeekAudioExtensionOptions,
): DecorationSet => {
    const { nodeType, nodesInterval, callback } = options;

    const decorations = findChildren(doc, (node) => node.type.name === nodeType)
        .filter((_, index) => index % nodesInterval === 0)
        .map(({ pos, node }) =>
            Decoration.widget(pos, renderSeekAudioButton({ time: node.attrs.start, callback })),
        );

    return DecorationSet.create(doc, decorations);
};

/**
 * An extension for Tiptap that adds audio playback buttons at regular intervals within the transcription document.
 */
const SeekAudioExtension = Extension.create<SeekAudioExtensionOptions>({
    name: 'seekAudioExtension',

    addOptions() {
        return {
            nodeType: 'phrase',
            nodesInterval: 5,
            callback: () => {},
        };
    },

    addProseMirrorPlugins() {
        const options = this.options;

        return [
            new Plugin({
                key: new PluginKey('seekAudioPlugin'),
                state: {
                    init(_, { doc }) {
                        return getDecorations(doc, options);
                    },
                    apply(transaction, decorations) {
                        return decorations.map(transaction.mapping, transaction.doc);
                    },
                },
                props: {
                    decorations(state) {
                        return this.getState(state);
                    },
                },
            }),
        ];
    },
});

export default SeekAudioExtension;
