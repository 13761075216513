import { forwardRef, useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import {
    FocusScope,
    mergeProps,
    OverlayContainer,
    useButton,
    useDialog,
    useModal,
    useOverlay,
    useOverlayPosition,
    useOverlayTrigger,
} from 'react-aria';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@ui/atoms/Button';

const Popover = forwardRef((props, ref) => {
    const { children, style } = props;
    const { overlayProps } = useOverlay(props, ref);
    const { modalProps } = useModal();
    const { dialogProps } = useDialog(props, ref);

    return (
        <FocusScope restoreFocus>
            <div {...mergeProps(overlayProps, dialogProps, modalProps)} ref={ref} style={style}>
                {children}
            </div>
        </FocusScope>
    );
});

Popover.displayName = 'Popover';

const ToggleButton = styled(Button)`
    height: 2rem;
    width: 2rem;
    padding: 0.375rem;

    ${(p) =>
        p.isToggled &&
        css`
            background: var(--neutral100);
        `}
`;

const EmojiPickerButton = ({ editor, isDisabled }) => {
    const intl = useIntl();
    const triggerRef = useRef();
    const overlayRef = useRef();
    const state = useOverlayTriggerState({});
    const { triggerProps, overlayProps } = useOverlayTrigger({ type: 'dialog' }, state, triggerRef);
    const { overlayProps: positionProps } = useOverlayPosition({
        targetRef: triggerRef,
        overlayRef,
        placement: 'right top',
        offset: 4,
        isOpen: state.isOpen,
    });

    const { buttonProps } = useButton({ onPress: () => state.open() }, triggerRef);

    const onSelectEmoji = (emoji) => {
        state.close();
        editor.chain().focus().insertContent(emoji.native).run();
    };

    return (
        <>
            <ToggleButton
                {...buttonProps}
                {...triggerProps}
                ref={triggerRef}
                variant="secondary"
                startIcon={
                    <FontAwesomeIcon
                        icon={icon({
                            name: 'face-smile',
                            style: 'regular',
                        })}
                        size="sm"
                    />
                }
                isToggled={state.isOpen}
                isDisabled={isDisabled || !editor.isEditable}
            />
            {state.isOpen && (
                <OverlayContainer>
                    <Popover
                        {...overlayProps}
                        {...positionProps}
                        ref={overlayRef}
                        title={intl.formatMessage({ defaultMessage: 'Sélectionner un emoji' })}
                        isOpen={state.isOpen}
                        onClose={state.close}
                        isDismissable
                    >
                        <Picker
                            set="apple"
                            onSelect={onSelectEmoji}
                            perLine={7}
                            emoji="rocket"
                            title=""
                        />
                    </Popover>
                </OverlayContainer>
            )}
        </>
    );
};

export default EmojiPickerButton;
