import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import YearSelect from '../YearSelect';
import MonthSelect from '../MonthSelect';
import DaySelect from '../DaySelect';

// First of all, this function is inspired by this source https://developer.mozilla.org/fr/docs/Web/HTML/Element/Input/date
// More particularly this one https://developer.mozilla.org/fr/docs/Web/HTML/Element/Input/date#exemples
// Useful to know, it is recommended to use this format date YYYY-MM-DD
// So the source going into this function, is an array like this => [YYYY, MM, DD]

const DatePickerSelect = ({ date, onChangeDate }) => {
    const [datePickerSelect, setDatePickerSelect] = useState(
        dayjs(date).format('YYYY-MM-DD').split('-') || date.split('-'),
    );

    useEffect(() => onChangeDate(datePickerSelect.join('-')), [datePickerSelect, onChangeDate]);

    return (
        <DeprecatedPaper flex w="max-content">
            <DaySelect source={datePickerSelect} onChange={setDatePickerSelect} />
            <MonthSelect source={datePickerSelect} onChange={setDatePickerSelect} />
            <YearSelect source={datePickerSelect} onChange={setDatePickerSelect} />
        </DeprecatedPaper>
    );
};

export default DatePickerSelect;
