import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';

const PreviewButton = styled(Button)`
    background-color: var(--primary50);
    color: var(--primary);
    box-shadow: none;
`;

const PreviewButtonIcon = styled(FontAwesomeIcon)`
    color: inherit;
`;

const EpisodeEditFormNewsletterPreviewButton = (props) => {
    return (
        <PreviewButton
            startIcon={<PreviewButtonIcon icon={icon({ name: 'eye', style: 'solid' })} />}
            {...props}
        >
            <FormattedMessage defaultMessage="Aperçu de la newsletter" />
        </PreviewButton>
    );
};

export default EpisodeEditFormNewsletterPreviewButton;
