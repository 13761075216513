import Text from '@ui/atoms/Text';

const MaxLength = ({ currentValue, max, ...otherProps }) => {
    return (
        <Text
            color={currentValue > max ? '--alert500' : '--neutral500'}
            variant="footnote"
            textAlign="end"
            fontWeight="--fw-semibold"
            {...otherProps}
        >
            {`${currentValue}/${max}`}
        </Text>
    );
};

export default MaxLength;
