import styled from 'styled-components';
import EpisodeEditResume from '@app/molecules/EpisodeEditResume';
import EpisodeEditResumeQuotaAlert from '../EpisodeEditResumeQuotaAlert';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import EpisodeEditFormAccordion from '@app/organisms/EpisodeEditForm/EpisodeEditFormAccordion';
import EpisodeEditTranscription from '../EpisodeEditTranscription';
import { ENABLE_TRANSCRIPTION, USER_ROLES } from '@/utils/constants';
import { useEpisodeBeingEdited } from '../../organisms/EpisodeEditForm/EpisodeEditFormContext';
import EpisodeEditTranscriptionProvider from '../EpisodeEditTranscription/EpisodeEditTranscriptionProvider';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem 2rem;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        max-width: calc(var(--container-width) - 4rem);
    }
`;

const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        max-width: 25rem;
    }
`;

const Content = styled.div`
    flex: 1;
`;

const StickyContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

const EpisodeEdit = () => {
    const { data: subscription } = useSubscriptionQuery({ staleTime: 0 });
    const episode = useEpisodeBeingEdited();
    const hasAccessToTranscription =
        episode.show?.userOptions?.includes('transcription') && ENABLE_TRANSCRIPTION;

    return (
        <Container>
            <Sidebar>
                <StickyContainer>
                    {subscription?.isFreemium && episode.show?.userRole === USER_ROLES.OWNER && (
                        <EpisodeEditResumeQuotaAlert />
                    )}
                    <EpisodeEditResume />
                    {hasAccessToTranscription && (
                        <EpisodeEditTranscriptionProvider>
                            <EpisodeEditTranscription />
                        </EpisodeEditTranscriptionProvider>
                    )}
                </StickyContainer>
            </Sidebar>
            <Content>
                <EpisodeEditFormAccordion />
            </Content>
        </Container>
    );
};

export default EpisodeEdit;
