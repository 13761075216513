import { useParams } from 'react-router-dom';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import EpisodeEditForm from '@app/organisms/EpisodeEditForm/EpisodeEditForm';
import useChaptersQuery from '@queries/chapter/useChaptersQuery.hook';
import EpisodeEditFormContext from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import EpisodeEditFormPageSkeleton from '@app/pages/EpisodeEditFormPage/EpisodeEditFormPageSkeleton';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';

const EpisodeEditFormPage = () => {
    const { episodeId } = useParams();
    const episodeQuery = useEpisodeQuery(episodeId, { staleTime: 0 });
    const chaptersQuery = useChaptersQuery(episodeId);
    const { isLoading: isLoadingUser, error: errorUser } = useUserQuery();
    const { isLoading: isLoadingSubscription, error: errorSubscription } = useSubscriptionQuery();

    const isLoading = episodeQuery.isLoading || isLoadingUser || isLoadingSubscription;
    const isError = episodeQuery.error || errorUser || errorSubscription;

    if (isLoading) {
        return <EpisodeEditFormPageSkeleton />;
    }

    if (isError) {
        // TODO Handle error state
        return 'ERROR';
    }

    const { data: episode } = episodeQuery;
    const { data: chapters = [] } = chaptersQuery;

    return (
        <EpisodeEditFormContext episode={episode}>
            <EpisodeEditForm episode={episode} chapters={chapters} />
        </EpisodeEditFormContext>
    );
};

export default EpisodeEditFormPage;
