import PropTypes from 'prop-types';
import { Modal as AriaModal, ModalOverlay } from 'react-aria-components';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

const SIZES = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
};

const animationProps = {
    initial: { opacity: 0, translateY: 100 },
    animate: { opacity: 1, translateY: 0 },
    exit: { opacity: 0, translateY: 100 },
    transition: {
        type: 'spring',
        stiffness: 200,
        damping: 12,
    },
};

const Modal = ({ className, size, ...props }) => {
    return (
        <Overlay {...props}>
            <AnimatePresence>
                <ModalStyled {...props} {...animationProps} $size={size} className={className} />
            </AnimatePresence>
        </Overlay>
    );
};

const sizeMatch = {
    small: '400px',
    medium: '600px',
    large: '800px',
};
const Overlay = styled(ModalOverlay)`
    background: hsla(0, 0%, 0%, 0.5);
    position: fixed;
    z-index: 100;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
`;
const ModalStyled = styled(motion(AriaModal))`
    background: var(--white);
    border-radius: var(--r-l);
    width: 100%;
    max-width: ${(p) => sizeMatch[p.$size]};
    max-height: calc(100dvh - 2rem); /* dvh for mobile devices / 2rem = overlay padding */
`;

/*
    cf. react-aria Modal component
    https://react-spectrum.adobe.com/react-aria/Modal.html
 */
Modal.propTypes = {
    isDismissable: PropTypes.bool,
    isKeyboardDismissDisabled: PropTypes.bool,
    isOpen: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    style: PropTypes.object,
    size: PropTypes.oneOf(Object.values(SIZES)),
};

export default Modal;
