import { Label as AriaLabel, Text as AriaText } from 'react-aria-components';
import styled, { css } from 'styled-components';
import Text from '../Text';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

const Label = ({ children, isRequired, isOptional, description, tooltip, ...props }) => {
    if (!children) return null;

    return (
        <LabelWrapper {...props}>
            <LabelTooltipWrapper>
                <LabelStyled isRequired={isRequired}>
                    {children}
                    {isRequired && (
                        <Text as="span" fontWeight="--fw-semibold" color="--alert500">
                            *
                        </Text>
                    )}
                    {isOptional && (
                        <OptionalLabel as="span">
                            <FormattedMessage defaultMessage="(facultatif)" />
                        </OptionalLabel>
                    )}
                </LabelStyled>
                {tooltip}
            </LabelTooltipWrapper>
            {description && <Description slot="description">{description}</Description>}
        </LabelWrapper>
    );
};

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const LabelTooltipWrapper = styled.div`
    display: flex;
    column-gap: 2px;
    align-items: center;
    width: 100%;
`;
const LabelStyled = styled(AriaLabel)`
    font-size: var(--fs-body-m);
    font-weight: var(--fw-semibold);

    ${(p) =>
        p.isRequired &&
        css`
            display: flex;
            justify-content: start;
            align-items: start;
            column-gap: 0.25rem;
        `}
`;
const OptionalLabel = styled(Text)`
    padding-left: 0.25rem;
`;
const Description = styled(AriaText)`
    font-size: 12px;
    color: var(--neutral500);
`;

Label.propTypes = {
    children: PropTypes.node.isRequired,
    description: PropTypes.string,
    tooltip: PropTypes.node,
    isRequired: PropTypes.bool,
    isOptional: PropTypes.bool,
};

Label.defaultProps = {
    isRequired: false,
    isOptional: false,
};

export default Label;
