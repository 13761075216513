import { useEffect } from 'react';
import dayjs from 'dayjs';
import { formatDates, makeArray } from '../../../../helpers';
import SelectNative from '../SelectNative';

const getNumberOfDaysInMonth = (date) => dayjs(date, 'YYYY-MM').daysInMonth();

const populateDaysSelect = (day, daysInMonth) => {
    // Creating an array for <option> Tag values to string </option> e.g: ['01', '02', '03', '04', ...]
    const daysOption = makeArray(daysInMonth, 1).map((d) => d.toString().padStart(2, '0'));
    return daysOption.map((d) => (
        <option key={d} selected={d === day}>
            {d}
        </option>
    ));
};

const DaySelect = ({ onChange, source }) => {
    const [year, month, day] = source;

    useEffect(() => {
        if (!dayjs(source.join('-')).isValid()) onChange([year, month, '01']);
    }, [onChange, source]);

    return (
        <SelectNative
            label={formatDates('range', 'days')}
            name="day"
            source={populateDaysSelect(day, getNumberOfDaysInMonth(source))}
            onChange={(e) => onChange([year, month, e.target.value])}
        />
    );
};

export default DaySelect;
