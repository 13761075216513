import { useQuery } from '@tanstack/react-query';
import { fetchPodcast } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeKeys from '@/queries/episode/episodeKeys';
import Cookie from 'js-cookie';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });
    const token = Cookie.get('token');

    let audioUrlInternal;

    try {
        const url = new URL(transformedData.audioUrlInternal);
        url.searchParams.append('token', token);
        audioUrlInternal = url.toString();
    } catch (error) {
        audioUrlInternal = transformedData.audioUrl;
    }

    return {
        ...transformedData,
        audioUrlInternal,
        tags: transformedData.tags.data,
        files: transformedData.files.data,
        show: transformedData.show.data,
    };
};

const queryFn = async ({ queryKey: [{ episodeId }] }) => {
    const { data } = await fetchPodcast(episodeId);

    return transform(data);
};

const useEpisodeQuery = (episodeId, config) => {
    return useQuery({
        queryKey: episodeKeys.detailById(episodeId),
        queryFn,
        enabled: !!episodeId,
        ...config,
    });
};

export default useEpisodeQuery;
