import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import EpisodeEditTooltip from '@app/molecules/EpisodeEditTooltip';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import useEpisodeSlugQuery from '@queries/episodeSlug/useEpisodeSlugQuery.hook';
import { useDebounce } from '@hooks/useDebounce';
import { InputTextController } from '@/components/ui/atoms/InputText';
import styled from 'styled-components';
import Button from '@/components/ui/atoms/Button';

const EpisodeEditFormContentSlugField = ({ prefix }) => {
    const { control, watch, setValue } = useFormContext();
    const episode = useEpisodeBeingEdited();
    const [hasCustomSlug, setHasCustomSlug] = useState(episode.hasCustomSlug);
    const [isEditable, setIsEditable] = useState(false);
    const episodeName = watch('content.name');
    const debouncedEpisodeName = useDebounce(episodeName, 500);

    const { data: episodeSlug } = useEpisodeSlugQuery(
        { episodeId: episode.id, name: debouncedEpisodeName || episode.name },
        {
            enabled: !hasCustomSlug,
        },
    );

    useEffect(() => {
        if (episodeSlug?.slug) {
            setValue('content.slug', episodeSlug?.slug, { shouldValidate: true });
        }
    }, [episodeSlug]);

    return (
        <InputTextController
            name="content.slug"
            control={control}
            prefix={prefix}
            label={<FormattedMessage defaultMessage="Lien" />}
            copyToClipboardEnabled
            isRequired
            tooltip={
                <EpisodeEditTooltip>
                    <FormattedMessage defaultMessage="Ce lien remplacera le lien Ausha sur votre Smartlink, SmartPlayer, Newsletter, ainsi que lors de partages sur les réseaux sociaux." />
                </EpisodeEditTooltip>
            }
            onBlur={() => {
                setHasCustomSlug(true);
            }}
            isDisabled={!isEditable}
            customButton={
                !isEditable ? (
                    <NoPaddingButton
                        color="--primary"
                        variant="ghost"
                        onPress={() => setIsEditable(true)}
                    >
                        <FormattedMessage defaultMessage="Modifier" />
                    </NoPaddingButton>
                ) : null
            }
        />
    );
};

const NoPaddingButton = styled(Button)`
    padding: 0;
    overflow: visible;
`;

export default EpisodeEditFormContentSlugField;
