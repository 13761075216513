import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import { ReactComponent as validate } from '@public/icons/validate.svg';
import SubscriptionFeedback from '../molecules/SubscriptionFeedback';
import { fetchShowBySlug } from '@/api';
import Img from '../../ui/atoms/Img';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { parseQuery } from '../../../api/query';

const Unsubscribe = () => {
    const [showData, setShowData] = useState();
    const [emailQuery, setEmailQuery] = useState();

    useEffect(() => {
        window.history.pushState(
            { page: window.location.search },
            'unsubscribe page',
            decodeURIComponent(window.location.search),
        );

        const { show, email } = parseQuery(window.location.search);

        (async () => {
            setShowData((await fetchShowBySlug(show)).data);
        })();

        setEmailQuery(email);
    }, []);

    return (
        <SubscriptionFeedback>
            <DeprecatedPaper pos="relative" mb={25} w="max-content">
                <Img size={70} src={showData?.image_url} radius={4} />
                <DeprecatedIcon as={validate} size={40} pos="absolute" bottom={-20} right={-20} />
            </DeprecatedPaper>
            <DeprecatedText fontSize={36} mb={5} weight="bold" align="center">
                <FormattedMessage defaultMessage="Désinscription réussie." />
            </DeprecatedText>
            <DeprecatedText size="big" mb={30} align="center">
                <FormattedMessage
                    defaultMessage="Vous ne recevrez plus d’e-mail de la part de {showName} à l’adresse {emailQuery}"
                    values={{ emailQuery, showName: showData?.name }}
                />
            </DeprecatedText>
            <DeprecatedButton
                accent="--primary"
                maxW={400}
                w="100%"
                as="a"
                href={process.env.URL_AUSHA}
            >
                <FormattedMessage defaultMessage="Retour à l’accueil" />
            </DeprecatedButton>
        </SubscriptionFeedback>
    );
};

export default Unsubscribe;
