import { formatDates, makeArray } from '../../../../helpers';
import SelectNative from '../SelectNative';

const populateTimeMinutes = (source) => {
    const minutes = 60;
    // timePickerSelect is an array === [HH:mm]
    const currentType = source.split(':')[1];
    // Creating array, if timeType => hours === 24 else if minutes === 60 then convert to string e.g: [..., '12', '13', '14', ...]
    const timeOption = makeArray(minutes).map((m) => m.toString().padStart(2, '0'));
    return timeOption.map((time) => (
        <option key={time} selected={time === currentType}>
            {time}
        </option>
    ));
};

const MinuteSelect = ({ onChange, source }) => (
    <SelectNative
        label={formatDates('times', 'minute')}
        name="minute"
        source={populateTimeMinutes(source)}
        onChange={(e) => onChange(`${source.split(':')[0]}:${e.target.value}`)}
    />
);

export default MinuteSelect;
