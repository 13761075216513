import { QUOTA_TYPES } from '@/utils/constants';

const quotaKeys = {
    all: () => [{ entity: 'quota' }],
    allDetails: () => [{ ...quotaKeys.all()[0], scope: 'detail' }],
    detailByType: (type: keyof typeof QUOTA_TYPES) => [{ ...quotaKeys.allDetails()[0], type }],
    priceByType: (type: keyof typeof QUOTA_TYPES, quantity: number) => [
        { ...quotaKeys.detailByType(type)[0], scope: 'price', quantity },
    ],
};

export default quotaKeys;
