import { useQuery } from '@tanstack/react-query';
import { fetchShowTemplate } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import newsletterKeys from '@queries/newsletter/newsletterKeys';

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await fetchShowTemplate(showId, 'newsletter');

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useShowNewsletterTemplateQuery = (showId) => {
    return useQuery({
        queryKey: newsletterKeys.detailByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useShowNewsletterTemplateQuery;
