import Text from '../Text';
import styled from 'styled-components';
import { Dialog } from 'react-aria-components';
import PropTypes from 'prop-types';
import { UI_VARIANTS } from '@/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import Modal from '@ui/atoms/Modal';

const iconLookup = {
    [UI_VARIANTS.SUCCESS]: iconFA({ name: 'circle-check', style: 'solid' }),
    [UI_VARIANTS.WARNING]: iconFA({ name: 'triangle-exclamation', style: 'solid' }),
    [UI_VARIANTS.ALERT]: iconFA({ name: 'circle-exclamation', style: 'solid' }),
    [UI_VARIANTS.NEUTRAL]: iconFA({ name: 'circle-question', style: 'solid' }),
    [UI_VARIANTS.INFO]: iconFA({ name: 'circle-info', style: 'solid' }),
};

const DialogAlert = ({ title, children, action, variant, className, icon, ...props }) => {
    return (
        <ModalStyled size="small" {...props}>
            <DialogContainer className={className}>
                <IconWrapper variant={variant}>
                    {icon || <FontAwesomeIcon icon={iconLookup[variant]} />}
                </IconWrapper>
                <ContentWrapper>
                    {title && (
                        <Text variant="headingS" fontWeight="--fw-bold">
                            {title}
                        </Text>
                    )}
                    {children}
                </ContentWrapper>
                {action && <ActionWrapper>{action}</ActionWrapper>}
            </DialogContainer>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    min-width: 370px;
`;
const DialogContainer = styled(Dialog)`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
    padding: 1.5rem;
    width: 100%;
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: stretch;
    width: 100%;

    & > :last-child {
        align-self: center;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;

    & > * {
        text-align: center;
    }
`;
const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ variant }) => `var(--${variant}50)`};
    border-radius: var(--r-m);
    color: ${({ variant }) => `var(--${variant}500)`};
`;

DialogAlert.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
    action: PropTypes.node,
    variant: PropTypes.oneOf(Object.values(UI_VARIANTS)),
    isDismissable: PropTypes.bool,
    isKeyboardDismissDisabled: PropTypes.bool,
    isOpen: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    style: PropTypes.object,
    icon: PropTypes.node,
};

DialogAlert.defaultProps = {
    variant: UI_VARIANTS.WARNING,
    isDismissable: false,
};

export default DialogAlert;
