import {
    combineTransactionSteps,
    Extension,
    findChildrenInRange,
    getChangedRanges,
} from '@tiptap/react';
import { Plugin, PluginKey } from '@tiptap/pm/state';

interface ConfidenceUpdateExtensionOptions {
    nodeType: string; // Node type to target for confidence updates.
    confidenceLevel: number; // Confidence level to apply to changed nodes.
}

/**
 * An extension for Tiptap that updates the confidence attribute of word nodes
 * upon user modification, indicating full trust in the user's changes.
 */
const ImprovedConfidenceUpdateExtension = Extension.create<ConfidenceUpdateExtensionOptions>({
    name: 'confidenceUpdateExtension',

    addOptions() {
        return {
            nodeType: 'word',
            confidenceLevel: 1,
        };
    },

    addProseMirrorPlugins() {
        const { nodeType, confidenceLevel } = this.options;

        return [
            new Plugin({
                key: new PluginKey('confidenceUpdatePlugin'),
                /**
                 * Appends a transaction to the ProseMirror state to update the confidence
                 * level of word nodes after changes.
                 */
                appendTransaction: (transactions, oldState, newState) => {
                    // Check if any transaction has changed the document.
                    const docChanges =
                        transactions.some((transaction) => transaction.docChanged) &&
                        !oldState.doc.eq(newState.doc);

                    if (!docChanges) {
                        return; // No changes detected, no need to proceed.
                    }

                    const { tr } = newState;
                    // Combines all transactions to analyze the total changes made.
                    const transform = combineTransactionSteps(oldState.doc, [...transactions]);
                    // Determines the ranges within the document that have been changed.
                    const changes = getChangedRanges(transform);

                    changes.forEach(({ newRange }) => {
                        // Identifies and updates nodes within the changed ranges that match the specified node type.
                        // Ignores nodes that already have the expected confidence level.
                        findChildrenInRange(
                            newState.doc,
                            newRange,
                            (node) =>
                                node.type.name === nodeType &&
                                node.attrs.confidence !== confidenceLevel,
                        ).forEach(({ pos, node }) => {
                            // Updates the confidence attribute of the node.
                            tr.setNodeMarkup(pos, null, {
                                ...node.attrs,
                                confidence: confidenceLevel,
                            });
                        });
                    });

                    // If changes were made, return the transaction to apply them.
                    if (!tr.steps.length) {
                        return;
                    }

                    return tr;
                },
            }),
        ];
    },
});

export default ImprovedConfidenceUpdateExtension;
