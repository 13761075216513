import { computed, runInAction } from 'mobx';
import {
    fetchListenablePodcasts,
    fetchPlayerIframeCode,
    fetchPlayerSettings,
    updatePlayerSettings,
} from '@/api';
import Store from './Store';
import PlayerSettingsModel from '../models/PlayerSettingsModel';
import { apiFetch } from '../components/app/decorators/api';

class PlayerStore extends Store {
    static observables = {
        listenablePodcasts: [],
        settings: null,
        iframeCode: null,
    };

    @computed
    get listenablePodcastsSource() {
        const listenables = this.listenablePodcasts.map((p) => ({
            value: p.id,
            publicId: p.public_id,
            label: p.name,
        }));

        return listenables;
    }

    @apiFetch
    async fetchSettings(showId) {
        try {
            const { data } = await fetchPlayerSettings(showId);
            runInAction(() => {
                this.settings = new PlayerSettingsModel(this, data);
                // If, for some reason, only size unit is fetched by the API, the default width is not relevant
                // and need to be overrided
                if (this.settings.sizeUnit === 'px' && this.settings.width === 100) {
                    this.settings.width = 600;
                }
            });
        } catch (error) {
            runInAction(() => {
                this.settings = new PlayerSettingsModel(this);
            });
            throw error;
        }
    }

    @apiFetch
    async fetchListenablePodcasts(showId) {
        const { data } = await fetchListenablePodcasts(showId, { transformer: 'minimal' });
        runInAction(() => {
            this.listenablePodcasts = data;
        });
    }

    @apiFetch
    async fetchPlayerIframeCode(settings) {
        const code = await fetchPlayerIframeCode(settings);
        runInAction(() => {
            this.iframeCode = code;
        });
    }

    async updateSettings(showId, formData) {
        const { data } = await updatePlayerSettings(showId, formData);
        this.settings.updateData(data);
    }
}

export default PlayerStore;
