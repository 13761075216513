import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { reflex } from '@ui/reflex';
import Desktop from '@ui/molecules/Desktop';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import { ReactComponent as arrow } from '@public/icons/chevrondown.svg';
import { ReactComponent as check } from '@public/icons/check.svg';
import { ReactComponent as closeBack } from '@public/icons/close.svg';
import Portal from '@ui/molecules/Portal';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedInput from '@ui/atoms/DeprecatedInput';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DropContainer from '@ui/atoms/DropContainer';
import MenuItem from '@ui/atoms/MenuItem';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import useDroppable from '@/utils/hooks/useDroppable';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import { computeCssValue } from '@/utils/css';

const MenuItemStyled = styled(MenuItem)`
    min-height: 2.25rem;
    height: ${(p) => (p.height || p.h ? computeCssValue(p.height || p.h) : 'auto')};
`;

const DropAnimated = posed(DropContainer)({
    enter: { height: 'auto', opacity: 1, transition: { duration: 200 }, zIndex: 10 },
    exit: { height: 0, opacity: 0, transition: { duration: 200 }, zIndex: 10 },
});

const Wrapper = reflex(
    styled.div`
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        width: 100%;
        p {
            margin-top: 5px;
        }
        .drop-container {
            position: relative;
        }
    `,
);

const InputWrapper = styled.div`
    position: relative;
    background: white;
    input {
        cursor: pointer;
        background-color: transparent;
        z-index: 1;
        position: relative;
        padding-right: 1.625rem; // Additional padding to prevent long text overlap
    }
    svg {
        position: absolute;
        right: 10px;
        top: calc(50%);
        transform: translateY(-50%);
        fill: ${(p) => p.disabled && 'var(--neutral500)'};
        z-index: 0;
    }
    input:focus + svg {
        fill: var(--primary);
    }
`;

const DropDown = ({
    source,
    query,
    value,
    currentValue,
    label,
    onChange,
    field,
    addButton,
    ...props
}) => (
    <DropAnimated y={34} z={10} mt={8} maxH={188} bordered {...props}>
        {source
            .filter((s) => query === null || s.label.toLowerCase().includes(query.toLowerCase()))
            .map(({ value: val, label: lab, ...rest }) => (
                <MenuItemStyled
                    key={val}
                    {...rest}
                    onClick={() => (onChange || field.setValue)(val)}
                >
                    {currentValue === lab ? (
                        <DeprecatedPaper flex justify="space-between">
                            <DeprecatedText as="span" ellipsis weight="semibold" color="--primary">
                                {lab}
                            </DeprecatedText>
                            <DeprecatedIcon as={check} color="--primary" />
                        </DeprecatedPaper>
                    ) : (
                        lab
                    )}
                </MenuItemStyled>
            ))}
        {addButton}
    </DropAnimated>
);

function DeprecatedSelect({
    label,
    description,
    field = {},
    onChange,
    optional,
    tooltip,
    source,
    required,
    value,
    disabled,
    addButton,
    ...props
}) {
    const [ref, opened, open, close] = useDroppable();
    const [query, setQuery] = useState(null);
    const { label: currentLabel } =
        source.find((s) => s.value === (value === undefined ? field.value : value)) || {};

    useEffect(() => {
        setQuery(null);
    }, [currentLabel]);

    let currentValue;
    if (query === null) {
        currentValue = currentLabel;
    } else {
        currentValue = query;
    }

    return (
        <Wrapper ref={ref} {...props}>
            <Cluster $gap="0.25rem">
                <Text fontWeight="--fw-semibold" overflowWrap="nowrap" as="label">
                    {label}
                </Text>
                {required && (
                    <Text as="span" fontWeight="--fw-semibold" color="--primary">
                        *
                    </Text>
                )}
                {optional && (
                    <Text as="span">
                        <FormattedMessage defaultMessage="(facultatif)" />
                    </Text>
                )}
                {tooltip}
            </Cluster>
            {description && (
                <Text color="--neutral500" as="span">
                    {description}
                </Text>
            )}
            <div className="drop-container">
                <MobileOrTablet>
                    <DeprecatedButton w="100%" onClick={open} accent="secondary" fullWidth>
                        <DeprecatedPaper as="span" flex justify="flex-start">
                            <DeprecatedText mt={0} fontSize="16px">
                                {currentValue}
                            </DeprecatedText>
                        </DeprecatedPaper>
                        <DeprecatedIcon as={arrow} mr={0} />
                    </DeprecatedButton>
                    {opened && (
                        <Portal id="select">
                            <DropContainer z={1000} w="100vw" pos="fixed" y={0} x={0} minH="100vh">
                                <DeprecatedWhitePaper
                                    bb={1}
                                    rounded={false}
                                    pos="sticky"
                                    y={0}
                                    z={1001}
                                    px={30}
                                    py={20}
                                    flex
                                    align="center"
                                    style={{ transform: 'translateY(-7px)' }}
                                >
                                    <DeprecatedIcon
                                        as={closeBack}
                                        color="black"
                                        onClick={() => close()}
                                    />
                                </DeprecatedWhitePaper>
                                <DeprecatedPaper px={20}>
                                    {source
                                        .filter(
                                            (s) =>
                                                query === null ||
                                                s.label.toLowerCase().includes(query.toLowerCase()),
                                        )
                                        .map(({ value: val, label: lab, ...rest }) => (
                                            <MenuItem
                                                key={val}
                                                {...rest}
                                                onClick={() => {
                                                    close();
                                                    return (onChange || field.setValue)(val);
                                                }}
                                            >
                                                <DeprecatedText size="big" ellipsis>
                                                    {lab}
                                                </DeprecatedText>
                                            </MenuItem>
                                        ))}
                                </DeprecatedPaper>
                                {addButton}
                            </DropContainer>
                        </Portal>
                    )}
                </MobileOrTablet>
                <Desktop>
                    <InputWrapper disabled={disabled}>
                        <DeprecatedInput
                            onClick={open}
                            color={field.error ? 'error' : undefined}
                            type="select"
                            required={required}
                            value={currentValue}
                            onChange={(e) => setQuery(e.target.value)}
                            disabled={disabled}
                        />
                        <DeprecatedIcon as={arrow} />
                    </InputWrapper>
                    <PoseGroup>
                        {opened && (
                            <DropDown
                                key={1}
                                source={source}
                                query={query}
                                value={value}
                                currentValue={currentValue}
                                label={label}
                                onChange={onChange}
                                field={field}
                                addButton={addButton}
                            />
                        )}
                    </PoseGroup>
                </Desktop>
            </div>
            {field.error && (
                <DeprecatedText color="--alert" size="small">
                    {field.error}
                </DeprecatedText>
            )}
        </Wrapper>
    );
}

DeprecatedSelect.propTypes = {
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    required: PropTypes.bool,
    optional: PropTypes.bool,
    tooltip: PropTypes.node,
    source: PropTypes.arrayOf(PropTypes.object),
    field: PropTypes.object,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    description: PropTypes.node,
    addButton: PropTypes.node,
};

DeprecatedSelect.defaultProps = {
    source: [],
    optional: false,
    tooltip: undefined,
    field: undefined,
    value: undefined,
    onChange: null,
    disabled: false,
    required: false,
};

export default DeprecatedSelect;
