import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ReactComponent as clock } from '@public/icons/clock.svg';
import { ReactComponent as warning } from '@public/icons/warning.svg';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DatePicker from '../DatePicker';
import TimeInput from '../TimeInput';
import Tooltip from '@ui/atoms/Tooltip';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { formatDateInput } from '@/helpers';
import styled from 'styled-components';

const InputWrapper = styled.div`
    ${({ z }) => z && `z-index: ${z};`}
    width: 100%;
    display: flex;

    & input {
        height: 40px;
    }
`;

const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
};

const DateTimePicker = ({
    dateLabel,
    timeLabel,
    field,
    value,
    onChange,
    blockedNextDays = false,
    blockedPrevDays = false,
    warnTooltip = false,
    isDisabled,
    tooltip,
    ...props
}) => {
    const setValue = onChange || field.setValue;
    const val = (field ? field.value : value) || new Date();

    const [datePicker, onChangeDatePicker] = useState(formatDateInput(val));
    const [hoursTime, setHoursTime] = useState([dayjs(val).format('HH'), dayjs(val).format('mm')]);
    const [hours, minutes] = hoursTime;

    // Set tooltip Feedback
    const [isBeforeDateTooltip, setIsBeforeDateTooltip] = useState(false);

    const [isTypeText, setIsTypeText] = useState(true);

    useEffect(() => {
        if (warnTooltip) {
            setIsBeforeDateTooltip(dayjs().isBefore(val));
            warnTooltip(isBeforeDateTooltip);
        }
    }, [val, isBeforeDateTooltip]);

    useEffect(() => {
        const currentDate = dayjs(`${datePicker} ${hours}:${minutes}`).toDate();
        setValue(currentDate);
    }, [datePicker, hoursTime, isTypeText]);

    const setTime = (time) => {
        setHoursTime([dayjs(time).format('HH'), dayjs(time).format('mm')]);
        return setValue(time);
    };

    return (
        <>
            <AnimatePresence initial={false} exitBeforeEnter>
                {isBeforeDateTooltip && warnTooltip && (
                    <motion.div
                        intial="hidden"
                        animate={isBeforeDateTooltip && warnTooltip ? 'visible' : 'hidden'}
                        exit="hidden"
                        variants={variants}
                    >
                        <Tooltip mb={30} type="info" flex align="baseline">
                            <DeprecatedIcon as={warning} color="--alert" />
                            <DeprecatedText ml={10}>
                                <FormattedMessage
                                    defaultMessage="Pour planifier votre épisode, rendez-vous dans la section {scheduled}."
                                    values={{
                                        lineBreak: <br />,
                                        scheduled: (
                                            <DeprecatedText as="span" weight="bold">
                                                <FormattedMessage defaultMessage="Planifier" />
                                            </DeprecatedText>
                                        ),
                                    }}
                                />
                            </DeprecatedText>
                        </Tooltip>
                    </motion.div>
                )}
            </AnimatePresence>
            <InputWrapper {...props}>
                <DatePicker
                    setIsTypeText={setIsTypeText}
                    date={datePicker}
                    onChangeDate={onChangeDatePicker}
                    label={dateLabel}
                    blockedNextDays={blockedNextDays}
                    blockedPrevDays={blockedPrevDays}
                    disabled={isDisabled}
                    tooltip={tooltip}
                />
                <TimeInput
                    value={val}
                    onChange={setTime}
                    label={timeLabel}
                    leftIcon={<DeprecatedIcon as={clock} mr={10} />}
                    isTypeText={isTypeText}
                    disabled={isDisabled}
                    tooltip={tooltip}
                />
            </InputWrapper>
        </>
    );
};

export default observer(DateTimePicker);
