import { useState } from 'react';
import posed, { PoseGroup } from 'react-pose';
import Tooltip from '../../atoms/Tooltip';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';

const transition = { duration: 250 };

const arrowToPositions = {
    left: {
        x: '100%',
        y: '50%',
        xR: 0,
        yR: null,
        transX: '10px',
        transXExit: '20px',
        transY: '-50%',
        transYExit: '-50%',
    },
    right: {
        x: 0,
        y: '50%',
        xR: null,
        yR: null,
        transX: 'calc(-100% - 10px)',
        transXExit: 'calc(-100% - 20px)',
        transY: '-50%',
        transYExit: '-50%',
    },
    top: {
        x: '50%',
        y: '100%',
        xR: null,
        yR: null,
        transX: '-50%',
        transXExit: '-50%',
        transY: 10,
        transYExit: 20,
    },
    bottom: {
        x: '50%',
        y: 0,
        xR: null,
        yR: null,
        transX: '-50%',
        transXExit: '-50%',
        transY: 'calc(-100% - 10px)',
        transYExit: 'calc(-100% - 20px)',
    },
};

const TooltipWrapper = posed(DeprecatedPaper)({
    enter: { opacity: 1, y: (p) => p.position.transY, x: (p) => p.position.transX, transition },
    exit: {
        opacity: 0,
        y: (p) => p.position.transYExit,
        x: (p) => p.position.transXExit,
        transition,
    },
});

function TooltipHover({ target, arrow, flex, align, inline, w = null, ...props }) {
    const [hovered, setHovered] = useState(false);
    const position = arrowToPositions[arrow];

    return (
        <DeprecatedPaper
            w={w}
            z={null}
            flex
            pos="relative"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            css={
                inline
                    ? `
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 1px; // Hack to prevent height increase of parent
                    `
                    : null
            }
            className={props.className}
        >
            {target}
            <PoseGroup>
                {hovered && (
                    <TooltipWrapper
                        key={0}
                        pos="absolute"
                        w="max-content"
                        z={5}
                        position={position}
                        x={position.x}
                        xR={position.xR}
                        y={position.y}
                        yR={position.yR}
                        css="line-height: normal;"
                    >
                        <Tooltip arrow={arrow} type="help" border="--primary300" {...props} />
                    </TooltipWrapper>
                )}
            </PoseGroup>
        </DeprecatedPaper>
    );
}

TooltipHover.defaultProps = {
    arrow: 'left',
};

export default TooltipHover;
