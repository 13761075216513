import styled from 'styled-components';
import { reflex } from '@ui/reflex';

const DeprecatedCheckbox = styled.input.attrs({
    type: 'checkbox',
})`
    position: relative;
    vertical-align: sub;
    width: 15px;
    min-width: 15px;
    height: 15px;
    min-height: 15px;
    border-radius: var(--r-xxs);
    font-size: var(--fs-body);
    border: 1px solid var(--neutral500);
    appearance: none;
    cursor: pointer;
    background: white;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: var(--gradient-primary);
        transition: opacity 250ms;
    }

    &:checked {
        &:after {
            content: '';
            box-sizing: content-box;
            border: solid white;
            border-width: 0 2px 2px 0;
            width: 4px;
            height: 8px;
            text-align: center;
            line-height: 15px;
            font-size: 12px;
            position: absolute;
            color: white;
            background-size: 9px;
            background-repeat: no-repeat;
            background-position: center;
            left: 3px;
            top: 0;
            transform: rotate(45deg);
        }
        &:before {
            opacity: 1;
        }
        color: white;
        border-color: var(--primary);
    }
    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
`;

export default reflex(DeprecatedCheckbox);
