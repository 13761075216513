import { createContext } from 'react';
import PropTypes from 'prop-types';
import { useRadioGroup } from 'react-aria';
import { useRadioGroupState } from 'react-stately';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import styled from 'styled-components';

export const RadioContext = createContext(null);
const ORIENTATIONS = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};
const directionMatch = {
    [ORIENTATIONS.HORIZONTAL]: 'row',
    [ORIENTATIONS.VERTICAL]: 'column',
};

const InputsWrapper = styled.div`
    display: flex;
    flex-direction: ${(p) => directionMatch[p.orientation]};
    gap: ${(p) => p.gap || '0.5rem'};
    flex-wrap: wrap;
`;

const RadioGroup = ({ ...props }) => {
    const { children, label, description, errorMessage, validationState, orientation, gap } = props;
    const state = useRadioGroupState(props);
    const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } = useRadioGroup(
        props,
        state,
    );

    return (
        <Stack $gap="0.5rem" {...radioGroupProps}>
            {label && (
                <Text fontWeight="--fw-semibold" {...labelProps}>
                    {label}
                </Text>
            )}
            <InputsWrapper gap={gap} orientation={orientation}>
                <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
            </InputsWrapper>
            {description && (
                <div {...descriptionProps} style={{ fontSize: 12 }}>
                    {description}
                </div>
            )}
            {errorMessage && validationState === 'invalid' && (
                <div {...errorMessageProps} style={{ color: 'red', fontSize: 12 }}>
                    {errorMessage}
                </div>
            )}
        </Stack>
    );
};

RadioGroup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    label: PropTypes.node,
    description: PropTypes.node,
    errorMessage: PropTypes.node,
    validationState: PropTypes.object,
    defaultValue: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    isReadOnly: PropTypes.bool,
    isDisabled: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func,
    orientation: PropTypes.oneOf(Object.values(ORIENTATIONS)),
    gap: PropTypes.string,
    value: PropTypes.string,
};

RadioGroup.defaultProps = {
    orientation: ORIENTATIONS.VERTICAL,
};

export default RadioGroup;
