import {
    loginSharingProvider,
    fetchSharingProvider,
    fetchPosts,
    deletePost,
    fetchProviders,
    addPost,
    updatePost,
    fetchYoutubeQuota,
} from '@/api';
import { action, computed, runInAction } from 'mobx';
import {
    fetchShowTemplate,
    updateShowTemplate,
    unlinkSharing,
    uploadMessageImage,
    deleteUploadedMessageImage,
} from '@/api';
import { FormattedMessage } from 'react-intl';
import Store from './Store';
import { apiCall, apiFetch } from '../components/app/decorators/api';
import SharingAccountModel from '../models/SharingAccountModel';
import FacebookPageModel from '../models/FacebookPageModel';
import SharingTemplateModel from '../models/SharingTemplateModel';
import {
    fetchInsightsOfSocialMediaPosts,
    fetchSocialMediaPostsOfEpisode,
    updatePodcastNewsletterTemplate,
} from '@/api';
import YoutubeAccountModel from '../models/YoutubeAccountModel';
import SoundcloudAccountModel from '../models/SoundcloudAccountModel';
import InstagramAccountModel from '../models/InstagramAccountModel';
import PaginationModel from '../models/PaginationModel';
import LinkedInAccountModel from '../models/LinkedInAccountModel';
import {
    FACEBOOK,
    INSTAGRAM,
    LINKEDIN,
    SOCIAL_NETWORK_NAME,
    UI_VARIANTS,
} from '../utils/constants';
import SocialMediaInsightsModel from '../models/SocialMediaInsightsModel';
import YoutubeQuotaModel from '../models/YoutubeQuotaModel';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';

class AutoSharingStore extends Store {
    static observables = {
        // PROVIDERS CONNECTED
        facebookAccount: null,
        twitterAccount: null,
        linkedinAccount: null,
        youtubeAccount: null,
        soundcloudAccount: null,
        instagramAccount: null,

        // SPECIFIC PAYLOAD
        facebookPages: [],
        allInstagramAccounts: [],
        allLinkedInAccounts: [],

        // SHOWS TEMPLATE
        facebookShowTemplate: null,
        twitterShowTemplate: null,
        linkedinShowTemplate: null,
        instagramShowTemplate: null,
        newsletterShowTemplate: null,

        // PODCASTS TEMPLATE
        facebookPodcastTemplate: null,
        twitterPodcastTemplate: null,
        linkedinPodcastTemplate: null,
        instagramPodcastTemplate: null,
        newsletterPodcastTemplate: null,

        // UI modals Instagram
        isShownInstagramAccountModal: false,
        isShownInstagramErrorModal: false,

        // LinkedIn
        isShownLinkedInAccountModal: false,

        posts: [],
        pagination: {},

        youtubeQuota: {},

        postsByEpisode: {},
    };

    @computed
    get facebookPagesSource() {
        return this.facebookPages.map((p) => ({
            label: p.name,
            value: p.id,
        }));
    }

    @computed
    get connectedProviders() {
        const providers = [];
        if (this.instagramAccount) providers.push('instagram');
        if (this.facebookAccount) providers.push('facebook');
        if (this.linkedinAccount) providers.push('linkedin');
        if (this.twitterAccount) providers.push('twitter');
        return providers;
    }

    // UI
    @action
    setAccountsInstagramModal(bool) {
        this.isShownInstagramAccountModal = bool;
    }
    @action
    setErrorInstagramModal(bool) {
        this.isShownInstagramErrorModal = bool;
    }
    @action
    setAccountsLinkedInModal(bool) {
        this.isShownLinkedInAccountModal = bool;
    }

    // API
    @apiCall
    async loginWith(formData, showId) {
        const data = await loginSharingProvider(showId, formData);

        switch (formData.provider) {
            case 'instagram':
                if (data.axios.data.length > 1) {
                    this.isShownInstagramAccountModal = true;
                    await runInAction(() => {
                        this.allInstagramAccounts = data.axios.data.map(
                            (account) => new InstagramAccountModel(this, account),
                        );
                    });
                } else {
                    await updateShowTemplate(showId, formData.provider, {
                        params: { user_id: data[0].id },
                    });
                    runInAction(() => {
                        this.updateAccount(formData.provider, { account: data[0] });
                    });
                }
                break;
            case 'linkedin':
                if (data.axios.data.length > 1) {
                    this.isShownLinkedInAccountModal = true;
                    await runInAction(() => {
                        this.allLinkedInAccounts = data.axios.data.map(
                            (account) => new LinkedInAccountModel(this, account),
                        );
                    });
                } else {
                    await updateShowTemplate(showId, formData.provider, {
                        params: { account_id: data[0].id },
                    });
                    runInAction(() => {
                        this.updateAccount(formData.provider, { account: data[0] });
                    });
                }
                break;
            default:
                this.updateAccount(formData.provider, data);
        }
        if (this[`${formData.provider}Account`])
            modalToastQueue.add(
                <FormattedMessage
                    defaultMessage="Vous êtes connecté à votre compte {provider}"
                    values={{ provider: SOCIAL_NETWORK_NAME[formData.provider] }}
                />,
                {
                    variant: UI_VARIANTS.SUCCESS,
                    timeout: 5000,
                },
            );
        else
            modalToastQueue.add(
                <FormattedMessage
                    defaultMessage="Oups, vous n'avez pas pu connecter votre compte {provider}"
                    values={{ provider: SOCIAL_NETWORK_NAME[formData.provider] }}
                />,
                {
                    variant: UI_VARIANTS.ALERT,
                    timeout: 5000,
                },
            );
    }

    @apiFetch
    async fetchPosts(showId, page = 1, perPage = 10) {
        if (page !== 1 && this.posts.length === 0) page = 1;
        try {
            const { data: posts, meta } = await fetchPosts(showId, page, perPage);
            const postIds = posts.map((post) => post.id);
            const { data: insights } =
                postIds.length > 0 ? await fetchInsightsOfSocialMediaPosts(postIds) : [];
            runInAction(() => {
                this.posts = posts.map((post) => new SharingTemplateModel(this, post));
                this.pagination = new PaginationModel(this, meta.pagination);

                const insightsCollection = insights.map(
                    (postInsights) => new SocialMediaInsightsModel(this, postInsights),
                );
                this.posts.forEach((post) => {
                    if (post.id) {
                        const matchingInsights = insightsCollection.find(
                            (postInsights) => postInsights.messageId === post.id,
                        );
                        if (matchingInsights) {
                            post.insights = matchingInsights;
                        }
                    }
                });
            });
        } catch (error) {
            runInAction(() => {
                this.posts = [];
            });
        }
    }

    @apiFetch
    async fetchSocialMediaPostsOfEpisode(episodeId) {
        try {
            const { data: posts } = await fetchSocialMediaPostsOfEpisode(episodeId);
            runInAction(() => {
                this.postsByEpisode[episodeId] = posts.map(
                    (post) => new SharingTemplateModel(this, post),
                );
            });
        } catch (error) {
            runInAction(() => {
                this.postsByEpisode[episodeId] = [];
            });
        }
    }

    @apiFetch
    async fetchSharingProvider(showId, provider) {
        try {
            const data = await fetchSharingProvider(showId, provider);
            this.updateAccount(provider, { ...data });
        } catch (e) {
            this[`${provider}Account`] = null;
        }
    }

    @apiFetch
    async fetchProviders(showId) {
        const data = await fetchProviders(showId);
        runInAction(() => {
            if (data.instagram) this.updateAccount('instagram', data.instagram);
            if (data.facebook) this.updateAccount('facebook', data.facebook);
            if (data.twitter) this.updateAccount('twitter', data.twitter);
            if (data.linkedin) this.updateAccount('linkedin', data.linkedin);
        });
    }

    @apiFetch
    async fetchShowTemplate(showId, provider) {
        try {
            const { data } = await fetchShowTemplate(showId, provider);
            runInAction(() => {
                this[`${provider}ShowTemplate`] = new SharingTemplateModel(this, data);
            });
        } catch (error) {
            runInAction(() => {
                this[`${provider}ShowTemplate`] = new SharingTemplateModel(this);
            });
        }
    }

    @apiFetch
    async fetchYoutubeQuota() {
        try {
            const { data } = await fetchYoutubeQuota();
            runInAction(() => {
                this.youtubeQuota = new YoutubeQuotaModel(this, data);
            });
        } catch (error) {
            runInAction(() => {
                this.youtubeQuota = new YoutubeQuotaModel(this);
            });
        }
    }

    @apiCall
    async createPost(template, provider, podcastId, formData) {
        let params;
        const facebookPage = this.facebookPages?.find(
            (page) => page.id === this.facebookShowTemplate?.pageId,
        );
        switch (provider) {
            case FACEBOOK:
                params = { params: { page_id: formData.page_id || facebookPage.id } } || {};
                break;
            case INSTAGRAM:
                params =
                    { params: { user_id: formData.user_id || this.instagramAccount.id } } || {};
                break;
            case LINKEDIN:
                params =
                    { params: { account_id: formData.account_id || this.linkedinAccount.id } } ||
                    {};
                break;
            default:
                params = {};
        }

        const { data } = await addPost(
            podcastId,
            provider,
            Object.assign(
                {
                    message_tpl: `${template?.text}`,
                    type: formData.type,
                    clip_format_id: formData.clip_format_id,
                    publish_at: formData.publish_at,
                    state: formData.state,
                },
                params,
            ),
        );
        template.updateData(data);
        return new SharingTemplateModel(this, data);
    }

    @apiCall
    async updatePost(template, postId, formData) {
        const { data } = await updatePost(
            postId,
            Object.assign({
                message_tpl: formData.message_tpl,
                type: formData.type,
                clip_format_id: formData.clip_format_id,
                publish_at: formData.publish_at,
                state: formData.state,
            }),
        );
        template.updateData(data);
        return new SharingTemplateModel(this, data);
    }

    @apiCall
    async deletePost(postId) {
        try {
            await deletePost(postId);
            runInAction(() => {
                this.posts = this.posts.filter((i) => i.id !== postId);
                modalToastQueue.add(<FormattedMessage defaultMessage="Le post a été supprimé" />, {
                    variant: UI_VARIANTS.SUCCESS,
                    timeout: 5000,
                });
            });
        } catch (error) {
            modalToastQueue.add(<FormattedMessage defaultMessage="Le post est déjà publié" />, {
                variant: UI_VARIANTS.ALERT,
                timeout: 5000,
            });
        }
    }

    @apiCall
    async updateShowTemplate(template, provider, showId, formData) {
        let providerTemplate;
        switch (provider) {
            case FACEBOOK:
                providerTemplate = { params: { page_id: formData.page_id } } || {};
                break;
            case INSTAGRAM:
                providerTemplate =
                    { params: { user_id: formData.id || this.instagramAccount.id } } || {};
                break;
            case LINKEDIN:
                providerTemplate =
                    { params: { account_id: formData.account_id || this.linkedinAccount.id } } ||
                    {};
                break;
            default:
                providerTemplate = { params: { activated: formData.activated } };
        }
        const { data } = await updateShowTemplate(
            showId,
            provider,
            Object.assign({ message_tpl: formData.message_tpl }, providerTemplate),
        );
        template.updateData(data);
    }

    @apiCall
    async updatePodcastNewsletterTemplate(template, podcastId, formData) {
        const providerTemplate = {
            params: { activated: formData.activated, title: formData.title },
        };
        const { data } = await updatePodcastNewsletterTemplate(
            podcastId,
            Object.assign(
                {
                    message_tpl: formData.message_tpl,
                    type: formData.type,
                    clip_format_id: formData.clip_format_id,
                    publish_at: formData.publish_at,
                    page_id: template.page_id,
                    state: formData.state,
                },
                providerTemplate,
            ),
        );
        template.updateData(data);
    }

    @apiCall
    async unlinkSharing(showId, provider) {
        await unlinkSharing(showId, provider);
        runInAction(() => {
            this[`${provider}Account`] = null;
        });
    }

    @apiCall
    async uploadMessageImage(messageId, file) {
        return uploadMessageImage(messageId, file);
    }

    @apiCall
    async deleteUploadedMessageImage(messageId) {
        await deleteUploadedMessageImage(messageId);
    }

    @action
    updateAccount(provider, { account, pages }) {
        let providerAccount;
        switch (provider) {
            case 'youtube':
                providerAccount = new YoutubeAccountModel(this, account);
                break;
            case 'soundcloud':
                providerAccount = new SoundcloudAccountModel(this, account);
                break;
            case 'instagram':
                providerAccount = new InstagramAccountModel(this, account);
                break;
            case 'linkedin':
                providerAccount = new LinkedInAccountModel(this, account);
                break;
            default:
                providerAccount = new SharingAccountModel(this, account);
        }
        this[`${provider}Account`] = providerAccount;

        if (provider === 'facebook') {
            this.facebookPages = pages.map((page) => new FacebookPageModel(this, page));
        }
    }
}

export default AutoSharingStore;
