import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
    Button as AriaButton,
    DialogTrigger,
    Popover,
    Separator as AriaSeparator,
} from 'react-aria-components';
import styled from 'styled-components';
import RadioGroup from '@/components/ui/molecules/RadioGroup';
import InputRadio from '@/components/ui/atoms/InputRadio';
import Button from '@/components/ui/atoms/Button';
import TranscriptionAddSpeakerModal from './TranscriptionAddSpeakerModal';
import TranscriptionSpeakerNameMessage from '@app/molecules/TranscriptionEditor/TranscriptionSpeaker/TranscriptionSpeakerNameMessage';
import { TranscriptionSpeakerFormSchema } from './TranscriptionSpeakerForm/useTranscriptionSpeakerFormSchema.hook';
import TranscriptionEditSpeakerModal from './TranscriptionEditSpeakerModal';
import Text from '@/components/ui/atoms/Text';
import {
    SPEAKER_ACTION,
    useSpeakers,
} from '@app/molecules/TranscriptionEditor/TranscriptionSpeakersProvider';

interface TranscriptionSpeakerProps {
    speakerId: number | null;
    setSpeaker: (speaker: number) => void;
    deleteSpeakerForAllParagraphs: (speaker: number) => void;
}

const TranscriptionSpeaker = ({
    speakerId,
    setSpeaker,
    deleteSpeakerForAllParagraphs,
}: TranscriptionSpeakerProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedSpeaker, setSelectedSpeaker] = useState<number | undefined>();
    const { speakers, dispatch } = useSpeakers();

    const handleAddSpeaker = ({ name }: TranscriptionSpeakerFormSchema) => {
        dispatch({
            type: SPEAKER_ACTION.ADD_SPEAKER,
            name,
        });
        setIsAddModalOpen(false);
    };

    const handleEditSpeaker = ({ name }: TranscriptionSpeakerFormSchema) => {
        if (selectedSpeaker === null || selectedSpeaker === undefined) {
            return;
        }

        dispatch({
            type: SPEAKER_ACTION.RENAME_SPEAKER,
            id: selectedSpeaker,
            name,
        });
        setIsEditModalOpen(false);
        setSelectedSpeaker(undefined);
    };

    const handleDeleteSpeaker = () => {
        if (selectedSpeaker === null || selectedSpeaker === undefined) {
            return;
        }

        dispatch({
            type: SPEAKER_ACTION.DELETE_SPEAKER,
            id: selectedSpeaker,
        });
        deleteSpeakerForAllParagraphs(selectedSpeaker);
        setIsEditModalOpen(false);
        setSelectedSpeaker(undefined);
    };

    const handleChangeSpeaker = (speakerId: number) => {
        setSpeaker(speakerId);
        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    return (
        <>
            {/* contentEditable={false} prevents Tiptap to move cursor on this component */}
            <SpeakerContainer contentEditable={false}>
                <SpeakerName fontWeight="--fw-bold" numberOfLines={1}>
                    <TranscriptionSpeakerNameMessage speakerId={speakerId} />
                </SpeakerName>
                <DialogTrigger>
                    <MenuTriggerButton aria-label="Menu" onPress={() => setIsMenuOpen(true)}>
                        <EllipsisIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />
                    </MenuTriggerButton>
                    <MenuPopover
                        placement="bottom end"
                        isOpen={isMenuOpen}
                        onOpenChange={setIsMenuOpen}
                    >
                        <RadioGroup
                            defaultValue={speakerId ?? ''}
                            onChange={handleChangeSpeaker}
                            gap="0px"
                        >
                            <SpeakerInputRadio value="">
                                <FormattedMessage defaultMessage="Aucun orateur" />
                            </SpeakerInputRadio>
                            <Separator />
                            {Array.from(speakers.keys()).map((id) => (
                                <SpeakerInputRadio key={id} value={id}>
                                    <SpeakerName numberOfLines={1}>
                                        <TranscriptionSpeakerNameMessage speakerId={id} />
                                    </SpeakerName>
                                    <EditButton
                                        onPress={() => {
                                            setSelectedSpeaker(id);
                                            setIsEditModalOpen(true);
                                        }}
                                    >
                                        <EditIcon
                                            icon={icon({ name: 'pen-line', style: 'solid' })}
                                        />
                                    </EditButton>
                                </SpeakerInputRadio>
                            ))}
                        </RadioGroup>
                        {/* @ts-ignore */}
                        <AddButton
                            onPress={() => setIsAddModalOpen(true)}
                            startIcon={<AddIcon icon={icon({ name: 'add', style: 'solid' })} />}
                        >
                            <FormattedMessage defaultMessage="Ajouter un orateur" />
                        </AddButton>
                    </MenuPopover>
                </DialogTrigger>
            </SpeakerContainer>
            <TranscriptionAddSpeakerModal
                isOpen={isAddModalOpen}
                onOpenChange={setIsAddModalOpen}
                onSubmit={handleAddSpeaker}
            />
            <TranscriptionEditSpeakerModal
                isOpen={isEditModalOpen}
                onOpenChange={setIsEditModalOpen}
                onSubmit={handleEditSpeaker}
                onDelete={handleDeleteSpeaker}
                speakerName={speakers.get(selectedSpeaker as number) ?? ''}
            />
        </>
    );
};

const MenuTriggerButton = styled(AriaButton)`
    visibility: hidden;
    padding: 0;
    background: none;
    border: none;
    pointer-events: none;
`;
const SpeakerContainer = styled.div`
    position: absolute;
    width: 10rem;
    right: calc(100% + 2rem);
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    &:hover > ${MenuTriggerButton} {
        visibility: visible;
        pointer-events: auto;
        cursor: pointer;
    }
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const MenuPopover = styled(Popover)`
    z-index: 100 !important; // override react-aria style which defaults to 10000
    width: 100%;
    max-width: 10rem;
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
    border-radius: var(--r-s);
    background-color: var(--white);
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;
const Separator = styled(AriaSeparator)`
    height: 1px;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    width: calc(100% - 1rem);
    margin-block: 0.25rem;
    align-self: center;
    pointer-events: none;
`;
const EditIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const EditButton = styled(AriaButton)`
    visibility: hidden;
    background: none;
    border: none;
    padding: O;
    flex-shrink: 0;
`;
const SpeakerInputRadio = styled(InputRadio)`
    padding: 0.5rem;
    min-width: 0;
    width: 100%;

    &:hover > ${EditButton} {
        visibility: visible;
        cursor: pointer;
    }
`;
const AddIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--primary);
`;
const AddButton = styled(Button)`
    background-color: var(--primary50);
    color: var(--primary);
    box-shadow: none;
    padding: 0.5rem 0.75rem;

    & > span {
        font-size: var(--fs-body-s);
        line-height: var(--lh-body-s);
    }
`;
const SpeakerName = styled(Text)`
    min-width: 0;
`;

export default TranscriptionSpeaker;
