import { computed } from 'mobx';
import ShowModel from './ShowModel';
import Model from './Model';
import { array, dateUTC } from './resolvers';
import { apiCall } from '../components/app/decorators/api';

class PageSettingsModel extends Model {
    static attributes = {
        id: 'id',
        showId: 'show_id',
        isPublished: 'is_published',
        color: 'color',
        isMultiShowEnabled: 'multi_show',
        recommendedShows: array('recommendedShows', ShowModel, 'showStore'),
        twitterAccount: 'twitter_account',
        updatedAt: dateUTC('updated_at'),
        createdAt: dateUTC('created_at'),
        googleAnalytics: 'google_analytics',
        facebookPixel: 'facebook_pixel',
        coverPageUrl: 'cover_page_url',
        displayTranscript: 'display_transcript',
    };

    @apiCall
    async update(formData) {
        return this.store.updateSettings(this, formData);
    }

    @computed
    get show() {
        // For some reason, `this.showId` can be undefined. In this case, we fetch again the show ID in the route.
        // This use case happens when the user never configured the podcast page : no API data is fetched and the
        // PageSettingsModel is constructed with the default value.
        // TODO: Identify why `state.routerStore.params.showId` in the default value is undefined at first page loading
        return this.state.showStore.byId(this.showId || this.state.routerStore.params.showId);
    }
}

export default PageSettingsModel;
