import { useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import { LINKS_NAMES } from '../organisms/CTAAccessAllowed';
import { connect } from '../decorators/connect';
import DeprecatedCheckbox from '../../ui/atoms/DeprecatedCheckbox';
import useSmartlinkSettingsQuery from '@/queries/smartlinkSetting/useSmartlinkSettingsQuery.hook';
import { useParams } from 'react-router-dom';

// input type checkbox has default style, to remove this parameter we need to correct it in GlobalStyle
// If we do it, you must to check where all DeprecatedCheckbox component is use
const CheckBox = styled(DeprecatedCheckbox)`
    margin-right: 0 !important;
`;

const enhance = connect(({ autoSharingStore, pageSettingsStore }) => {
    const settings = pageSettingsStore.settings;
    const newsletterTemplate = autoSharingStore.newsletterShowTemplate;

    return {
        urlsAllowed: {
            [LINKS_NAMES.PAGE_PODCAST]: settings?.isPublished,
            [LINKS_NAMES.SMARTLINK]: false, // Will be computed in the component, using react query
            [LINKS_NAMES.NEWSLETTER]: newsletterTemplate?.activated,
            [LINKS_NAMES.PLAYER]: true,
        },
    };
});

const CTALocationsCheckbox = ({ checkboxItem, field, type, urlsAllowed }) => {
    const ref = useRef(null);
    const { showId } = useParams();
    const { data: smartlinkSettings } = useSmartlinkSettingsQuery(showId);

    // Update this param based on fetched smartlink settings
    urlsAllowed[LINKS_NAMES.SMARTLINK] = smartlinkSettings?.isPublished ?? false;

    useEffect(() => {
        if (ref.current) ref.current.checked = field.value.includes(checkboxItem.value);
    }, [ref.current]);

    const addLocations = (elt) => field.setValue([...field.value, elt]);
    const removeLocations = (elt) => field.setValue(field.value.filter((loc) => loc !== elt));

    const selectedLocations = useCallback(
        (elt, checked) => {
            if (checked) return addLocations(elt);
            return removeLocations(elt);
        },
        [field.value],
    );

    return (
        <DeprecatedText gap={10} as="label" for={checkboxItem.value} flex align="center" pointer>
            <CheckBox
                ref={ref}
                key={checkboxItem.value}
                id={checkboxItem.value}
                name={checkboxItem.value}
                onChange={(e) => selectedLocations(checkboxItem.value, e.target.checked)}
                disabled={!urlsAllowed[checkboxItem.value] || type === checkboxItem.value}
                required={field.value.length === 0}
            />
            <DeprecatedText
                disabled={!urlsAllowed[checkboxItem.value] || type === checkboxItem.value}
            >
                {checkboxItem.label}
            </DeprecatedText>
        </DeprecatedText>
    );
};

export default enhance(CTALocationsCheckbox);
