import showKeys from '@/queries/show/showKeys';
import { useQuery } from '@tanstack/react-query';
import { fetchShows } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async () => {
    const { data, meta } = await fetchShows();

    const result = {
        shows: data,
        pagination: meta.pagination,
    };

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(result, { deep: true });
};

const useShowsQuery = () => {
    return useQuery({
        queryKey: showKeys.list(),
        queryFn,
    });
};

export default useShowsQuery;
