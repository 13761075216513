import { FormattedMessage } from 'react-intl';
import { connect } from '@app/decorators/connect';
import { AUSHA_CLUB_URL, ENABLE_AUSHA_CLUB_BUTTON } from '@/utils/constants';
import UiKitTooltip from '@ui/atoms/UiKitTooltip/UiKitTooltip';
import AushaClubText from '@public/images/ausha-club-text.svg';
import PurpleHeart from '@public/images/purple-heart.png';
import StaticPurpleHeart from '@public/images/static-purple-heart.png';
import styled, { css } from 'styled-components';
import { sendAmplitudeLogEvent } from '@/helpers';

const enhance = connect(({ userStore, subscriptionStore }) => ({
    user: userStore.user,
    isSubscriptionActive: subscriptionStore.subscription?.active,
}));

const AushaClubButton = ({ user, isSubscriptionActive, onClick }) => {
    if (!ENABLE_AUSHA_CLUB_BUTTON || user?.languageCode !== 'fr') return null;

    const Content = (
        <>
            <ClubIcon animatedSrc={PurpleHeart} staticSrc={StaticPurpleHeart} />
            <ClubText src={AushaClubText} alt="Club" />
        </>
    );

    const handleClick = () => {
        sendAmplitudeLogEvent('Clicked on Club Ausha Icon');
        onClick();
    };

    return (
        <UiKitTooltip
            variant="dark"
            content={<FormattedMessage defaultMessage="Communauté de Podcasteurs" />}
            position="left"
        >
            {isSubscriptionActive ? (
                <LinkClubAushaStyled
                    animatedSrc={PurpleHeart}
                    href={AUSHA_CLUB_URL}
                    onClick={() => sendAmplitudeLogEvent('Clicked on Club Ausha Icon')}
                >
                    {Content}
                </LinkClubAushaStyled>
            ) : (
                <ButtonClubAushaStyled animatedSrc={PurpleHeart} onClick={handleClick}>
                    {Content}
                </ButtonClubAushaStyled>
            )}
        </UiKitTooltip>
    );
};

const ClubIcon = styled.div`
    height: 1.25rem;
    width: 1.25rem;
    background-image: url(${({ staticSrc }) => staticSrc});
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
`;

const ClubText = styled.img`
    ${(p) => p.theme.mediaQueries.mobile} {
        display: none;
    }
`;

const sharedClubAusha = css`
    background: var(--gradient-dawn);
    display: flex;
    align-items: center;
    gap: 0.25rem;
    height: 100%;
    padding: 0.5rem 0.75rem;
    border-radius: var(--r-s);

    &:hover ${ClubIcon} {
        background-image: url(${({ animatedSrc }) => animatedSrc});
    }

    ${(p) => p.theme.mediaQueries.mobile} {
        height: 24px;
        min-width: 24px;
    }
`;

const ButtonClubAushaStyled = styled.button`
    ${sharedClubAusha};
    border: none;
    cursor: pointer;
`;

const LinkClubAushaStyled = styled.a`
    ${sharedClubAusha};
`;

export default enhance(AushaClubButton);
