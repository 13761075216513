import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { InputTextController } from '@ui/atoms/InputText';
import EpisodeEditTooltip from '@app/molecules/EpisodeEditTooltip';
import styled from 'styled-components';

const HelpLink = styled.a`
    color: var(--white);
    text-decoration: underline;
`;

const EpisodeEditFormContentNameField = () => {
    const intl = useIntl();
    const { control } = useFormContext();

    return (
        <InputTextController
            name="content.name"
            control={control}
            label={<FormattedMessage defaultMessage="Titre" />}
            placeholder={intl.formatMessage({
                defaultMessage: 'Le super titre de votre épisode',
            })}
            isRequired
            tooltip={
                <EpisodeEditTooltip>
                    <FormattedMessage
                        defaultMessage="Choisissez un nom impactant qui révèle en quelques mots pourquoi il faut écouter votre épisode. <link>En savoir plus</link>"
                        values={{
                            link: (chunks) => (
                                <HelpLink
                                    href={intl.formatMessage({
                                        defaultMessage:
                                            'https://www.ausha.co/fr/blog/nouveau-sublimez-les-descriptions-de-vos-podcasts/',
                                    })}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {chunks}
                                </HelpLink>
                            ),
                        }}
                    />
                </EpisodeEditTooltip>
            }
            data-testid="new-episode-name-input"
        />
    );
};

export default EpisodeEditFormContentNameField;
