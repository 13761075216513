import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import Form from '../Form';
import CallToActionForm from '../../../forms/CallToActionForm';
import { connect } from '../decorators/connect';
import CallToActionModel from '../../../models/CallToActionModel';
import { LINKS_NAMES } from '../organisms/CTAAccessAllowed';
import CTAContentButtonModal from '../organisms/CTAContentButtonModal';

const enhance = connect(({ routerStore, callToActionStore }, { onClose, state }) => {
    const show = routerStore.resolve('showId');

    const defaultFields = [
        LINKS_NAMES.PAGE_PODCAST,
        LINKS_NAMES.SMARTLINK,
        LINKS_NAMES.NEWSLETTER,
        LINKS_NAMES.CROWDFUNDING,
    ];
    const objDefaultFields = defaultFields.includes(state.item.type)
        ? { text: state.item.text, url: state.item.url }
        : {};

    return {
        form: () =>
            CallToActionForm(
                new CallToActionModel(callToActionStore.callToActionButtons),
                {},
                { ...objDefaultFields },
            ),
        submitAddCallToAction: async (formData) => {
            await callToActionStore.addCallToAction(show.id, formData);
            onClose();
        },
        isLoading: callToActionStore.addCallToAction.pending,
    };
});

const CTACreateButtonModal = ({
    isOpened,
    onClose,
    state,
    submitAddCallToAction,
    form,
    checkboxList,
    isLoading,
}) => (
    <DeprecatedModal
        isOpened={isOpened}
        onClose={onClose}
        w={600}
        title={<FormattedMessage defaultMessage="Ajouter un nouveau bouton" />}
    >
        <Form
            form={form}
            onSubmit={(formData) =>
                submitAddCallToAction(Object.assign(formData, { type: state.item.type }))
            }
        >
            {({ fields, hasChanged }) => (
                <CTAContentButtonModal
                    fields={fields}
                    hasChanged={hasChanged}
                    isLoading={isLoading}
                    checkboxList={checkboxList}
                    state={state}
                    onClose={onClose}
                />
            )}
        </Form>
    </DeprecatedModal>
);

export default enhance(CTACreateButtonModal);
