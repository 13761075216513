import Text from '../Text';
import styled from 'styled-components';
import { Dialog as AriaDialog } from 'react-aria-components';
import PropTypes from 'prop-types';
import IconButton from '../IconButton';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@ui/atoms/Modal';
import Stack from '../../layout/Stack';

const Dialog = ({ title, children, action, className, disableScroll, description, ...props }) => {
    return (
        <Modal {...props}>
            <DialogContainer className={className}>
                {({ close }) => (
                    <>
                        <DialogHeader>
                            <Stack $gap="0.25rem">
                                {title && (
                                    <Text variant="headingS" fontWeight="--fw-bold">
                                        {title}
                                    </Text>
                                )}
                                {description && <Text color="--neutral500">{description}</Text>}
                            </Stack>
                            {props.hasCloseButton && (
                                <CloseButton
                                    icon={<CloseIcon icon={icon({ name: 'xmark-large' })} />}
                                    onPress={close}
                                    variant="ghost"
                                    size="small"
                                />
                            )}
                        </DialogHeader>
                        <ContentWrapper $disableScroll={disableScroll}>{children}</ContentWrapper>
                        {action && <ActionWrapper>{action}</ActionWrapper>}
                    </>
                )}
            </DialogContainer>
        </Modal>
    );
};

const DialogContainer = styled(AriaDialog)`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    width: 100%;
    padding: 1.5rem;
    min-height: 0;
    max-height: inherit;
`;
const DialogHeader = styled.div`
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 0.5rem;
    width: 100%;
    flex: 0;
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: stretch;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        column-gap: 0.5rem;

        & > :first-child {
            order: 1;
        }
    }
`;
const ContentWrapper = styled.div`
    width: 100%;
    flex: 1;
    min-height: 0;
    ${({ $disableScroll }) => !$disableScroll && 'overflow-y: auto;'}
`;
const CloseIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1rem;
    height: 1rem;
`;
const CloseButton = styled(IconButton)`
    padding: 0.25rem;
    border-radius: var(--r-s);
    &:hover {
        background-color: var(--neutral50);
    }
`;

Dialog.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    action: PropTypes.node,
    isDismissable: PropTypes.bool,
    isKeyboardDismissDisabled: PropTypes.bool,
    isOpen: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    style: PropTypes.object,
    disableScroll: PropTypes.bool,
    hasCloseButton: PropTypes.bool,
    description: PropTypes.string,
};

Dialog.defaultProps = {
    isDismissable: true,
    disableScroll: false,
    hasCloseButton: true,
};

export default Dialog;
