import { useMutation } from '@tanstack/react-query';
import { updatePodcast } from '@/api';
import { decamelizeKeys } from 'humps';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ episodeId, episode }) => {
    const { data } = await updatePodcast(episodeId, decamelizeKeys(episode));

    return transform(data);
};

export default function useUpdateEpisodeMutation() {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Invalidation of episode query is handled in useSaveEpisodeFormMutation
        },
    });
}
