import { useQuery } from '@tanstack/react-query';
import { fetchYoutubeQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import quotaKeys from './quotaKeys';
import { QUOTA_TYPES } from '@/utils/constants';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const queryFn = async () => {
    const { data } = await fetchYoutubeQuota();

    return transform(data);
};

const useYoutubeQuotaQuery = () => {
    return useQuery({
        queryKey: quotaKeys.detailByType(QUOTA_TYPES.YOUTUBE),
        queryFn,
    });
};

export default useYoutubeQuotaQuery;
