import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addSeason } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import seasonKeys from '@/queries/season/seasonKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });

    // Remove some properties
    const { podcastsCount, ...rest } = transformedData;

    return {
        ...rest,
        episodesCount: podcastsCount,
    };
};

const mutationFn = async ({ showId }) => {
    const { data } = await addSeason(showId);

    return transform(data);
};

const useAddSeasonMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: seasonKeys.allLists() });
        },
    });
};

export default useAddSeasonMutation;
