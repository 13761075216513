import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import DeprecatedTextInput from '../../ui/molecules/DeprecatedTextInput';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import CTALocationsCheckbox from '../molecules/CTALocationsCheckbox';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import Spinner from '../../ui/atoms/Spinner';

const CTAContentButtonModal = ({ fields, state, checkboxList, onClose, hasChanged, isLoading }) => (
    <>
        <DeprecatedTextInput
            label={<FormattedMessage defaultMessage="Texte du bouton" />}
            field={fields.text}
            maxLength={40}
            mb={32}
            required
        />

        <DeprecatedTextInput
            label={<FormattedMessage defaultMessage="URL du bouton" />}
            field={fields.url}
            readOnly={state.item.type !== 'custom'}
            required
        />

        <DeprecatedText weight="semibold" mb={8} mt={32}>
            <FormattedMessage defaultMessage="Ajouter le bouton sur" />
        </DeprecatedText>
        <DeprecatedPaper flex gap={16} column>
            {Object.values(checkboxList).map((list) => (
                <CTALocationsCheckbox
                    key={list.id}
                    field={fields.locations}
                    checkboxItem={list}
                    type={state.item.type}
                />
            ))}
        </DeprecatedPaper>
        <DeprecatedPaper flex mt={40} justify="flex-end" gap={8}>
            <DeprecatedButton accent="secondary" onClick={onClose}>
                <FormattedMessage defaultMessage="Annuler" />
            </DeprecatedButton>

            <DeprecatedButton
                type="submit"
                disabled={
                    isLoading ||
                    fields.locations.value.length === 0 ||
                    !fields.text.value ||
                    !fields.url.value ||
                    !hasChanged
                }
            >
                {isLoading && <Spinner />}
                <FormattedMessage defaultMessage="Ajouter" />
            </DeprecatedButton>
        </DeprecatedPaper>
    </>
);

export default observer(CTAContentButtonModal);
