import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePodcastListeningLink } from '@/api';
import episodeListeningLinkKeys from '@/queries/episodeListeningLink/episodeListeningLinkKeys';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ episodeId, key, url }) => {
    const { data } = await updatePodcastListeningLink(episodeId, key, {
        url,
    });

    return transform(data);
};

const useUpdateEpisodeListeningLinkMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (data, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: episodeListeningLinkKeys.listByEpisodeId(episodeId),
            });
        },
    });
};

export default useUpdateEpisodeListeningLinkMutation;
