import { runInAction } from 'mobx';
import camelCase from 'lodash.camelcase';
import PageSettingsModel from '../models/PageSettingsModel';
import {
    deleteShowLogo,
    fetchPageSettings,
    updatePageSettings,
    uploadBackgroundCover,
} from '@/api';
import Store from './Store';
import { apiFetch } from '../components/app/decorators/api';
import { messageParsers } from '@/forms/validators/apiValidation';
import { FormattedMessage } from 'react-intl';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { UI_VARIANTS } from '@/utils/constants';

class PageSettingsStore extends Store {
    static observables = {
        settings: undefined,
        files: [],
    };

    @apiFetch
    async fetchSettings(showId) {
        try {
            const { data } = await fetchPageSettings(showId);
            runInAction(() => {
                this.settings = new PageSettingsModel(this, data);
            });
        } catch (error) {
            runInAction(() => {
                this.settings = new PageSettingsModel(this);
            });
        }
    }

    async getFileBackground(file) {
        const findElement = (obj, key) => {
            this.files.find((page) => page[key] === obj[key]);
        };
        this.files.remove(findElement(file, file.formatName));
        this.files.push(file);
    }

    async coverCustomBackground(file, settings) {
        const formatNameCamelCase = camelCase(file.formatName);
        if (Object.values(file).includes('DELETE')) {
            await deleteShowLogo(settings.showId, file.formatName);
            runInAction(() => {
                settings[formatNameCamelCase] = null;
            });
            return;
        }
        if (file) {
            try {
                const { data } = await uploadBackgroundCover(
                    settings.showId,
                    file,
                    file.formatName,
                );
                runInAction(() => {
                    settings[formatNameCamelCase] = data.url;
                });
            } catch (error) {
                Object.entries(error?.response?.data?.fails?.file)?.forEach((fail) => {
                    const [key, values] = fail;
                    const parsedValue = key === 'Mimes' ? [values] : values;
                    if (messageParsers[key]) {
                        modalToastQueue.add(
                            <FormattedMessage
                                defaultMessage="{message}"
                                values={{ message: messageParsers[key](...parsedValue) }}
                            />,
                            {
                                variant: UI_VARIANTS.ALERT,
                                timeout: 5000,
                            },
                        );
                    }
                });
            }
        }
    }

    async updateSettings(settings, { slug, ...formData }) {
        if (Object.keys(formData).length > 0) {
            // Update CustomBackground
            if (this.files?.length > 0) {
                await Promise.all(
                    this.files.map(async (file) => {
                        await this.coverCustomBackground(file, settings);
                    }),
                );
                this.files = [];
            }

            const { data } = await updatePageSettings(settings.showId, formData);
            this.settings.updateData(data);
        }
    }
}

export default PageSettingsStore;
