import { createApi } from '../callApi';
const analyticsApi = createApi('ANALYTICS');

export const psoSaveKeywords = (showId, keywords) => {
    return analyticsApi({
        method: 'PUT',
        target: `/shows/${showId}/keywords`,
        body: keywords,
    });
};
export const fetchAvailableStores = () => {
    return analyticsApi({ method: 'GET', target: '/stores' });
};
export const psoSaveStores = (showId, stores) => {
    return analyticsApi({
        method: 'PUT',
        target: `/shows/${showId}/stores`,
        body: stores,
    });
};
export const psoGlobalConfiguration = (showId) => {
    return analyticsApi({
        method: 'GET',
        target: `/shows/${showId}/global_configuration`,
    });
};
export const psoRankings = (showId, currentStore) => {
    return analyticsApi({
        target: `/shows/${showId}/stats/rankings?country=${currentStore}`,
        method: 'GET',
    });
};
export const psoOverview = (showId, currentStore, doesExport) => {
    return analyticsApi({
        target: `/shows/${showId}/stats/overview?country=${currentStore}`,
        method: 'GET',
        headers: { Accept: doesExport ? 'text/csv' : 'application/json' },
    });
};
export const psoAnalytics = (showId, currentStore) => {
    return analyticsApi({
        target: `/shows/${showId}/stats/analytics?country=${currentStore}`,
        method: 'GET',
    });
};
export const psoKeywordQuota = (channelId) => {
    return analyticsApi({
        target: `/channels/${channelId}/keywords_quota`,
        method: 'GET',
    });
};
export const psoIndexedKeywords = (showId) => {
    return analyticsApi({
        target: `/shows/${showId}/indexed_keywords`,
        method: 'GET',
    });
};
