import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import EpisodeEdit from '@app/molecules/EpisodeEdit';
import { DEBUG_EPISODE_EDIT_FORM, EPISODE_STATUS } from '@/utils/constants';
import styled from 'styled-components';
import { useResponsive } from '@hooks/useResponsive';
import SaveDraftEpisodeAndCloseButton from '@app/organisms/EpisodeEditForm/actions/SaveDraftEpisodeAndCloseButton';
import SaveAndCloseButton from '@app/organisms/EpisodeEditForm/actions/SaveAndCloseButton';
import CancelButton from '@app/organisms/EpisodeEditForm/actions/CancelButton';
import MoveToDraftButton from '@app/organisms/EpisodeEditForm/actions/MoveToDraftButton';
import PublishButton from '@app/organisms/EpisodeEditForm/actions/PublishButton';
import ScheduleButton from '@app/organisms/EpisodeEditForm/actions/ScheduleButton';
import Cluster from '@ui/layout/Cluster';
import { useIntl } from 'react-intl';
import getEpisodeEditFormSchema from '@app/organisms/EpisodeEditForm/schemas/episodeEditFormSchema';
import UnsavedFormModal from '@app/molecules/UnsavedFormModal';
import { DevTool } from '@hookform/devtools';
import useYoutubeVideoQuery from '@/queries/youtubeVideo/useYoutubeVideoQuery.hook';
import { YOUTUBE_VIDEO_STATUS } from '@/utils/constants';

const Container = styled.section`
    background-color: var(--neutral50);
    display: flex;
    flex-direction: column;
    height: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        background-color: var(--white);
    }
`;

const Header = styled.header`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
    }
`;

const Content = styled.div`
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
    padding: 0 1rem 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        justify-content: center;
        background-color: var(--neutral50);
        border-radius: var(--r-l) var(--r-l) 0 0;
        margin-inline: 2rem;
        padding: 2rem;
    }
`;

const Footer = styled.footer`
    background-color: var(--white);
    border-top: 1px solid var(--neutral100);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
    padding: 1rem;

    > * {
        flex-grow: 1;
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;

const EpisodeEditForm = ({ episode, chapters }) => {
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();
    const schema = getEpisodeEditFormSchema(intl);
    const { data: youtubeVideo } = useYoutubeVideoQuery(episode.id);
    const isYoutubeVideoPendingOrProcessing =
        youtubeVideo?.state === YOUTUBE_VIDEO_STATUS.PENDING ||
        youtubeVideo?.state === YOUTUBE_VIDEO_STATUS.PROCESSING;
    const methods = useForm({
        mode: 'onBlur',
        values: {
            content: {
                name: episode.name,
                slug: episode.slug,
                useCustomUrl: episode.useCustomUrl,
                customUrl: episode.customUrl,
                htmlDescription: episode.htmlDescription,
                tags: episode.tags.map((tag) => tag.name),
                imageUrl: episode.imageUrl,
            },
            options: {
                seasonId: episode.seasonId,
                type: episode.type,
                isExplicit: episode.isExplicit,
                publishYoutube: !!isYoutubeVideoPendingOrProcessing,
                soundcloudPublish: episode.soundcloudPublish,
            },
            privacy: {
                privacy: episode.privacy,
                canDownload: episode.canDownload,
            },
            chapters: chapters,
        },
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
        resolver: yupResolver(schema),
    });

    const isEpisodeDraft = episode.state === EPISODE_STATUS.DRAFT;

    const {
        control,
        formState: { isDirty },
    } = methods;

    return (
        <FormProvider {...methods}>
            <Container>
                <Header>
                    <Cluster $gap="1rem" $wrap="nowrap">
                        {isEpisodeDraft ? (
                            <SaveDraftEpisodeAndCloseButton />
                        ) : (
                            <>
                                <CancelButton />
                                {!isMobileOrTablet && <MoveToDraftButton />}
                            </>
                        )}
                    </Cluster>
                    {!isMobileOrTablet && (
                        <Cluster $gap="1rem" $wrap="nowrap">
                            {isEpisodeDraft ? (
                                <>
                                    <ScheduleButton />
                                    <PublishButton />
                                </>
                            ) : (
                                <SaveAndCloseButton />
                            )}
                        </Cluster>
                    )}
                </Header>
                <Content>
                    <EpisodeEdit episode={episode} />
                </Content>
                <Footer>
                    {isEpisodeDraft ? (
                        <>
                            <ScheduleButton />
                            <PublishButton />
                        </>
                    ) : (
                        <>
                            <MoveToDraftButton />
                            <SaveAndCloseButton />
                        </>
                    )}
                </Footer>
            </Container>
            <UnsavedFormModal shouldBlockNavigation={isDirty} />

            {DEBUG_EPISODE_EDIT_FORM && <DevTool control={control} placement="top-left" />}
        </FormProvider>
    );
};

export default EpisodeEditForm;
