import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import DeprecatedText from '../../atoms/DeprecatedText';
import DeprecatedIcon from '../../atoms/DeprecatedIcon';
import { ReactComponent as arrow } from '@public/icons/chevrondown.svg';
import MobileOrTablet from '../MobileOrTablet';
import Desktop from '../Desktop';
import DeprecatedButton from '../../atoms/DeprecatedButton';
import Button from '../../atoms/Button';
import IconClose from '../../icons/IconClose';

const Wrapper = styled(DeprecatedPaper)`
    position: relative;
    border: 1px solid var(--neutral200);
    border-radius: var(--r-xs);
    label {
        margin-bottom: 10px;
        display: flex;
    }
    select {
        cursor: pointer;
        background-color: transparent;
    }
    svg {
        position: absolute;
        right: 10px;
        bottom: 12px;
        fill: var(--neutral500);
        z-index: 1;
    }
`;

const accents = {
    primary: css`
        height: 35px;
    `,
    secondary: css`
        height: 45px;
    `,
};

const InputSelect = styled(DeprecatedPaper)`
    padding: 8px 10px;
    background: transparent;
    border: none;
    color: var(--black);
    font-size: ${(p) => (p.isMobileOrTablet ? 'var(--fs-subheading)' : 'var(--fs-body)')};
    font-weight: var(--fw-semibold);
    -webkit-appearance: none;
    ${(p) => accents[p.accent]};
`;

const MobileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 0.75rem;
    padding-left: 1rem;
`;

const MobileHeaderTitle = styled.span`
    display: inline-flex;
`;

const CloseButton = styled(Button)`
    background: none;
    box-shadow: none;
`;

const SelectNative = ({
    label,
    id,
    name,
    onChange,
    source,
    disabled,
    manualClosing,
    withClosingHeader,
    close,
}) => {
    const [opened, setOpen] = useState(false);

    useEffect(() => {
        if (close) setOpen(false);
    }, [close]);
    return (
        <>
            <Desktop>
                <DeprecatedPaper mr={10}>
                    <DeprecatedText mb={10} flex as="label" htmlFor={name} weight="semibold">
                        {label}
                    </DeprecatedText>
                    <Wrapper flex w="max-content">
                        <InputSelect
                            pr={30}
                            z={2}
                            as="select"
                            id={id}
                            name={name}
                            onChange={onChange}
                        >
                            {source}
                        </InputSelect>
                        <DeprecatedIcon as={arrow} size="10px" />
                    </Wrapper>
                </DeprecatedPaper>
            </Desktop>
            <MobileOrTablet>
                <DeprecatedButton
                    h={40}
                    accent="rounded"
                    style={{ borderRadius: 4, width: '100%' }}
                    disabled={disabled}
                    onClick={() => setOpen(true)}
                >
                    {label}
                </DeprecatedButton>
                {opened && (
                    <DeprecatedPaper
                        background="white"
                        pos="fixed"
                        x={0}
                        y={0}
                        h="100%"
                        w="100%"
                        z={5}
                        onClick={() => !manualClosing && setOpen(false)}
                    >
                        {withClosingHeader && (
                            <MobileHeader>
                                <MobileHeaderTitle>{label}</MobileHeaderTitle>
                                <CloseButton color="--neutral500" onClick={() => setOpen(false)}>
                                    <IconClose />
                                </CloseButton>
                            </MobileHeader>
                        )}
                        {source}
                    </DeprecatedPaper>
                )}
            </MobileOrTablet>
        </>
    );
};

export default SelectNative;
