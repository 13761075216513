import { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import DeprecatedInput from '../../atoms/DeprecatedInput';
import DeprecatedText from '../../atoms/DeprecatedText';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import TimePickerSelect from '../TimePickerSelect';
import UiKitTooltip from '@ui/atoms/UiKitTooltip/UiKitTooltip';

const InputTime = styled(DeprecatedInput)`
    display: flex;
    flex-direction: row-reverse;
    width: max-content;

    .datetime-reset-button {
        display: none;
    }
    &::-webkit-calendar-picker-indicator {
        margin: 0;
        &:hover {
            cursor: pointer;
        }
    }
    &:invalid {
        border-color: var(--alert);
    }
`;
const StyledTooltip = styled(UiKitTooltip)`
    width: max-content;
`;

function TimeInput({ value, onChange, label, required, isTypeText, disabled, tooltip, className }) {
    const ref = createRef();

    const [time, setTime] = useState(dayjs(value).format('HH:mm'));

    const handleChange = (val) => {
        if (!val) return;
        const parseTime = val.split(':').map((t) => parseInt(t, 10));

        onChange(
            dayjs(value)
                .set({
                    years: dayjs(value).year(),
                    months: dayjs(value).month(),
                    days: dayjs(value).day(),
                    hours: parseTime[0],
                    minutes: parseTime[1],
                })
                .toDate(),
        );

        setTime(val);
    };

    if (isTypeText) return <TimePickerSelect time={time} setTime={handleChange} />;

    return (
        <StyledTooltip position="top" passthrough={!tooltip} content={tooltip} variant="dark">
            <DeprecatedPaper pos="relative" w="max-content">
                {label && (
                    <DeprecatedText weight="semibold" mb={label && 10}>
                        {label}
                        {required && (
                            <DeprecatedText weight="semibold" as="span" color="--primary">
                                {' *'}
                            </DeprecatedText>
                        )}
                    </DeprecatedText>
                )}
                <InputTime
                    value={time}
                    onChange={(e) => handleChange(e.target.value)}
                    type="time"
                    pattern="[0-9]{2}:[0-9]{2}"
                    min="00:00"
                    max="23:59"
                    required
                    w={85}
                    h={38}
                    ref={ref}
                    disabled={disabled}
                    className={className}
                />
            </DeprecatedPaper>
        </StyledTooltip>
    );
}

const ExportedTimeInput = observer(TimeInput);

ExportedTimeInput.propTypes = {
    field: PropTypes.shape({}),
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    required: PropTypes.bool,
    rightIcon: PropTypes.node,
    leftIcon: PropTypes.node,
    useSeconds: PropTypes.bool,
    disabled: PropTypes.bool,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

ExportedTimeInput.defaultProps = {
    field: undefined,
    value: undefined,
    onChange: undefined,
    label: undefined,
    required: undefined,
    rightIcon: undefined,
    leftIcon: undefined,
    useSeconds: false,
    disabled: false,
};

export default ExportedTimeInput;
