import Model from './Model';
import { initial } from './resolvers';
import { apiCall } from '../components/app/decorators/api';
import { durationToSeconds, secondsToDuration } from '../helpers';

class PlayerSettingsModel extends Model {
    static default = (state) => ({
        showId: state.routerStore.params.showId,
        color: '#72238e',
        episodeList: false,
        format: 'auto',
        showSwitch: false,
        darkMode: false,
        sizeUnit: '%',
        width: 100,
        startAt: '00:00',
    });

    static attributes = {
        showId: 'show_id',
        color: 'color',
        episodeList: 'episode_list',
        format: 'format',
        showSwitch: 'show_switch',
        darkMode: 'dark_mode',
        sizeUnit: {
            fromApi: {
                key: 'size_unit',
                resolve: (size) => (size === 'percent' ? '%' : 'px'),
            },
            toApi: {
                key: 'size_unit',
                resolve: (size) => (size === '%' ? 'percent' : 'pixel'),
            },
        },
        width: initial('width', 100),
        startAt: {
            fromApi: {
                key: 'start_at',
                resolve: secondsToDuration,
            },
            toApi: {
                key: 'start_at',
                resolve: durationToSeconds,
            },
        },
    };

    @apiCall
    update(showId, formData) {
        return this.store.updateSettings(showId, formData);
    }
}

export default PlayerSettingsModel;
