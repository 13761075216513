import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deletePodcastImage } from '@/api';
import episodeKeys from '@/queries/episode/episodeKeys';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ episodeId }) => {
    const { data } = await deletePodcastImage(episodeId);

    return transform(data);
};

const useDeleteEpisodeImageMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (data, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.detailById(episodeId),
            });
        },
    });
};

export default useDeleteEpisodeImageMutation;
