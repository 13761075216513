import { useQuery } from '@tanstack/react-query';
import { fetchPodcastListeningLinks } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeListeningLinkKeys from '@/queries/episodeListeningLink/episodeListeningLinkKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });

    return transformedData;
};

const queryFn = async ({ queryKey: [{ episodeId }] }) => {
    const { data } = await fetchPodcastListeningLinks(episodeId);

    return transform(data);
};

const useEpisodeListeningLinksQuery = (episodeId) => {
    return useQuery({
        queryKey: episodeListeningLinkKeys.listByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
    });
};

export default useEpisodeListeningLinksQuery;
