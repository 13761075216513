import { FormattedMessage } from 'react-intl';
import CTAMenuDropDown from './CTAMenuDropDown';
import EmptyState from '@ui/molecules/EmptyState';
import noCTAMainIllustration from '@public/images/empty-state/illustration/calls_to_action.png';
import noCTAHeadIllustration from '@public/images/empty-state/calls_to_action.svg';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const CTAsCreationStart = ({ basicsCTAsList, dispatch }) => (
    <EmptyState
        illustration={noCTAMainIllustration}
        illustrationType="image"
        illustrationAlign="right"
    >
        <HeadIllustrationWrapper>
            <HeadIllustration src={noCTAHeadIllustration} />
        </HeadIllustrationWrapper>
        <LabelWrapper>
            <Text variant="heading" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Créez votre premier bouton personnalisé" />
            </Text>
            <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                <FormattedMessage defaultMessage="Personnalisez et gérez vos boutons d'action pour mettre en avant votre contenu sur tous vos outils de communication 🎛️" />
            </Text>
        </LabelWrapper>
        <CTAMenuDropDown ctaList={basicsCTAsList} dispatch={dispatch} defaultDropDown />
    </EmptyState>
);

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default CTAsCreationStart;
