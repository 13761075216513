import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const appendArrow = (position) => {
    const positionMatch = {
        top: css`
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        `,
        bottom: css`
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
        `,
        left: css`
            border-top: 0.5rem solid transparent;
            border-bottom: 0.5rem solid transparent;
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
        `,
        right: css`
            border-top: 0.5rem solid transparent;
            border-bottom: 0.5rem solid transparent;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
        `,
    };

    return css`
    ::before,
    ::after {
        content: '';
        position: absolute;
        ${positionMatch[position]};
    }
    ::before {
        border-${position}: 0.25rem solid ${(p) =>
        p.variant === 'dark' ? 'var(--black)' : 'var(--primary200)'};
    }

    ::after {
        border-${position}: 0.25rem solid ${(p) =>
        p.variant === 'dark' ? 'var(--black)' : 'var(--white)'};
        margin-${position}: -1.5px;
        z-index: 100;
    }
    `;
};

const tooltipContentPosition = {
    top: css`
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(100% + 0.5rem);
    `,
    bottom: css`
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + 0.5rem);
    `,
    left: css`
        top: 50%;
        transform: translateY(-50%);
        right: calc(100% + 0.5rem);
    `,
    right: css`
        top: 50%;
        transform: translateY(-50%);
        left: calc(100% + 0.5rem);
    `,
};

const UiKitTooltipContent = styled.div`
    font-weight: var(--fw-normal);
    font-size: var(--fs-body);
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    text-align: center;
    padding: 0.5rem;
    border-radius: var(--r-s);
    white-space: normal;
    background-color: ${(p) => (p.variant === 'dark' ? 'var(--black)' : 'var(--white)')};
    color: ${(p) => (p.variant === 'dark' ? 'var(--white)' : 'var(--black)')};
    border: solid 1px ${(p) => (p.variant === 'dark' ? 'var(--black)' : 'var(--primary200)')};
    position: absolute;
    max-width: 20rem;
    ${(p) => tooltipContentPosition[p.position]};

    ${(p) => p.position && appendArrow(p.position)};
`;

const UiKitTooltipContainer = styled.div`
    position: relative;
    height: max-content;
    width: max-content;
    // TODO: Change height and width ?
    // height: fit-content;
    // width: fit-content;
    & svg {
        display: block;
    }
`;

const UiKitTooltip = ({ children, content, passthrough, className, ...props }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const tooltipRef = useRef(null);
    let hoverTimeout;

    useEffect(() => {
        return () => {
            clearTimeout(hoverTimeout);
        };
    }, []);

    const handleMouseEnter = () => {
        clearTimeout(hoverTimeout);
        setIsTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        hoverTimeout = setTimeout(() => {
            setIsTooltipVisible(false);
        }, 300);
    };

    if (passthrough) return children;

    return (
        <UiKitTooltipContainer
            className={className}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {children}
            {isTooltipVisible && (
                <UiKitTooltipContent ref={tooltipRef} {...props}>
                    {content}
                </UiKitTooltipContent>
            )}
        </UiKitTooltipContainer>
    );
};

UiKitTooltip.propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.node,
    position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    variant: PropTypes.oneOf(['light', 'dark']),
    passthrough: PropTypes.bool,
    className: PropTypes.string,
};

UiKitTooltip.defaultProps = {
    variant: 'light',
    position: 'right',
    passthrough: false,
};

export default UiKitTooltip;
