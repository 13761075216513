import Model from './Model';

class LanguageModel extends Model {
    static attributes = {
        id: 'id',
        code: 'code',
        isFeatured: 'is_featured',
        name: 'name',
        nativeName: 'native_name',
    };
}

export default LanguageModel;
