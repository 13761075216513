import { useState } from 'react';
import { Button, DialogTrigger, FileTrigger, Popover } from 'react-aria-components';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import MenuDropdown from '@ui/atoms/MenuDropdown';
import MenuDropdownItem from '@ui/atoms/MenuDropdown/MenuDropdownItem';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import { ACCEPTED_AUDIO_TYPES } from '@/utils/constants';

const MENU_ACTIONS = {
    UPLOAD: 'upload',
    DOWNLOAD: 'download',
    DELETE: 'delete',
};

const EpisodeEditResumeAudioFileMenu = ({ handleDelete, handleEdit, audioUrl }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const menuActionHandler = (action) => {
        switch (action) {
            case MENU_ACTIONS.UPLOAD:
                break;
            case MENU_ACTIONS.DELETE:
                handleDelete();
                setIsMenuOpen(false);
                break;
            default:
                break;
        }
    };
    const handleFile = (e) => {
        const files = Array.from(e);
        handleEdit(files[0]);
    };

    return (
        <DialogTrigger>
            <TriggerButton aria-label="Menu" onPress={() => setIsMenuOpen(true)}>
                <EllipsisIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />
            </TriggerButton>
            <PopoverStyled placement="bottom end" isOpen={isMenuOpen} onOpenChange={setIsMenuOpen}>
                <MenuDropdown onAction={menuActionHandler}>
                    <MenuDropdownItem id={MENU_ACTIONS.UPLOAD}>
                        <FileTrigger
                            onSelect={handleFile}
                            acceptedFileTypes={ACCEPTED_AUDIO_TYPES.join(',')}
                        >
                            <FileTriggerButton>
                                <MenuItemIcon
                                    icon={icon({
                                        name: 'pen-to-square',
                                        style: 'solid',
                                    })}
                                />
                                <Text variant="bodyM">
                                    <FormattedMessage defaultMessage="Modifier" />
                                </Text>
                            </FileTriggerButton>
                        </FileTrigger>
                    </MenuDropdownItem>
                    <MenuDropdownItem
                        id={MENU_ACTIONS.DOWNLOAD}
                        href={audioUrl}
                        target="_blank"
                        download
                        style={{ textDecoration: 'none' }}
                    >
                        <MenuItemIcon
                            icon={icon({
                                name: 'arrow-down-to-line',
                                style: 'solid',
                            })}
                        />
                        <Text variant="bodyM">
                            <FormattedMessage defaultMessage="Télécharger" />
                        </Text>
                    </MenuDropdownItem>
                    <MenuDropdownItem id={MENU_ACTIONS.DELETE}>
                        <TrashIcon icon={icon({ name: 'trash', style: 'solid' })} />
                        <Text variant="bodyM" color="--alert">
                            <FormattedMessage defaultMessage="Supprimer" />
                        </Text>
                    </MenuDropdownItem>
                </MenuDropdown>
            </PopoverStyled>
        </DialogTrigger>
    );
};

const TriggerButton = styled(Button)`
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const PopoverStyled = styled(Popover)`
    width: 100%;
    max-width: 10rem;
    z-index: 1 !important;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const TrashIcon = styled(MenuItemIcon)`
    color: var(--alert);
`;
const FileTriggerButton = styled(Button)`
    padding: 0;
    width: 100%;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;
`;

export default EpisodeEditResumeAudioFileMenu;
