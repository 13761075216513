import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchTranscriptQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { QUOTA_TYPES } from '@/utils/constants';
import quotaKeys from './quotaKeys';

export interface TranscriptQuota {
    maximum: number;
    minimum: number;
    step: number;
    total: number;
}

/* 
    TODO: Type is valid here but usually we would use utility type like 
    SnakeToCamelCase<S extends string>
*/
const transform = (data: TranscriptQuota) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(
        {
            maximum: data.maximum / 60,
            minimum: data.minimum / 60,
            step: data.step / 60,
            total: data.total / 60,
        },
        { deep: true },
    );
};

const queryFn = async () => {
    const { data } = await fetchTranscriptQuota();

    return transform(data);
};

const useTranscriptQuotaQuery = (): UseQueryResult<TranscriptQuota> => {
    return useQuery({
        queryKey: quotaKeys.detailByType(QUOTA_TYPES.TRANSCRIPT as keyof typeof QUOTA_TYPES),
        queryFn,
        staleTime: 0,
    });
};

export default useTranscriptQuotaQuery;
