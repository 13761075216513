import useSeasonsQuery from '@/queries/season/useSeasonsQuery.hook';
import { SelectController } from '@/components/ui/molecules/Select';
import useAddSeasonMutation from '@/queries/season/useAddSeasonMutation.hook';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import Button from '@/components/ui/atoms/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router-dom';
import Text from '@/components/ui/atoms/Text';
import EpisodeEditTooltip from '../EpisodeEditTooltip/EpisodeEditTooltip';

const messages = defineMessages({
    season: { defaultMessage: 'Saison {season}' },
    noSeason: { defaultMessage: 'Non classé' },
});

const SelectSeason = (props) => {
    const intl = useIntl();
    const { showId } = useParams();
    const { data: seasons } = useSeasonsQuery(showId);
    const addSeasonMutation = useAddSeasonMutation();

    const addSeason = () => addSeasonMutation.mutate({ showId });

    if (seasons === undefined) return null;

    return (
        <SelectController
            nullOptionCustomTitle={intl.formatMessage(messages.noSeason)}
            label={
                <>
                    <FormattedMessage defaultMessage="Saison" />
                    <EpisodeEditTooltip>
                        <FormattedMessage defaultMessage="Vous pouvez classer vos épisodes dans des saisons qui seront visibles sur Apple Podcast." />
                    </EpisodeEditTooltip>
                </>
            }
            options={seasons?.map((season) => ({
                id: season.id,
                label: intl.formatMessage(messages.season, { season: season.seasonNumber }),
            }))}
            customButton={
                <CustomButton
                    isLoading={addSeasonMutation.isLoading}
                    startIcon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'plus', style: 'solid' })}
                            color="--primary500"
                            size="xs"
                        />
                    }
                    onPress={addSeason}
                >
                    <Text>
                        <FormattedMessage defaultMessage="Créer une saison" />
                    </Text>
                </CustomButton>
            }
            placeholder={intl.formatMessage(messages.noSeason)}
            {...props}
        />
    );
};

const CustomButton = styled(Button)`
    background: none;
    box-shadow: unset;
    border-top: solid var(--neutral50) 1px;
    padding: 0.75rem;
    width: 100%;
    color: var(--black);
    border-radius: unset;
    justify-content: flex-start;

    &:hover {
        background-color: var(--neutral50);
    }
`;

export default SelectSeason;
