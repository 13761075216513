import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { InputRichTextController } from '@ui/atoms/InputRichText';

const EpisodeEditFormNewsletterMessageField = () => {
    const { control } = useFormContext();

    return (
        <InputRichTextController
            name="messageTpl"
            control={control}
            label={<FormattedMessage defaultMessage="Contenu de la newsletter" />}
            charactersLimit={4000}
        />
    );
};

export default EpisodeEditFormNewsletterMessageField;
