import { runInAction } from 'mobx';
import Store from './Store';
import { deleteImportedEpisode, fetchImportedPodcasts, retryImport } from '@/api';
import ImportedPodcastModel from '../models/ImportedPodcastModel';
import ImportedPodcastsCountersModel from '../models/ImportedPodcastsCountersModel';
import { apiCall } from '../components/app/decorators/api';
import PaginationModel from '../models/PaginationModel';

class ImportedPodcastsStore extends Store {
    static observables = {
        importedPodcasts: [],
        counters: {},
        pagination: {},
    };

    @apiCall
    async fetchImportedPodcasts(importId, query) {
        return this.subscribeToImportedPodcasts(importId, query);
    }

    @apiCall
    async subscribeToImportedPodcasts(importId, query) {
        const { data, meta } = await fetchImportedPodcasts(importId, query);
        runInAction(() => {
            this.importedPodcasts = data.map((p) => new ImportedPodcastModel(this, p));
            this.counters = new ImportedPodcastsCountersModel(this, meta.counters);
            this.pagination = new PaginationModel(this, meta.pagination);
        });
    }

    async retryImport(importedPodcast) {
        importedPodcast.states = 'pending';
        await retryImport(importedPodcast.id);
    }

    async deleteImportedEpisode(importedPodcast) {
        importedPodcast.states = 'pending';
        await deleteImportedEpisode(importedPodcast.id);
    }
}

export default ImportedPodcastsStore;
