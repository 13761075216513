import styled, { css } from 'styled-components';
import Stack from '@ui/layout/Stack';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Cluster from '@ui/layout/Cluster';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useOverlayTriggerState } from 'react-stately';
import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import LinkForm from '@ui/atoms/InputRichText/LinkForm';
import EmojiPickerButton from '@ui/molecules/EmojiPickerButton';
import Text from '@ui/atoms/Text';
import Placeholder from '@tiptap/extension-placeholder';
import { CharacterCount } from '@tiptap/extension-character-count';
import MaxLength from '@ui/molecules/MaxLength';
import PropTypes from 'prop-types';
import { LinkExtension } from './LinkExtension';

const InputRichText = ({
    value,
    label,
    description,
    placeholder,
    tooltip,
    charactersLimit,
    isDisabled,
    options,
    onChange,
    errorMessage,
    onBlur,
}) => {
    const linkModalState = useOverlayTriggerState({});
    const hasOptions = Object.values(options).some((option) => option === true);
    let extensions = [
        StarterKit.configure({
            bold: options.bold,
            italic: options.italic,
            bulletList: options.bulletList,
            orderedList: options.orderedList,
            blockquote: false,
            codeBlock: false,
            heading: false,
            horizontalRule: false,
            code: false,
            strike: false,
        }),
    ];

    if (options.link) {
        extensions = [...extensions, LinkExtension];
    }

    if (placeholder) {
        extensions = [
            ...extensions,
            Placeholder.configure({
                placeholder,
            }),
        ];
    }

    if (charactersLimit) {
        extensions = [
            ...extensions,
            CharacterCount.configure({
                limit: null,
            }),
        ];
    }

    const content = value?.replace(/<p><br><\/p>/g, '<p></p>');

    const editor = useEditor({
        extensions,
        content,
        onUpdate: ({ editor }) => {
            if (!onChange) return;
            onChange(editor.getHTML());
        },
        parseOptions: {
            preserveWhitespace: true, // Preserve whitespace, but normalize newlines to spaces
        },
    });

    if (!editor) {
        return null;
    }

    if (isDisabled) {
        editor.setEditable(false);
    }

    const setLink = (data) => {
        // cancelled
        if (data === null) {
            return;
        }
        // empty
        if (data.link === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
            return;
        }
        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: data.link }).run();
        linkModalState.close();
    };

    return (
        <>
            <Stack $gap="0.5rem">
                <Cluster $justify="space-between">
                    <Stack>
                        {label && (
                            <Cluster $gap="0.125rem" $align="center">
                                <Text
                                    fontWeight="--fw-semibold"
                                    color={isDisabled ? '--neutral400' : ''}
                                >
                                    {label}
                                </Text>
                                {tooltip}
                            </Cluster>
                        )}
                        {description && (
                            <Text
                                variant="footnote"
                                color={isDisabled ? '--neutral300' : '--neutral500'}
                            >
                                {description}
                            </Text>
                        )}
                    </Stack>
                    {charactersLimit && (
                        <MaxLength
                            currentValue={editor.storage.characterCount.characters()}
                            max={charactersLimit}
                        />
                    )}
                </Cluster>
                {hasOptions && (
                    <Cluster $gap="0.25rem">
                        {options.bold && (
                            <ExtensionButton
                                variant="secondary"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'bold',
                                            style: 'solid',
                                        })}
                                        size="sm"
                                    />
                                }
                                onPress={() => editor.chain().focus().toggleBold().run()}
                                isToggled={editor.isActive('bold')}
                                isDisabled={isDisabled}
                                data-testid="rich-text-input-bold-button"
                            />
                        )}
                        {options.italic && (
                            <ExtensionButton
                                variant="secondary"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'italic',
                                            style: 'solid',
                                        })}
                                        size="sm"
                                    />
                                }
                                onPress={() => editor.chain().focus().toggleItalic().run()}
                                isToggled={editor.isActive('italic')}
                                isDisabled={isDisabled}
                            />
                        )}
                        {options.link && (
                            <>
                                <ExtensionButton
                                    variant="secondary"
                                    startIcon={
                                        <FontAwesomeIcon
                                            icon={icon({
                                                name: 'link-simple',
                                                style: 'solid',
                                            })}
                                            size="sm"
                                        />
                                    }
                                    onPress={linkModalState.open}
                                    isToggled={editor.isActive('link')}
                                    isDisabled={isDisabled}
                                />
                                <ExtensionButton
                                    variant="secondary"
                                    startIcon={
                                        <FontAwesomeIcon
                                            icon={icon({
                                                name: 'link-simple-slash',
                                                style: 'solid',
                                            })}
                                            size="sm"
                                        />
                                    }
                                    onPress={() => editor.chain().focus().unsetLink().run()}
                                    isToggled={!editor.isActive('link')}
                                    isDisabled={isDisabled || !editor.isActive('link')}
                                />
                            </>
                        )}
                        {options.bulletList && (
                            <ExtensionButton
                                variant="secondary"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'list-ul',
                                            style: 'solid',
                                        })}
                                        size="sm"
                                    />
                                }
                                onPress={() => editor.chain().focus().toggleBulletList().run()}
                                isToggled={editor.isActive('bulletList')}
                                isDisabled={isDisabled}
                            />
                        )}
                        {options.orderedList && (
                            <ExtensionButton
                                variant="secondary"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'list-ol',
                                            style: 'solid',
                                        })}
                                        size="sm"
                                    />
                                }
                                onPress={() => editor.chain().focus().toggleOrderedList().run()}
                                isToggled={editor.isActive('orderedList')}
                                isDisabled={isDisabled}
                            />
                        )}
                        {options.emojis && (
                            <EmojiPickerButton editor={editor} isDisabled={isDisabled} />
                        )}
                    </Cluster>
                )}
                <StyledEditorContent
                    editor={editor}
                    onBlur={(event) => onBlur(event)}
                    role="textbox"
                />
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Stack>
            {linkModalState.isOpen && (
                <Modal
                    title={<FormattedMessage defaultMessage="Lien" />}
                    onClose={linkModalState.close}
                    isOpen={linkModalState.isOpen}
                >
                    <LinkForm
                        onSubmit={setLink}
                        onCancel={linkModalState.close}
                        link={editor?.getAttributes('link')?.href}
                    />
                </Modal>
            )}
        </>
    );
};

const ExtensionButton = styled(Button)`
    height: 2rem;
    width: 2rem;
    padding: 0.375rem;

    ${(p) =>
        p.isToggled &&
        css`
            background: var(--neutral100);
        `}
`;

const StyledEditorContent = styled(EditorContent)`
    .ProseMirror {
        min-height: 7.5rem;
        max-height: 19.75rem;
        padding: 0.625rem 0.75rem;
        overflow-y: auto;
        font-size: var(--fs-body);
        line-height: var(--lh-body);
        border: 1px solid var(--neutral200);
        border-radius: var(--r-xs);
        background-color: var(--white);
        transition: border-color 0.5s;
    }

    .ProseMirror:hover {
        border-color: var(--neutral500);
    }

    .ProseMirror:focus,
    .ProseMirror:focus-within,
    .ProseMirror-focused {
        border-color: var(--primary);
    }

    .ProseMirror p.is-editor-empty:first-child::before {
        color: var(--neutral500);
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }
`;
const ErrorMessage = styled(Text)`
    color: var(--alert);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;

InputRichText.propTypes = {
    value: PropTypes.string,
    label: PropTypes.node,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    tooltip: PropTypes.element,
    charactersLimit: PropTypes.number,
    isDisabled: PropTypes.bool,
    options: PropTypes.shape({
        bold: PropTypes.bool,
        italic: PropTypes.bool,
        link: PropTypes.bool,
        bulletList: PropTypes.bool,
        orderedList: PropTypes.bool,
        emojis: PropTypes.bool,
    }),
    onChange: PropTypes.func,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    onBlur: PropTypes.func,
};

InputRichText.defaultProps = {
    value: undefined,
    label: undefined,
    description: undefined,
    placeholder: undefined,
    tooltip: undefined,
    charactersLimit: undefined,
    isDisabled: false,
    options: {
        bold: true,
        italic: true,
        link: true,
        bulletList: true,
        orderedList: true,
        emojis: true,
    },
    onChange: () => {},
    onBlur: () => {},
    hasError: false,
};

export default InputRichText;
