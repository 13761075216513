import { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TranscriptionEditor from '@app/molecules/TranscriptionEditor/TranscriptionEditor';
import TranscriptionPlayer from '@app/molecules/TranscriptionPlayer/TranscriptionPlayer';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import Button from '@ui/atoms/Button';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUpdateEpisodeTranscriptMutation from '@queries/episodeTranscript/useUpdateEpisodeTranscriptMutation.hook';
import { convertTiptapContentToTranscript } from '@app/molecules/TranscriptionEditor/transcriptConverter';
import { useSpeakers } from '@app/molecules/TranscriptionEditor/TranscriptionSpeakersProvider';
import useTranscriptionEditor from '../../molecules/TranscriptionEditor/useTranscriptionEditor';

const Transcription = ({ transcription, episodeId, audioUrl, waveformDataUrl, onClose }) => {
    const { speakers } = useSpeakers();
    const updateTranscript = useUpdateEpisodeTranscriptMutation();
    const [currentTime, setCurrentTime] = useState(0);
    const [segmentTimeCode, setSegmentTimeCode] = useState(null);
    const handlePlaySegment = useCallback(
        (time) => {
            setSegmentTimeCode(time);
        },
        [setSegmentTimeCode],
    );
    const editor = useTranscriptionEditor({ transcription, onPlaySegment: handlePlaySegment });

    const handleUpdateTranscript = useCallback(() => {
        if (!editor) {
            onClose();
            return;
        }
        const speakersArray = Array.from(speakers.entries()).map(([id, name]) => ({ id, name }));
        updateTranscript.mutate(
            {
                episodeId,
                transcript: {
                    data: convertTiptapContentToTranscript(editor.getJSON(), speakersArray),
                },
            },
            { onSuccess: onClose },
        );
    }, [episodeId, onClose, updateTranscript]);

    return (
        <>
            <Header>
                <BackButton
                    variant="secondary"
                    startIcon={<ArrowIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />}
                    onPress={onClose}
                >
                    <Text variant="bodyL" fontWeight="--fw-semibold" color="--neutral500">
                        <FormattedMessage defaultMessage="Retour à l'épisode" />
                    </Text>
                </BackButton>
                <Button
                    startIcon={<FontAwesomeIcon icon={icon({ name: 'check', style: 'solid' })} />}
                    onPress={handleUpdateTranscript}
                    isLoading={updateTranscript.isLoading}
                    isDisabled={updateTranscript.isLoading}
                >
                    <FormattedMessage defaultMessage="Enregistrer les modifications" />
                </Button>
            </Header>
            <Body>
                <Content>
                    <TranscriptionEditor editor={editor} currentTime={currentTime} />
                </Content>
                <Footer>
                    <TranscriptionPlayer
                        audioUrl={audioUrl}
                        waveformDataUrl={waveformDataUrl}
                        onCurrentTimeChange={setCurrentTime}
                        segmentTimeCode={segmentTimeCode}
                    />
                </Footer>
            </Body>
        </>
    );
};

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    padding: 2rem;
`;

const Body = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    height: calc(100vh - 8rem);
`;

const Content = styled.div`
    flex-grow: 1;
    padding-inline-start: 12rem;
    padding-top: 0.5rem;
    overflow: auto;
    scroll-behavior: smooth;
    max-width: var(--container-width);
    align-self: center;

    @media (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }
`;

const Footer = styled.footer`
    background: white;
    padding: 1rem;
    border-radius: var(--r-l);
    box-shadow: var(--s-m);
`;

const BackButton = styled(Button)`
    border: none;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

Transcription.propTypes = {
    transcription: PropTypes.object,
    episodeId: PropTypes.number,
    audioUrl: PropTypes.string,
    waveformDataUrl: PropTypes.string,
    onClose: PropTypes.func,
};

export default Transcription;
