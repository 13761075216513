import { useQuery } from '@tanstack/react-query';
import { fetchSeasons } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import seasonKeys from '@/queries/season/seasonKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });

    return transformedData.map((season) => {
        // Remove some properties
        const { podcastsCount, ...rest } = season;

        return {
            ...rest,
            episodesCount: podcastsCount,
        };
    });
};

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await fetchSeasons(showId);

    return transform(data);
};

const useSeasonsQuery = (showId) => {
    return useQuery({
        queryKey: seasonKeys.listByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useSeasonsQuery;
