import Cluster from '@ui/layout/Cluster';
import { DeprecatedIcon } from '@ui/atoms/DeprecatedIcon';
import { ReactComponent as AddIcon } from '@public/icons/add.svg';
import { ReactComponent as CheckIcon } from '@public/icons/check.svg';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';

const PricingOfferFeature = ({ isHighlighted, isAdditional, iconColor, children }) => (
    <Cluster $gap="0.5rem" $flexWrap="nowrap">
        <DeprecatedIcon
            as={isAdditional ? AddIcon : CheckIcon}
            color={iconColor ?? (isAdditional ? '--primary' : '--success')}
            size="1rem"
            css="margin-top: 0.125rem;"
        />
        <DeprecatedText weight={isHighlighted ? 'bold' : null} css="line-height: 1.5em;">
            {children}
        </DeprecatedText>
    </Cluster>
);

export default PricingOfferFeature;
