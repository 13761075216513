export const USER_OPTIONS = {
    APPLE_STATISTICS: 'apple_statistics',
    AUTOSHARING_INSTAGRAM: 'autosharing_instagram',
    AUTOSHARING_LINKEDIN: 'autosharing_linkedin',
    BROADCAST_YOUTUBE: 'broadcast_youtube',
    CHANNEL_PAGE: 'channel_page',
    COMPLETION_RATE: 'completion_rate',
    CTA_BUTTONS: 'cta_buttons',
    CUSTOMIZABLE_LOGO: 'customizable_logo',
    IMPORT_RSS_AUTO: 'import_rss_auto',
    INVITATIONS: 'invitations',
    MANUAL_CAMPAIGNS: 'manual_campaigns',
    PEAKTIME: 'peaktime',
    PLAYLISTS_CONFIDENTIALITY: 'playlists_confidentiality',
    PODCASTS_CUSTOM_URL: 'podcasts_custom_url',
    PODCASTS_UNLISTED: 'podcasts_unlisted',
    PUBLIC_API: 'public_api',
    SOUNDCLOUD: 'soundcloud',
    SOURCES_OS_BROWSERS: 'sources_os_browsers',
    STATS_DEEZER: 'stats_deezer',
    UNIQUE_LISTENERS: 'unique_listeners',
    WEBHOOK: 'webhook',
    WHITE_LABEL: 'white_label',
    PSO: 'pso',
    GUEST_USERS_EXPORT: 'permissions_extract',
};
