import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TranscriptionWaveform from '@app/molecules/TranscriptionPlayer/TranscriptionWaveform/TranscriptionWaveform';
import Cluster from '@ui/layout/Cluster';
import Stack from '@ui/layout/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSwitch } from '@hooks/useSwitch';
import { formatTime } from '@/helpers';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import IconSkipBackwardFifteen from '@ui/icons/IconSkipBackwardFifteen';
import IconSkipForwardFifteen from '@ui/icons/IconSkipForwardFifteen';

const SKIP_FORWARD = 'forward';
const SKIP_BACKWARD = 'backward';

interface TranscriptionPlayerProps {
    audioUrl: string;
    waveformDataUrl: string;
    segmentTimeCode: number;
    onCurrentTimeChange: (currentTime: number) => void;
}

const TranscriptionPlayer = ({
    audioUrl,
    waveformDataUrl,
    segmentTimeCode,
    onCurrentTimeChange,
}: TranscriptionPlayerProps) => {
    const [currentTime, setCurrentTime] = useState(0);
    const [cursorPosition, setCursorPosition] = useState(0);
    const [audioFileDuration, setAudioFileDuration] = useState(0);
    const [isPlaying, play, pause] = useSwitch(false);

    const handleSkipInAudio = (type: string) => {
        const previousValue = currentTime;
        const newValue =
            type === SKIP_FORWARD
                ? Math.min(previousValue + 15, audioFileDuration)
                : Math.max(previousValue - 15, 0);
        setCurrentTime(newValue);
        setCursorPosition(newValue);
    };

    const handlePlayOnTime = (time: number) => {
        setCurrentTime(time);
        setCursorPosition(time);
        play();
    };

    useEffect(() => {
        onCurrentTimeChange(currentTime);
    }, [currentTime]);

    useEffect(() => {
        if (segmentTimeCode) handlePlayOnTime(segmentTimeCode);
    }, [segmentTimeCode]);

    return (
        <Cluster $gap="1rem" $wrap="nowrap">
            <Stack $gap="0.5rem" $align="center" $justify="center">
                <Cluster $gap="1rem" $align="center">
                    <SkipButton
                        //@ts-ignore
                        variant="secondary"
                        onPress={() => handleSkipInAudio(SKIP_BACKWARD)}
                        startIcon={<IconSkipBackwardFifteen />}
                    />
                    {isPlaying ? (
                        <IconPlayPause
                            icon={icon({ name: 'pause', style: 'solid' })}
                            onClick={() => (isPlaying ? pause() : play())}
                        />
                    ) : (
                        <IconPlayPause
                            icon={icon({ name: 'play', style: 'solid' })}
                            onClick={() => (isPlaying ? pause() : play())}
                        />
                    )}
                    <SkipButton
                        //@ts-ignore
                        variant="secondary"
                        onPress={() => handleSkipInAudio(SKIP_FORWARD)}
                        startIcon={<IconSkipForwardFifteen />}
                    />
                </Cluster>
                <Cluster $gap="0.5rem" $wrap="nowrap">
                    <TimeCodeText>{formatTime(currentTime)}</TimeCodeText>
                    <TimeCodeText color="--neutral500">/</TimeCodeText>
                    <TimeCodeText color="--neutral500">
                        {formatTime(audioFileDuration)}
                    </TimeCodeText>
                </Cluster>
            </Stack>
            <TranscriptionWaveform
                audioUrl={audioUrl}
                waveformDataUrl={waveformDataUrl}
                isPlaying={isPlaying}
                cursorPosition={cursorPosition}
                onCurrentTimeChange={setCurrentTime}
                onDurationChange={setAudioFileDuration}
            />
        </Cluster>
    );
};

const SkipButton = styled(Button)`
    padding: 0;
    border: none;
    background: transparent;
`;

const IconPlayPause = styled(FontAwesomeIcon)`
    cursor: pointer;
    width: 2rem;
    height: 2rem;
`;

const TimeCodeText = styled(Text)`
    font-variant-numeric: tabular-nums;
`;

TranscriptionPlayer.propTypes = {
    audioUrl: PropTypes.string,
    waveformDataUrl: PropTypes.string,
    segmentTimeCode: PropTypes.number,
    onCurrentTimeChange: PropTypes.func,
};

export default TranscriptionPlayer;
