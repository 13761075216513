import { useState } from 'react';
import EpisodeEditTranscriptionQuota from './EpisodeEditTranscriptionQuota';
import Button from '@ui/atoms/Button';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import { useEpisodeEditAudioFileMutation } from '@/context/EpisodeEditContext';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import useGenerateEpisodeTranscriptMutation from '@/queries/episodeTranscript/useGenerateEpisodeTranscriptMutation.hook';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';
import { useEpisodeTranscription } from './EpisodeEditTranscriptionProvider';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import Link from '@/components/ui/atoms/Link';
import useInternalRouteMapping from '@/utils/hooks/useInternalRouteMapping.hook';
import TranscriptionEditQuotaModal from '../TranscriptionEditQuotaModal/TranscriptionEditQuotaModal';
import useUpdateTranscriptQuotaMutation from '@/queries/quota/useUpdateTranscriptQuotaMutation.hook';
import TranscriptionEditQuotaConfirmationModal from '../TranscriptionEditQuotaConfirmationModal';
import EpisodeEditTranscriptionGenerateModal from '../EpisodeEditTranscriptionGenerateModal';
import { sendAmplitudeLogEvent } from '@/helpers';

const EpisodeEditTranscriptionGenerate = () => {
    const { data: subscription } = useSubscriptionQuery();
    const episodeTranscription = useEpisodeTranscription();
    const episodeBeingEdited = useEpisodeBeingEdited();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const generateTranscript = useGenerateEpisodeTranscriptMutation();
    const hasAudioFile =
        episodeBeingEdited.files.length > 0 && !episodeEditAudioFileMutation.isLoading;
    const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
    const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);
    const [isQuotaConfirmationModalOpen, setIsQuotaConfirmationModalOpen] = useState(false);
    const toast = useModalToastQueue();
    const duration = Math.round(episodeBeingEdited.duration / 60); // Convert seconds to minutes
    const { link } = useInternalRouteMapping();
    const updateQuota = useUpdateTranscriptQuotaMutation();
    const [quotaQuantity, setQuotaQuantity] = useState(null);

    const handleGenerateTranscript = () => {
        sendAmplitudeLogEvent('Transcription Requested');
        generateTranscript.mutate(episodeBeingEdited.id, {
            onSuccess: () => {
                setIsGenerateModalOpen(false);
                /* Success message enabled */
                localStorage.setItem(`episode-form-${episodeBeingEdited.id}-transcription`, 'true');
            },
            onError: (error) => {
                switch (error.response.status) {
                    case 400:
                        // TODO: Update message
                        toast.alert(
                            <FormattedMessage defaultMessage="Le fichier audio est invalide." />,
                        );
                        break;
                    case 402:
                        toast.alert(
                            <FormattedMessage defaultMessage="Oups... Il semble que vous ayez atteint votre limite de transcription. Augmentez votre limite et réessayez. 🚀" />,
                        );
                        break;
                    default:
                        toast.alert();
                        break;
                }
            },
        });
    };

    const handleUpdateQuota = (quantity) => {
        updateQuota.mutate(quantity, {
            onSuccess: () => {
                setQuotaQuantity(null);
                setIsQuotaConfirmationModalOpen(false);
            },
        });
    };

    const handleConfirmQuota = (quantity) => {
        setQuotaQuantity(quantity);
        setIsQuotaModalOpen(false);
        setIsQuotaConfirmationModalOpen(true);
    };

    return (
        <>
            <Stack $gap="1.5rem">
                <EpisodeEditTranscriptionQuota />
                <Stack $gap="1rem" $align="center">
                    {/* TODO: Use new Tooltip component to get full width button when tooltip enabled */}
                    <Tooltip
                        position="top"
                        variant="dark"
                        content={
                            <FormattedMessage defaultMessage="Un fichier audio est nécessaire pour pouvoir générer une transcription." />
                        }
                        passthrough={hasAudioFile}
                    >
                        <GenerateButton
                            isDisabled={!hasAudioFile}
                            isLoading={
                                episodeEditAudioFileMutation.isLoading ||
                                episodeTranscription.isLoading ||
                                generateTranscript.isLoading
                            }
                            startIcon={
                                <FontAwesomeIcon icon={icon({ name: 'bolt', style: 'solid' })} />
                            }
                            onPress={() => setIsGenerateModalOpen(true)}
                        >
                            <FormattedMessage defaultMessage="Générer la transcription" />
                        </GenerateButton>
                    </Tooltip>
                    <Text variant="bodyM" color="--neutral500">
                        {subscription?.isFreemium ? (
                            <FormattedMessage
                                defaultMessage="Besoin de plus ? <link>S'abonner 🚀</link>"
                                values={{
                                    link: (chunks) => (
                                        <SubscriptionLink target="_blank" to={link('user.offers')}>
                                            {chunks}
                                        </SubscriptionLink>
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Besoin de plus ? <button>Augmenter la limite</button>"
                                values={{
                                    button: (chunks) => (
                                        <IncreaseQuotaButton
                                            onPress={() => setIsQuotaModalOpen(true)}
                                            variant="ghost"
                                        >
                                            {chunks}
                                        </IncreaseQuotaButton>
                                    ),
                                }}
                            />
                        )}
                    </Text>
                </Stack>
            </Stack>
            <EpisodeEditTranscriptionGenerateModal
                isOpen={isGenerateModalOpen}
                onOpenChange={setIsGenerateModalOpen}
                handleGenerateTranscript={handleGenerateTranscript}
                isloading={generateTranscript.isLoading}
                duration={duration}
            />
            <TranscriptionEditQuotaConfirmationModal
                isOpen={isQuotaConfirmationModalOpen}
                onOpenChange={setIsQuotaConfirmationModalOpen}
                isLoading={updateQuota.isLoading}
                onSubmit={handleUpdateQuota}
                quantity={quotaQuantity}
            />
            <TranscriptionEditQuotaModal
                isOpen={isQuotaModalOpen}
                onOpenChange={setIsQuotaModalOpen}
                onConfirm={handleConfirmQuota}
            />
        </>
    );
};

const GenerateButton = styled(Button)`
    width: 100%;
`;
const Tooltip = styled(UiKitTooltip)`
    width: 100%;
`;
const IncreaseQuotaButton = styled(Button)`
    color: var(--primary);
    font-weight: var(--fw-semibold);
    padding: 0;
`;
const SubscriptionLink = styled(Link)`
    color: var(--primary);
    font-weight: var(--fw-semibold);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export default EpisodeEditTranscriptionGenerate;
