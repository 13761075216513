import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import Form from '../Form';
import CallToActionForm from '../../../forms/CallToActionForm';
import { connect } from '../decorators/connect';
import CTAContentButtonModal from '../organisms/CTAContentButtonModal';

const enhance = connect(({ routerStore, callToActionStore }, { onClose }) => {
    const show = routerStore.resolve('showId');
    return {
        submitEditCallToAction: async (formData) => {
            await callToActionStore.updateCallToAction(show.id, formData);
            onClose();
        },
        callToActionButtons: callToActionStore.callToActionButtons,
        isLoading: callToActionStore.updateCallToAction.pending,
    };
});

const CTAEditButtonModal = ({
    callToActionButtons,
    isOpened,
    onClose,
    state,
    submitEditCallToAction,
    checkboxList,
    isLoading,
}) => (
    <DeprecatedModal
        isOpened={isOpened}
        onClose={onClose}
        w={600}
        title={<FormattedMessage defaultMessage="Ajouter un nouveau bouton" />}
    >
        <Form
            form={() =>
                CallToActionForm(callToActionButtons.find((item) => item.id === state.item.id))
            }
            onSubmit={submitEditCallToAction}
        >
            {({ fields, hasChanged }) => (
                <CTAContentButtonModal
                    fields={fields}
                    hasChanged={hasChanged}
                    isLoading={isLoading}
                    checkboxList={checkboxList}
                    state={state}
                    onClose={onClose}
                />
            )}
        </Form>
    </DeprecatedModal>
);

export default enhance(CTAEditButtonModal);
