import { createValidator } from './createValidator';

export const predicates = {
    isRequired(value) {
        return !!value;
    },
    minNumber(value, min) {
        return value >= min;
    },
    maxNumber(value, max) {
        return value <= max;
    },
    sameAs(value, fieldKey, form) {
        return form.fields[fieldKey].value === value;
    },
    differentFrom(value, fieldKey, form) {
        return form.fields[fieldKey].value !== value;
    },
    alphaDash(value) {
        if (typeof value !== 'string') {
            return false;
        }
        if (value === '') {
            return true;
        }
        return value.match(/^[a-zA-Z0-9-_]+$/);
    },
    isUrl(value) {
        if (typeof value !== 'string') {
            return false;
        }
        if (value === '') {
            return true;
        }
        return value.match(
            // eslint-disable-next-line no-useless-escape
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gm,
        );
    },
    isEmail(value) {
        if (typeof value !== 'string') {
            return false;
        }
        if (value === '') {
            return true;
        }
        return value.match(
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },
    minLength(value, min) {
        //  We ignore HTML so we don't have to properly convert the string length
        if (typeof value === 'string' && value.includes('<p>')) {
            return true;
        }
        if (typeof value === 'string') {
            return value.length >= min;
        }
        return value >= min;
    },
    maxLength(value, max) {
        //  We ignore HTML so we don't have to properly convert the string length
        if (typeof value === 'string' && value.includes('<p>')) {
            return true;
        }
        if (typeof value === 'string') {
            return value.length <= max;
        }
        return value <= max;
    },
    matchesRegex(value, regex) {
        if (typeof value !== 'string') {
            return false;
        }
        if (value === '') {
            return true;
        }
        return value.match(regex);
    },
};

export const isRequired = createValidator({ name: 'isRequired', predicate: predicates.isRequired });
export const minNumber = createValidator({ name: 'minNumber', predicate: predicates.minNumber });
export const maxNumber = createValidator({ name: 'maxNumber', predicate: predicates.maxNumber });
export const minLength = createValidator({ name: 'minLength', predicate: predicates.minLength });
export const maxLength = createValidator({ name: 'maxLength', predicate: predicates.maxLength });
export const sameAs = createValidator({ name: 'sameAs', predicate: predicates.sameAs });
export const differentFrom = createValidator({
    name: 'differentFrom',
    predicate: predicates.differentFrom,
});
export const isEmail = createValidator({ name: 'isEmail', predicate: predicates.isEmail });
export const alphaDash = createValidator({ name: 'alphaDash', predicate: predicates.alphaDash });
export const isUrl = createValidator({ name: 'isUrl', predicate: predicates.isUrl });
