import { action, autorun, computed, runInAction } from 'mobx';
import { createTransformer } from 'mobx-utils';
import {
    assignAllPodcasts,
    deleteAllPodcasts,
    deleteFile,
    deletePodcast,
    fetchAllShrinkedEpisodes,
    fetchPodcast,
    fetchPodcasts,
    updatePodcast,
    uploadPodcastImage,
} from '@/api';
import { FormattedMessage } from 'react-intl';
import Store from './Store';
import PodcastModel from '../models/PodcastModel';
import PaginationModel from '../models/PaginationModel';
import { apiCall, apiFetch } from '@app/decorators/api';
import {
    addPodcast,
    duplicate,
    deletePodcastImage,
    moveAllPodcasts,
    uploadEpisodeAudioFile,
} from '@/api';
import FileModel from '../models/FileModel';
import { FileSizeException } from '@app/errorBoundaries/Exceptions';
import ShrinkedEpisodeModel from '../models/ShrinkedEpisodeModel';
import { MAX_AUDIO_FILE_SIZE, UI_VARIANTS } from '@/utils/constants';
import userKeys from '@/queries/user/userKeys';
import { queryClient } from '@/components/App';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { sendAmplitudeLogEvent } from '@/helpers';
import showKeys from '@/queries/show/showKeys';

class PodcastStore extends Store {
    static observables = {
        podcasts: [],
        podcastsShrinked: [],
        uploadingPodcasts: [],
        pagination: {},
        // UI state
        filtersMenuOpened: false,
        statusFilters: [],
        seasonFilters: [],

        allSelected: false,
        playingPodcast: null,
        playing: false,

        editAccordionIndex: 0,

        editDescriptionHasPassedCharacterLimit: false,
    };

    byId = createTransformer((podId) => this.podcasts.find((s) => `${s.id}` === `${podId}`));
    uploadingById = createTransformer((podId) =>
        this.uploadingPodcasts.find((s) => `${s.id}` === `${podId}`),
    );

    constructor(state) {
        super(state);
        autorun(() => {
            // allSelected may be out of sync if user deselect some episodes after selecting all episodes of show
            // If allSelected stay at true even if user deselected some episodes, it may be possible to move/delete
            // all episodes of show, even unselected ones.
            // Reset allSelected to false here to prevent this.
            // If one episode is deselected, allVisibleSelected becomes false and trigger allSelected to become false too.
            if (this.allVisibleSelected === false) {
                this.allSelected = false;
            }
        });
    }

    @computed
    get nonUploadingPodcasts() {
        return this.podcasts.filter((p) => {
            for (let i = 0; i < this.uploadingPodcasts.length; i += 1) {
                if (this.uploadingPodcasts[i].id === p.id) {
                    return false;
                }
            }
            return true;
        });
    }

    @computed
    get listenablePodcasts() {
        return this.podcasts.filter((p) => p.privacy === 'public' || p.privacy === 'unlisted');
    }

    // --- FILTERS HANDLING

    @action.bound
    updateFilters(statusFilters, seasonFilters) {
        this.statusFilters = statusFilters;
        this.seasonFilters = seasonFilters;
    }

    @computed
    get filtersCount() {
        return this.seasonFilters.length + this.statusFilters.length;
    }

    // --- PLAYER HANDLING

    @action
    playOrPause(podcast) {
        if (this.playingPodcast && this.playingPodcast.id === podcast.id) {
            this.togglePlaying();
        } else {
            this.playing = true;
        }
        this.playingPodcast = podcast;
    }

    @action
    pause() {
        this.playing = false;
    }

    @action.bound
    togglePlaying() {
        this.playing = !this.playing;
    }

    // --- FILTERS HANDLING

    @action.bound
    toggleFilterMenu() {
        this.filtersMenuOpened = !this.filtersMenuOpened;
        if (this.filtersMenuOpened) {
            sendAmplitudeLogEvent('show filters');
        }
    }

    // --- SELECTION HANDLING

    @action.bound
    toggleSelectionAll() {
        this.allSelected = !this.allSelected;
    }
    @action.bound
    toggleSelectionAllVisible() {
        if (this.allVisibleSelected) {
            this.podcasts.forEach((p) => {
                p.isSelected = false;
            });
        } else {
            this.allSelected = false;
            this.podcasts.forEach((p) => {
                p.isSelected = true;
            });
        }
    }

    @computed
    get oneSelected() {
        return this.selectionCount > 0;
    }
    @computed
    get selection() {
        return this.podcasts.filter((p) => p.isSelected);
    }
    @computed
    get selectionCount() {
        return this.allSelected
            ? this.state.routerStore.resolve('showId').podcastsCount
            : this.selection.length;
    }
    @computed
    get allVisibleSelected() {
        if (this.podcasts.length === 0) {
            return false;
        }
        return this.podcasts.reduce((acc, p) => acc && p.isSelected, true);
    }

    // --- EDIT ACCORDION HANDLING

    @action.bound
    setEditAccordionIndex(index) {
        this.editAccordionIndex = index;
    }

    // --- API CALLS

    @apiFetch
    async fetchPodcast(podcastId) {
        if (podcastId === 'all') return;
        const { data } = await fetchPodcast(podcastId);
        const podcast = new PodcastModel(this, data);
        runInAction(() => {
            if (this.podcasts.find((p) => p.id === podcast.id)) return;
            this.podcasts.push(podcast);
        });

        return podcast;
    }

    @apiFetch
    async fetchPodcasts(showId, searchValue = '', seasonFilters, statusFilters, page, perPage) {
        const { data, meta } = await fetchPodcasts(
            showId,
            searchValue,
            seasonFilters,
            statusFilters,
            page,
            perPage,
        );
        runInAction(() => {
            this.podcasts = data.map(
                (podcast) => this.uploadingById(podcast.id) || new PodcastModel(this, podcast),
            );
            this.pagination = new PaginationModel(this, meta.pagination);
        });
    }

    @apiFetch
    async refreshPodcasts(showId, searchValue = '', seasonFilters, statusFilters, page, perPage) {
        const { data, meta } = await fetchPodcasts(
            showId,
            searchValue,
            seasonFilters,
            statusFilters,
            page,
            perPage,
        );
        runInAction(() => {
            this.podcasts = data.map(
                (podcast) => this.uploadingById(podcast.id) || new PodcastModel(this, podcast),
            );
            this.pagination = new PaginationModel(this, meta.pagination);
        });
    }

    @apiFetch
    async showHasEpisodes(showId, searchQuery = '', page = 1, perPage = 1) {
        const { data } = await fetchAllShrinkedEpisodes(showId, searchQuery, page, perPage);
        return data.length > 0;
    }

    @apiFetch
    async fetchShrinkedEpisodes(
        showId,
        searchQuery = '',
        page = 1,
        perPage = 20,
        withClip = null,
        episodeStatus = null,
    ) {
        const { data, meta } = await fetchAllShrinkedEpisodes(
            showId,
            searchQuery,
            page,
            perPage,
            withClip,
            episodeStatus,
        );
        runInAction(() => {
            this.podcastsShrinked = data.map((episode) => new ShrinkedEpisodeModel(this, episode));
        });

        return {
            episodes: data.map((episode) => new ShrinkedEpisodeModel(this, episode)) ?? [],
            pagination: {
                total: meta.pagination.total,
                count: meta.pagination.count,
                perPage: meta.pagination.per_page,
                currentPage: meta.pagination.current_page,
                totalPages: meta.pagination.total_pages,
            },
        };
    }

    @apiCall
    async updatePodcast(podcast, { file: image, ...formData }) {
        if (Object.keys(formData).length !== 0) {
            const {
                shareClipFacebook,
                shareClipTwitter,
                shareClipLinkedin,
                shareClipInstagram,
                ...formDataWithoutShareClip
            } = formData;
            // Continue podcast update without shareClip data in formData
            formData = formDataWithoutShareClip;

            /*
            const SHARE_CLIP_PROVIDERS_LIST = [
                { name: 'Facebook', clip: shareClipFacebook },
                { name: 'Twitter', clip: shareClipTwitter },
                { name: 'Linkedin', clip: shareClipLinkedin },
                { name: 'Instagram', clip: shareClipInstagram },
            ];

            SHARE_CLIP_PROVIDERS_LIST.filter(hasClip => typeof hasClip.clip === 'boolean').forEach(provider => {
                const template = podcast[`${provider.name.toLowerCase()}PodcastTemplate`];

                if (template) {
                    template.shareClip = provider.clip;

                    let pageIdFacebook = {};
                    if (provider.name === 'Facebook') {
                        pageIdFacebook = { page_id: template.pageId };
                    }

                    template[`updatePodcast${provider.name}`](podcast.id, {
                        message_tpl: template.resolvedText,
                        share_clip: provider.clip,
                        ...pageIdFacebook,
                    });
                }
            });
            */

            if (Object.keys(formData).includes('publish_youtube')) {
                podcast.setIsPublishYouTube(Object.values(formData)[0]);
            }

            const { data } = await updatePodcast(podcast.id, formData);
            podcast.updateData(data);
            // Need to synchronize Mobx with react-query by refreshing cache after Mobx actions
            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
            modalToastQueue.add(
                <FormattedMessage defaultMessage="Votre épisode a été mis à jour" />,
                {
                    variant: UI_VARIANTS.SUCCESS,
                    timeout: 5000,
                },
            );
        }
        if (image) {
            const imageResponse = await uploadPodcastImage(podcast.id, image);
            runInAction(() => {
                podcast.imageUrlPrivate = imageResponse.data.url;
            });
        }
        if (image === null) {
            await deletePodcastImage(podcast.id);
            runInAction(() => {
                podcast.imageUrlPrivate = null;
            });
        }
    }

    @apiCall
    async updateSlug(podcast, formData) {
        await this.updatePodcast(podcast, formData);
    }

    @apiCall
    async deletePodcast(podcast) {
        await deletePodcast(podcast.id);
        if (podcast.audioFile) podcast.audioFile.cancel();
        try {
            podcast.show.removePodcast(podcast);
            this.podcasts.remove(podcast);
            modalToastQueue.add(
                <FormattedMessage defaultMessage="Votre épisode a été supprimé" />,
                {
                    variant: UI_VARIANTS.SUCCESS,
                    timeout: 5000,
                },
            );
            if (this.playingPodcast && podcast.id === this.playingPodcast.id) {
                runInAction(() => {
                    this.playing = false;
                    this.playingPodcast = null;
                });
            }
            // Need to synchronize Mobx with react-query by refreshing cache after Mobx actions
            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
            queryClient.invalidateQueries({ queryKey: showKeys.detailById(podcast.showId) });
        } catch (error) {
            modalToastQueue.add(
                <FormattedMessage defaultMessage="Votre épisode n'a pu être supprimé. Veuillez réessayer." />,
                {
                    variant: UI_VARIANTS.ALERT,
                    timeout: 5000,
                },
            );
            throw error;
        }
    }

    @apiCall
    async uploadPodcasts(show, files) {
        if (files.length > 1 && this.state.routerStore.routeName !== 'menu.episodes') {
            this.state.routerStore.push('menu.episodes', { showId: show.id });
        }
        await Promise.all(
            Array.from(files).map(async (file) => {
                const { data } = await addPodcast(show.id, {
                    name: file.name.replace(/\.mp3$/, '').substr(0, 140),
                });
                runInAction(() => {
                    show.podcastsCountApi += 1;
                    const podcast = new PodcastModel(this, data);
                    this.podcasts.unshift(podcast);
                    if (files.length === 1) {
                        this.state.routerStore.push('episodes.edit', {
                            showId: show.id,
                            podId: podcast.id,
                        });
                    }
                    podcast.addFile(file);
                });
            }),
        );
    }

    @apiCall
    async createPodcast(show, name) {
        const { data } = await addPodcast(show.id, { name });
        runInAction(() => {
            show.podcastsCountApi += 1;
            const podcast = new PodcastModel(this, data);
            this.podcasts.unshift(podcast);
            this.state.routerStore.push('episodes.edit', { showId: show.id, podId: podcast.id });
            // Need to synchronize Mobx with react-query by refreshing cache after Mobx actions
            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
        });
    }

    @apiCall
    async addFile(podcast, file) {
        if (podcast.audioFile && podcast.audioFile.cancelSource) {
            podcast.audioFile.cancel();
        } else {
            if (MAX_AUDIO_FILE_SIZE && file.size > MAX_AUDIO_FILE_SIZE) {
                throw new FileSizeException(MAX_AUDIO_FILE_SIZE);
            }
            podcast.prevFile = podcast.audioFile;
            podcast.prevUrl = podcast.audioUrlPrivate;
            podcast.audioFile = new FileModel(this, {});
            podcast.audioUrlPrivate = null;
            this.uploadingPodcasts.unshift(podcast);
        }
        try {
            podcast.audioFile.startUpload(file);
            const { data } = await uploadEpisodeAudioFile(podcast.id, podcast.audioFile);
            runInAction(() => {
                podcast.audioFile.endUpload();
                this.uploadingPodcasts.remove(podcast);
                podcast.updateData(data);
                podcast.prevFile &&
                    modalToastQueue.add(
                        <FormattedMessage defaultMessage="Le fichier a bien été envoyé" />,
                        {
                            variant: UI_VARIANTS.SUCCESS,
                            timeout: 5000,
                        },
                    );
            });
        } catch (error) {
            if (error.response) {
                runInAction(() => {
                    podcast.audioFile = podcast.prevFile;
                    podcast.audioUrlPrivate = podcast.prevUrl;
                });
            }
            throw error;
        }
    }

    @apiCall
    async deleteFile(podcast) {
        await deleteFile(podcast.id);
        runInAction(() => {
            podcast.audioFile = null;
            podcast.audioUrlPrivate = null;
        });
    }

    @apiCall
    async deleteAll(show) {
        if (this.allSelected) {
            await deleteAllPodcasts(show.id);
            runInAction(() => {
                this.allSelected = false;
                show.podcastsCountApi = 0;
                show.duration = 0;
            });
        } else {
            await Promise.all(
                this.selection.map(async (p) => {
                    await p.delete();
                    runInAction(() => {
                        show.duration -= p.duration;
                        show.podcastsCountApi -= 1;
                    });
                }),
            );
        }
        return this.fetchPodcasts(show.id, '', this.seasonFilters, this.statusFilters);
    }

    @apiCall
    async assignAll(showId, seasonId) {
        if (this.allSelected) {
            await assignAllPodcasts(showId, { newSeasonId: seasonId });
            runInAction(() => {
                this.podcasts.forEach((podcast) => {
                    podcast.seasonId = seasonId;
                });
            });
        } else {
            await Promise.all(this.selection.map((podcast) => podcast.updateSeason(seasonId)));
        }
    }

    @apiCall
    async moveAll(prevShow, newShowId) {
        if (this.allSelected) {
            await moveAllPodcasts(prevShow.id, { newShowId });
            runInAction(() => {
                this.allSelected = false;
                prevShow.podcastsCountApi = 0;
                prevShow.duration = 0;
            });
        } else {
            await Promise.all(
                this.selection.map(async (podcast) => {
                    await podcast.move(newShowId, false);
                    runInAction(() => {
                        prevShow.duration -= podcast.duration;
                        prevShow.podcastsCountApi -= 1;
                    });
                }),
            );
        }
        return this.fetchPodcasts(prevShow.id, '', this.seasonFilters, this.statusFilters);
    }

    @apiCall
    async duplicate(podId) {
        const { data } = await duplicate(podId);
        runInAction(() => {
            const podcast = new PodcastModel(this, data);
            sendAmplitudeLogEvent('show form episode', {
                is_new_episode: podcast.isDraft,
            });
            this.state.routerStore.push('experimental.episodes.edit', {
                episodeId: podcast.id,
            });
        });
    }

    @action.bound
    setEditDescriptionHasPassedCharacterLimit(passed) {
        this.editDescriptionHasPassedCharacterLimit = passed;
    }
}

export default PodcastStore;
