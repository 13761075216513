import { createContext, useContext, useState } from 'react';
import useAddEpisodeAudioFileMutation from '@/queries/episode/useAddEpisodeAudioFileMutation.hook';

const EpisodeEditAudioFileMutationContext = createContext(null);

/**
 * Context dedicated to episode creation.
 * Accessible from audio file upload and episode edit form and used to pass audio file data
 * (file name, file size, upload progress, etc) from upload page and episode edit form.
 */
export const EpisodeEditContext = ({ children }) => {
    const audioFileState = useState(null);
    const addEpisodeAudioFileMutation = useAddEpisodeAudioFileMutation();

    return (
        <EpisodeEditAudioFileMutationContext.Provider
            value={{
                audioFileMutation: addEpisodeAudioFileMutation,
                audioFileState,
            }}
        >
            {children}
        </EpisodeEditAudioFileMutationContext.Provider>
    );
};

export const useEpisodeEditAudioFileMutation = () => {
    const context = useContext(EpisodeEditAudioFileMutationContext);

    return context.audioFileMutation;
};

export const useEpisodeEditAudioFileState = () => {
    const context = useContext(EpisodeEditAudioFileMutationContext);

    return context.audioFileState;
};
