import { computed, action } from 'mobx';
import Model from './Model';
import { dateUTC } from './resolvers';
import { apiCall } from '@app/decorators/api';
import ShowLinkModel from './ShowLinksModel';
import { formatDateForApiUTC } from '@/helpers';
import TagModel from './TagModel';
import { STATUS_SHOWS } from '@/utils/statusShows';

class ShowModel extends Model {
    static default = ({ userStore }) => ({
        languageId: userStore.user?.languageCode === 'fr' ? 47 : 40, // 47 is French, 40 is english
        htmlDescription: '',
        author: `${userStore.user?.firstName} ${userStore.user?.lastName}`,
        categoryId: null,
        imageUrl: null,
        tags: [],
    });

    static attributes = {
        id: 'id',
        archived: 'archived',
        canComment: 'can_comment',
        category: 'category',
        categoryId: 'category_id',
        secondaryCategory: 'secondary_category',
        secondaryCategoryId: 'secondary_category_id',
        channelId: 'channel_id',
        clipColor: 'clip_color',
        description: 'description',
        downloadsCount: 'downloads_count',
        duration: 'duration',
        htmlDescription: 'html_description',
        author: 'author',
        imageUrl: 'image_url',
        languageId: 'language_id',
        name: 'name',
        campaignsCount: 'campaigns_count',
        listeningLinks: {
            fromApi: {
                key: 'listening_links',
                resolve(links, store, show) {
                    const data = links.data.reduce(
                        (acc, elt) => ({ ...acc, [elt.key]: elt.url }),
                        {},
                    );
                    return new ShowLinkModel(store, { show_id: show.id, ...data });
                },
            },
        },
        socialLinks: {
            fromApi: {
                key: 'social_links',
                resolve(links, store, show) {
                    const data = links.data.reduce(
                        (acc, elt) => ({ ...acc, [elt.key]: elt.url }),
                        {},
                    );
                    return new ShowLinkModel(store, { show_id: show.id, ...data });
                },
            },
        },
        ownerPricing: 'owner_pricing',
        amazonFeedId: 'amazon_feed_id',
        podcastsCountApi: 'podcasts_count',
        hasPublicActivePodcasts: 'has_public_active_podcasts',
        publicCounts: 'public_counts',
        publicId: 'public_id',
        rssLink: 'rss_link',
        siteUrl: 'site_url',
        slug: 'slug',
        type: 'type',
        viewsCount: 'views_count',
        updatedAt: dateUTC('updated_at'),
        createdAt: dateUTC('created_at'),
        userRole: 'user_role',
        userOptions: 'user_options',
        isWhiteLabel: 'white_label',
        lightLogoUrl: 'light_logo_url',
        darkLogoUrl: 'dark_logo_url',
        logoLink: 'logo_link',
        favicon: 'favicon_url',
        podcastsUpdated: 'podcasts_updated',
        userEmail: 'owner_email',
        tags: {
            fromApi: {
                key: 'tags',
                resolve: (xs) => xs.data.map((x) => new TagModel(this, x)),
            },
            toApi: {
                key: 'tags',
                resolve: (xs) => xs.map((x) => x.name),
            },
        },
    };

    // UI

    // Fixes podcasts count for imports (it can't be fetched correctly)
    @computed
    get podcastsCount() {
        return Math.max(this.podcastsCountApi, this.state.podcastStore.pagination.total);
    }

    @computed
    get formattedUpdatedAt() {
        return formatDateForApiUTC(this.updatedAt);
    }

    @computed
    get formattedCreatedAt() {
        return formatDateForApiUTC(this.createdAt);
    }

    @computed
    get hasListeningLinks() {
        const { store, showId, ...listeningLinks } = this.listeningLinks;

        return Object.values(listeningLinks).some((link) => link);
    }

    @action
    removePodcast(podcast) {
        this.podcastsCountApi -= 1;
        this.duration -= podcast.duration;
    }

    @action
    addCampaign() {
        this.campaignsCount += 1;
    }

    @action
    removeCampaign() {
        this.campaignsCount -= 1;
    }

    @action
    appendPodcast(podcast) {
        this.podcastsCountApi += 1;
        this.duration += podcast.duration;
    }

    // API

    @apiCall
    addPodcasts(name) {
        return this.state.podcastStore.createPodcast(this, name);
    }

    @apiCall
    async deleteAllPodcasts() {
        return this.state.podcastStore.deleteAll(this);
    }

    @apiCall
    async moveAllPodcasts(showId) {
        return this.state.podcastStore.moveAll(this, showId);
    }

    @apiCall
    update(formData) {
        return this.store.updateShow(this, formData);
    }

    @apiCall
    delete() {
        return this.store.deleteShow(this);
    }

    @apiCall
    unbroadcastOnSpotify() {
        return this.store.state.broadcastStore.unbroadcastOnSpotify(this);
    }

    @apiCall
    updateTags(formData) {
        const tags = formData.map((x) => x.name);
        return this.store.updateShow(this, { tags });
    }

    @computed
    get language() {
        return this.store.state.languageStore.languages.find((l) => l.id === this.languageId);
    }

    @computed
    get showStatus() {
        const { ACTIVE_SHOWS, ARCHIVED_SHOWS, GUEST_SHOWS } = STATUS_SHOWS;
        if (!this.userRole.includes('owner')) return GUEST_SHOWS;
        return this.archived ? ARCHIVED_SHOWS : ACTIVE_SHOWS;
    }

    allows(option) {
        return this.userOptions.includes(option);
    }
}

export default ShowModel;
