import { createTransformer } from 'mobx-utils';
import { computed, runInAction } from 'mobx';
import Store from './Store';
import { fetchLanguages } from '@/api';
import LanguageModel from '../models/LanguageModel';
import { apiFetch } from '../components/app/decorators/api';

class LanguageStore extends Store {
    static observables = {
        languages: [],
    };

    byCode = createTransformer((code) => this.languages.find((l) => l.code === code));
    byId = createTransformer((id) => this.languages.find((l) => l.id === id));

    @apiFetch
    async fetchLanguages() {
        const { data } = await fetchLanguages();
        runInAction(() => {
            this.languages = data.map((l) => new LanguageModel(this, l));
        });
    }

    @computed
    get languagesSource() {
        return this.languages.map((l) => ({
            label: l.nativeName,
            value: l.id,
            isFeatured: l.isFeatured,
        }));
    }
}

export default LanguageStore;
